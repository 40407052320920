import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import TranslateButton from './TranslateButton';
import ReadMoreModal from './modals/ReadMoreModal';
import LanguageDetect from 'languagedetect';
import api from 'utils/api';
import Description from './Description';
import { Link } from 'react-router-dom';

const detector = new LanguageDetect();

const TitleDescription = ({
  title,
  description,
  isListItem = false,
  button = null,
  to = null,
  noOfCharacters,
  ...rest
}) => {
  const [translations, setTranslations] = useState(null);
  const [showTranslated, setShowTranslated] = useState(false);
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTranslations(null);
    setShowTranslated(false);
  }, [title, description]);

  const toggleShowReadMoreModal = () => {
    setShowReadMoreModal(!showReadMoreModal);
  };

  const handleOnClickTranslate = async () => {
    if (!translations) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [title, description]
        });
        setTranslations(data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    setShowTranslated(!showTranslated);
  };

  const titleText = showTranslated && translations ? translations[0] : title;
  const descriptionText =
    showTranslated && translations ? translations[1] : description;

  const languages =
    titleText &&
    descriptionText &&
    detector.detect(`${title} ${description}`, 2);

  return (
    <Stack spacing={4} {...rest}>
      <HStack
        spacing={4}
        width="full"
        alignItems="center"
        flexWrap="wrap"
        rowGap={isListItem ? 2 : 4}
        height="fit-content"
      >
        <Heading
          noOfLines={99}
          width="fit-content"
          wordBreak="break-word"
          fontSize={isListItem ? 'lg' : '2xl'}
          maxWidth={isListItem ? null : ['full', null, '90%']}
          height={isListItem ? null : 'min-content'}
        >
          {titleText}
        </Heading>
        <Spacer />
        <Flex
          flexGrow={1}
          alignSelf="flex-start"
          justify={isListItem ? 'flex-end' : 'flex-end'}
        >
          {button}
        </Flex>
      </HStack>
      <Description noOfCharacters={noOfCharacters}>
        {descriptionText}
      </Description>
      <HStack spacing={4}>
        {languages &&
          languages.length > 0 &&
          languages[0][0] !== 'english' &&
          languages[0][1] > 0.2 && (
            <TranslateButton
              showTranslated={showTranslated}
              onClick={handleOnClickTranslate}
            />
          )}
        {descriptionText?.length > noOfCharacters && (
          <Button
            variant="link"
            size="xs"
            as={Link}
            to={to}
            onClick={to ? null : toggleShowReadMoreModal}
          >
            {t('button.read_more')}
          </Button>
        )}
      </HStack>
      <ReadMoreModal
        title={titleText}
        description={descriptionText}
        isOpen={showReadMoreModal}
        onClose={toggleShowReadMoreModal}
      />
    </Stack>
  );
};

export default TitleDescription;
