import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack
} from '@chakra-ui/react';
import CustomMDXEditor from 'components/CustomMDXEditor';

const GeneratedReportForm = ({ folder, defaultValues, onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="report">
          <FormLabel>{t('common.report')}</FormLabel>
          <Controller
            control={control}
            name="report"
            render={({ field }) => (
              <Box maxH="400px" overflowY="scroll">
                <CustomMDXEditor field={field} setValue={setValue} />
              </Box>
            )}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isLoading={isSubmitting}
            isDisabled={!folder}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default GeneratedReportForm;
