import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  Tab,
  Tabs,
  TabPanel,
  TabList,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TabPanels,
  useToast,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import { Plans, hasPlanGreaterThanOrEqual } from 'features/hive/hiveUtils';
import api from 'utils/api';
import GroupForm from './GroupForm';
import ProfileTable from './ProfileTable';

const AdminUsers = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const [selectedGroup, setSelectedGroup] = useState(null);

  const {
    isOpen: showCreateGroupModal,
    onOpen: openCreateGroupModal,
    onClose: closeCreateGroupModal
  } = useDisclosure();

  const {
    isOpen: showDeleteGroupModal,
    onOpen: openDeleteGroupModal,
    onClose: closeDeleteGroupModal
  } = useDisclosure();

  const {
    isOpen: showUpdateGroupModal,
    onOpen: openUpdateGroupModal,
    onClose: closeUpdateGroupModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status: groupsStatus,
    data: groups,
    isSuccess: groupsIsSuccess
  } = useQuery('groups', async () => {
    const { data } = await api.get(`/groups?hive__slug=${slug}`);
    return data;
  });

  const createGroupMutation = useMutation(
    async group => await api.post('/groups', group),
    {
      onSuccess: ({ data: group }) => {
        closeCreateGroupModal();
        queryClient.setQueryData('groups', [group, ...groups]);
        toast({
          title: t('toast.group_create_success'),
          status: 'success'
        });
      }
    }
  );

  const deleteGroupMutation = useMutation(
    async () => {
      await api.delete(`/groups/${selectedGroup.id}`);
    },
    {
      onSuccess: () => {
        closeDeleteGroupModal();
        queryClient.setQueryData(
          'groups',
          groups.filter(g => g.id !== selectedGroup.id)
        );
        toast({
          title: t('toast.group_delete_success'),
          status: 'success'
        });
      }
    }
  );

  const updateGroupMutation = useMutation(
    async group => await api.patch(`/groups/${selectedGroup.id}`, group),
    {
      onSuccess: ({ data: group }) => {
        closeUpdateGroupModal();
        queryClient.setQueryData(
          'groups',
          groups.map(g => (g.id === group.id ? group : g))
        );
        toast({
          title: t('toast.group_update_success'),
          status: 'success'
        });
      }
    }
  );

  const handleOnCreateGroup = async group => {
    await createGroupMutation.mutateAsync({ ...group, hive: hive.id });
  };
  const handleOnDeleteGroup = async () => {
    await deleteGroupMutation.mutateAsync();
  };

  const handleOnUpdateGroup = async group => {
    await updateGroupMutation.mutateAsync(group);
  };

  const hasStarterOrBetter = hasPlanGreaterThanOrEqual(
    hive?.plan,
    Plans.Starter
  );

  return (
    <LoadingWrapper
      statuses={[groupsStatus]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.groups').toLowerCase()
        })
      ]}
    >
      {groupsIsSuccess ? (
        <>
          <Tabs size="sm" variant="fresh">
            <TabList>
              <Tab>{t('common.people')}</Tab>
              {hasStarterOrBetter && <Tab>{t('common.groups')}</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProfileTable hasStarterOrBetter={hasStarterOrBetter} />
              </TabPanel>
              {hasStarterOrBetter && (
                <TabPanel>
                  <Stack spacing={8}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t('common.group')}</Th>
                          <Th textAlign="center">{t('common.total')}</Th>
                          <Th textAlign="center">{t('common.members')}</Th>
                          <Th textAlign="center">{t('common.managers')}</Th>
                          <Th textAlign="center">{t('common.admins')}</Th>
                          <Th textAlign="center">{t('common.guests')}</Th>
                          <Th textAlign="center">{t('common.delete')}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {groups.map(group => (
                          <Tr key={group.id}>
                            <Td>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setSelectedGroup(group);
                                  openUpdateGroupModal();
                                }}
                              >
                                {group.name}
                              </Button>
                            </Td>
                            <Td textAlign="center">
                              {group.metrics?.members?.total}
                            </Td>
                            <Td textAlign="center">
                              {group.metrics?.members?.members}
                            </Td>
                            <Td textAlign="center">
                              {group.metrics?.members?.managers}
                            </Td>
                            <Td textAlign="center">
                              {group.metrics?.members?.admins}
                            </Td>
                            <Td textAlign="center">
                              {group.metrics?.members?.guests}
                            </Td>
                            <Td textAlign="center">
                              <Button
                                variant="outline"
                                onClick={() => {
                                  setSelectedGroup(group);
                                  openDeleteGroupModal();
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <Flex justifyContent="flex-end">
                      <Button colorScheme="teal" onClick={openCreateGroupModal}>
                        <HStack>
                          <FontAwesomeIcon icon={faPlus} />
                          <Text>{t('button.create')}</Text>
                        </HStack>
                      </Button>
                    </Flex>
                  </Stack>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
            )}
            isOpen={showDeleteGroupModal}
            onClose={closeDeleteGroupModal}
            onDelete={handleOnDeleteGroup}
          />
          <ModalWrapper
            title={t('admin.create_group')}
            size="full"
            isOpen={showCreateGroupModal}
            onClose={closeCreateGroupModal}
          >
            <GroupForm
              isOpen={showCreateGroupModal}
              onSubmit={handleOnCreateGroup}
              onClose={closeCreateGroupModal}
            />
          </ModalWrapper>
          <ModalWrapper
            title={t('common.group')}
            isOpen={showUpdateGroupModal}
            onClose={closeUpdateGroupModal}
          >
            <GroupForm
              defaultValues={selectedGroup}
              isOpen={showUpdateGroupModal}
              onSubmit={handleOnUpdateGroup}
            />
          </ModalWrapper>
        </>
      ) : null}
    </LoadingWrapper>
  );
};

export default AdminUsers;
