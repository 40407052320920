import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack
} from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';

const StepReminderForm = ({ defaultValues, isOpen, onDelete, onSubmit }) => {
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      reminder_days: defaultValues?.reminder_days ?? 1
    }
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  useEffect(() => {
    setModalClosureLocked(isDirty || !defaultValues?.reminder_days);
  }, [defaultValues?.reminder_days, isDirty, setModalClosureLocked]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="reminder_days" isInvalid={!!errors.reminder_days}>
          <FormLabel>{t('common.days')}</FormLabel>
          <Controller
            name="reminder_days"
            control={control}
            rules={{ required: false, min: 1, max: 100, valueAsNumber: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <NumberInput
                value={value}
                onChange={(_, valueNumber) => {
                  onChange(valueNumber);
                }}
              >
                <NumberInputField {...field} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
          <FormErrorMessage>
            {t('workflow.reminder_days_invalid_message')}
          </FormErrorMessage>
        </FormControl>
        <Flex
          alignItems="center"
          justifyContent={
            defaultValues?.reminder_days ? 'space-between' : 'flex-end'
          }
        >
          {defaultValues?.reminder_days && (
            <Button onClick={onDelete} colorScheme="red">
              {t('common.delete')}
            </Button>
          )}
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || (defaultValues?.reminder_days && !isDirty)}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default StepReminderForm;
