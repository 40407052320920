import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  CardFooter as ChakraCardFooter,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import { getCollectingPlural, getCollectingSingular } from './collectionUtils';

const CollectionListItem = ({ collection, onClick }) => {
  const { slug } = useParams();
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  /* Ugly code! Just for testing!!! */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContentHeight(entry.contentRect.height);
      }
    });

    const currentContentRef = contentRef.current; // Capture the current ref value

    if (currentContentRef) {
      resizeObserver.observe(currentContentRef);
    }

    return () => {
      if (currentContentRef) {
        resizeObserver.unobserve(currentContentRef); // Use the captured value
      }
    };
  }, []);

  return (
    <ChakraCard
      cursor="pointer"
      as={onClick ? null : Link}
      to={
        onClick
          ? null
          : {
              pathname: `/${slug}/collection/${collection.id}`
            }
      }
      onClick={onClick}
      direction="row"
      overflow="hidden"
      variant="outline"
    >
      <Flex
        width={['80px', '120px']}
        minWidth={['80px', '120px']}
        height={`${contentHeight}px`}
      >
        <CoverMedia
          object={collection}
          rounded="none"
          width="full"
          height="full"
        />
      </Flex>
      <Flex direction="column" ref={contentRef}>
        <ChakraCardBody pb={0}>
          <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
            {collection.title}
          </Heading>
        </ChakraCardBody>
        <ChakraCardFooter>
          <Text fontSize="xs" textTransform="lowercase">
            {`${collection.metrics.cards} ${
              collection.metrics.cards === 1
                ? getCollectingSingular(collection.type)
                : getCollectingPlural(collection.type)
            }`}
          </Text>
        </ChakraCardFooter>
      </Flex>
    </ChakraCard>
  );
};

export default CollectionListItem;
