import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import MemberAvatar from 'components/MemberAvatar';
import ModalWrapper from 'components/ModalWrapper';
import OldReportForm from 'features/report/OldReportForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import api from 'utils/api';
import CollectionReportModal from './CollectionReportModal';
import { useParams } from 'react-router-dom';

const CollectionReportList = ({ collectionId }) => {
  const [selectedReport, setSelectedReport] = useState(null);

  const {
    isOpen: showReportModal,
    onOpen: openReportModal,
    onClose: closeReportModal
  } = useDisclosure();

  const {
    isOpen: showDeleteReportModal,
    onOpen: openDeleteReportModal,
    onClose: closeDeleteReportModal
  } = useDisclosure();

  const {
    isOpen: showUpdateReportModal,
    onOpen: openUpdateReportModal,
    onClose: closeUpdateReportModal
  } = useDisclosure();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { slug } = useParams();

  const {
    data: reports,
    status,
    isSuccess
  } = useQuery(['reports', collectionId], async () => {
    const { data } = await api.get(
      `/collections/reports?collection=${collectionId}&hive__slug=${slug}`
    );
    return data;
  });

  const deleteReportMutation = useMutation(
    () => api.delete(`/collections/reports/${selectedReport.id}`),
    {
      onSuccess: () => {
        queryClient.setQueryData(
          ['reports', collectionId],
          reports.filter(r => r.id !== selectedReport.id)
        );
        setSelectedReport(null);
        closeDeleteReportModal();
      }
    }
  );

  const updateReportMutation = useMutation(
    payload =>
      api.patch(
        `/collections/reports/${selectedReport.id}?hive__slug=${slug}`,
        payload
      ),
    {
      onSuccess: ({ data: report }) => {
        setSelectedReport(report);
        queryClient.setQueryData(
          ['reports', collectionId],
          reports.map(r => (r.id === selectedReport.id ? report : r))
        );
        closeUpdateReportModal();
      }
    }
  );

  const handleOnDeleteReport = async () => {
    await deleteReportMutation.mutateAsync();
  };

  const handleOnUpdateReport = async values => {
    await updateReportMutation.mutateAsync({
      title: values.title,
      report: values.description
    });
  };

  return (
    <LoadingWrapper statuses={[status]}>
      {isSuccess && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th pl={0}>{t('common.ai_analysis')}</Th>
                <Th textAlign="center">{t('common.manager')}</Th>
                <Th textAlign="center">{t('common.created_at')}</Th>
                <Th pr={0} textAlign="right">
                  {t('common.delete')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {reports.map(report => (
                <Tr key={report.id}>
                  <Td pl={0}>
                    <Button
                      variant="link"
                      onClick={() => {
                        setSelectedReport(report);
                        openReportModal();
                      }}
                    >
                      <Text maxW="400px" isTruncated>
                        {report.title}
                      </Text>
                    </Button>
                  </Td>
                  <Td textAlign="center">
                    <MemberAvatar member={report.creator} size="sm" />
                  </Td>
                  <Td px={0} textAlign="center">
                    <Text>
                      {format(
                        new Date(report.created_at),
                        'd MMM yyyy, HH:mm:ss'
                      )}
                    </Text>
                  </Td>
                  <Td pr={0} textAlign="right">
                    <Button
                      variant="outline"
                      onClick={async () => {
                        try {
                          await api.delete(
                            `/collections/reports/${report.id}?hive__slug=${slug}`
                          );
                          queryClient.setQueryData(
                            ['reports', collectionId],
                            reports.filter(r => r.id !== report.id)
                          );
                        } catch (_) {}
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <CollectionReportModal
            report={selectedReport}
            isOpen={showReportModal}
            onClose={closeReportModal}
            onClickDelete={openDeleteReportModal}
            onClickUpdate={openUpdateReportModal}
          />
          {selectedReport && (
            <>
              <ModalWrapper
                isOpen={showUpdateReportModal}
                onClose={closeUpdateReportModal}
                title={t('common.ai_analysis')}
              >
                <OldReportForm
                  defaultValues={{
                    title: selectedReport.title,
                    description: selectedReport.report
                  }}
                  onSubmit={handleOnUpdateReport}
                />
              </ModalWrapper>
              <ConfirmationModal
                deleteText={t(
                  'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
                  { data: t('common.ai_analysis') }
                )}
                isOpen={showDeleteReportModal}
                onClose={closeDeleteReportModal}
                onDelete={handleOnDeleteReport}
              />
            </>
          )}
        </TableContainer>
      )}
    </LoadingWrapper>
  );
};

export default CollectionReportList;
