import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AspectRatio,
  Button,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import ButtonAnimator from 'components/ButtonAnimator';
import CardList from 'features/card/CardList';
import {
  cardFilterToArray,
  generateCardPayload
} from 'features/card/cardUtils';
import CoverMedia from 'components/CoverMedia';
import EmptyState from 'components/EmptyState';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import CardForm from 'features/card/CardForm';
import { useAttachment } from 'providers/AttachmentProvider';
import api from 'utils/api';
import CollectionCardFilter from './CollectionCardFilter';
import CollectionSearchFilter from './CollectionSearchFilter';
import {
  canContribute,
  getAllContributions,
  getCollectingSingular,
  getMyContributions
} from './collectionUtils';
import CollectionControls from './CollectionControls';
import { getContributeButtonLabel } from 'features/workflow/workflowUtils';
import { useUi } from 'providers/UiProvider';
import { useParams } from 'react-router-dom';
import FolderAssociationButton from 'features/folders/FolderAssociationButton';
import { useConfetti } from 'providers/ConfettiProvider';

const CollectionPage = ({
  isAdminOrCollectionManager = false,
  collectionId,
  collection,
  onClickMoveToFolder,
  onClickDelete,
  onClickEdit,
  onClickExport,
  onClickManagers,
  onClickPublish,
  onClickSetThankYouMessage,
  onClickShare
}) => {
  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  const { handleOnThankYou } = useUi();

  const [showFilter, setShowFilter] = useState(false);
  const { fire } = useConfetti();
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const queryClient = useQueryClient();
  const { filter, setFilter } = useUi();
  const { uploadAttachments } = useAttachment();
  const toast = useToast();
  const { t } = useTranslation();

  const createCardMutation = useMutation(
    card => api.post(`/cards?hive=${slug}`, card, { timeout: 0 }),
    {
      onSuccess: ({ data: card }) => {
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [card, ...pages[0].results];
          }
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
        uploadAttachments({
          singular: 'card',
          plural: 'cards',
          modelId: card.id
        });
        queryClient.setQueryData(['collection', collectionId], {
          ...collection,
          metrics: {
            ...collection.metrics,
            cards: collection.metrics.cards + 1
          }
        });
        if (collection?.thank_you_message) {
          handleOnThankYou(collection?.thank_you_message);
        } else {
          fire();
          toast({
            title: t('card.contribution_thank_you'),
            status: 'success'
          });
        }
        closeCreateCardModal();
      },
      onError: () => {
        toast({
          title: t('toast.card_create_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateCard = async card => {
    await createCardMutation.mutateAsync(
      generateCardPayload(collectionId, card)
    );
  };

  useEffect(() => {
    if (Object.keys(filter).length < 1) {
      setFilter({
        ...filter,
        collection: collectionId
      });
    }
  }, [setFilter, filter, collectionId]);

  const contributionAllowed = canContribute(collection, me);

  return (
    <Stack spacing={8} pb={24}>
      <HStack spacing={4}>
        <Heading fontSize="3xl">{t('common.collection')}</Heading>
        <Spacer />
        {isAdminOrCollectionManager && (
          <CollectionControls
            collection={collection}
            onClickMoveToFolder={onClickMoveToFolder}
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
            onClickExport={onClickExport}
            onClickManagers={onClickManagers}
            onClickPublish={onClickPublish}
            onClickShare={onClickShare}
            onClickSetThankYouMessage={onClickSetThankYouMessage}
          />
        )}
      </HStack>
      <SimpleGrid columns={[1, null, 2]} spacing={8}>
        <AspectRatio ratio={16 / 9}>
          <CoverMedia object={collection} clickToFullscreen />
        </AspectRatio>
        <Stack
          spacing={8}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack spacing={4}>
            {isAdminOrCollectionManager && (
              <FolderAssociationButton
                folder={collection?.folder}
                onClickMoveToFolder={onClickMoveToFolder}
              />
            )}
            <TitleDescription
              title={collection?.title}
              description={collection?.description}
              noOfCharacters={650}
            />
          </Stack>
          {!(
            collection.contribution_access === 'MANAGERS' &&
            !contributionAllowed
          ) &&
            collection?.metrics?.cards > 0 && (
              <Flex
                width="full"
                justifyContent={['flex-end', null, 'flex-start']}
              >
                <Tooltip
                  isDisabled={contributionAllowed}
                  label={t('collection.closed_collection_tooltip')}
                  shouldWrapChildren
                  hasArrow
                >
                  <ButtonAnimator active={contributionAllowed}>
                    <Button
                      colorScheme="teal"
                      isDisabled={!contributionAllowed}
                      onClick={openCreateCardModal}
                    >
                      {getContributeButtonLabel(collection.workflow)}
                    </Button>
                  </ButtonAnimator>
                </Tooltip>
              </Flex>
            )}
        </Stack>
      </SimpleGrid>
      <GridAttachmentList
        modelConfig={{
          singular: 'collection',
          plural: 'collections',
          modelId: collection.id
        }}
        canEdit={isAdminOrCollectionManager}
      />
      {contributionAllowed && collection.metrics.cards === 0 && (
        <EmptyState
          title={t('collection.be_the_first')}
          description={t(
            'collection.this_collection_just_opened_up_for_submissions_be_the_first_to_contribute'
          )}
          filename="lightbulb.svg"
          button={
            <ButtonAnimator active>
              <Button
                colorScheme="teal"
                onClick={openCreateCardModal}
                width="full"
              >
                {collection?.defaultValues?.contribute_button_label
                  ? collection.defaultValues.contribute_button_label
                  : `+ ${t('common.contribute')}`}
              </Button>
            </ButtonAnimator>
          }
        />
      )}
      <Stack spacing={4}>
        {collection.metrics.cards > 0 && (
          <Tabs
            size="sm"
            variant="fresh"
            onChange={index => {
              setFilter({ ...filter, creator: index === 0 ? null : me.id });
            }}
          >
            <SimpleGrid columns={[1, 2]} spacing={4}>
              <TabList>
                <Tab>{getAllContributions(collection)}</Tab>
                <Tab>{getMyContributions(collection)}</Tab>
              </TabList>
              <Flex justifyContent="flex-end">
                <CollectionSearchFilter
                  collection={collection}
                  filter={filter}
                  setFilter={setFilter}
                  showFilter={showFilter}
                  toggleFilter={() => {
                    setShowFilter(!showFilter);
                  }}
                />
              </Flex>
            </SimpleGrid>
          </Tabs>
        )}
      </Stack>
      {showFilter && (
        <CollectionCardFilter
          collection={collection}
          filter={filter}
          setFilter={setFilter}
        />
      )}
      <CardList filter={filter} useCarousel />
      <ModalWrapper
        title={getCollectingSingular(collection.type)}
        size="full"
        isOpen={showCreateCardModal}
        onClose={closeCreateCardModal}
        confirmClosure
      >
        <CardForm
          titleOverride={collection.workflow?.title_override}
          useDescription={collection.workflow?.use_card_description}
          useImage={collection.workflow?.use_card_image}
          workflowId={collection.workflow?.id}
          collection={collection}
          isOpen={showCreateCardModal}
          onSubmit={handleOnCreateCard}
        />
      </ModalWrapper>
    </Stack>
  );
};

export default CollectionPage;
