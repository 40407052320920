import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CardBodySkeleton from './CardBodySkeleton';
import PublicCardBody from './PublicCardBody';
import PublicCardHeader from './PublicCardHeader';

const PublicCardDetailed = ({ cardId, isOpen, onClose }) => {
  const { t } = useTranslation();

  const {
    data: card,
    status,
    isSuccess,
    refetch: fetchCard
  } = useQuery(
    ['public', 'card', cardId],
    async () => {
      const { data } = await api.get(`/public/cards/${cardId}`);
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (isOpen && cardId) {
      fetchCard();
    }
  }, [fetchCard, isOpen, cardId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={['full', null, '3xl']}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mr={8}>
          {isSuccess && <PublicCardHeader card={card} />}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingWrapper
            statuses={[status]}
            errorMessages={[
              t('common.could_not_fetch_data_please_try_again_later', {
                data: t('common.card').toLowerCase()
              })
            ]}
            indicator={<CardBodySkeleton />}
          >
            {isSuccess && <PublicCardBody card={card} />}
          </LoadingWrapper>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default PublicCardDetailed;
