import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import CollectionListItem from 'features/collection/CollectionListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import TitleDescription from 'components/TitleDescription';

const BoardAccessText = ({ board }) => {
  const { t } = useTranslation();

  let label = '';
  let list = [];
  if (board?.access === 'HIVE') {
    label = t('board.visible_to_all_members_of_this_hive');
  }
  if (board?.access === 'DOMAINS') {
    label = `${t('board.visible_to_the_following_domains')}:`;
    list = board?.domains.map(domain => domain.domain);
  }
  if (board?.access === 'GROUPS') {
    label = `${t('board.visible_to_the_following_groups')}:`;
    list = board?.groups.map(member => member.name);
  }

  return (
    <Stack spacing={4}>
      <Text>{`${label}`}</Text>
      {list.length > 0 ? (
        <Box ml={4} pb={2}>
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Box>
      ) : (
        <Text ml={4} variant="muted">
          {board?.access === 'DOMAINS'
            ? t('board.no_domains_selected')
            : t('board.no_groups_selected')}
        </Text>
      )}
    </Stack>
  );
};

const BoardListItem = ({ board, hasCreateCollectionsPermission }) => {
  const {
    isOpen: showAccessModal,
    onOpen: openAccessModal,
    onClose: closeAccessModal
  } = useDisclosure();

  const { slug } = useParams();
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <TitleDescription
        noOfCharacters={250}
        title={board.title}
        description={board.description}
        button={
          <>
            <Spacer />
            <HStack spacing={2}>
              {hasCreateCollectionsPermission && (
                <Button size="sm" variant="outline" onClick={openAccessModal}>
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              )}
              <Button
                size="sm"
                as={Link}
                to={`/${slug}/board/${board.id}`}
                variant="outline"
              >
                {t('button.open')}
              </Button>
            </HStack>
          </>
        }
        isListItem
        to={`/${slug}/board/${board.id}`}
      />
      {board.collections.length > 0 && (
        <SimpleGrid columns={[1, null, 2]} spacing={6}>
          {board.collections.map(collection => (
            <CollectionListItem key={collection.id} collection={collection} />
          ))}
        </SimpleGrid>
      )}
      <Modal isOpen={showAccessModal} onClose={closeAccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('board.visibility_of_the_board_title', { title: board.title })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={8}>
            {board.access === 'MEMBERS' ? (
              <Stack spacing={8}>
                <Text>{t('board.visible_to_the_following_members')}:</Text>
                <Stack spacing={4}>
                  {board?.members?.map(member => (
                    <HStack
                      key={member.id}
                      spacing={4}
                      as={Link}
                      to={`/${slug}/member/${member?.id}`}
                    >
                      <Avatar
                        size="sm"
                        cursor="pointer"
                        src={
                          member?.avatar
                            ? member.avatar
                            : member?.third_party_avatar
                              ? member.third_party_avatar
                              : null
                        }
                        name={member?.full_name}
                      />
                      <Text whiteSpace="break-spaces">{member.full_name}</Text>
                    </HStack>
                  ))}
                </Stack>
              </Stack>
            ) : (
              <BoardAccessText board={board} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default BoardListItem;
