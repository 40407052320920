import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { getColorByCode } from './reportUtils';
import SelectSourcesForm from './SelectSourcesForm';
import SelectCollectionsForm from './SelectCollectionsForm';
import SelectCards from './SelectCards';
import GeneratedReportForm from './GeneratedReportForm';
import LoadingWrapper from 'components/LoadingWrapper';
import ChangeFolderModal from 'features/folders/ChangeFolderModal';
import FolderAssociationButton from 'features/folders/FolderAssociationButton';
import api from 'utils/api';

const fetchPrompts = async () => {
  const { data } = await api.get('/prompts');
  return data;
};

const CreateReportModal = ({ isOpen, onClose }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const [step, setStep] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [preselectedCards, setPreselectedCards] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [folder, setFolder] = useState(null);
  const [report, setReport] = useState(null);
  const [, setSelectedSources] = useState({
    collections: false
    // contributions: false,
    // surveys: false
  });
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const toast = useToast();

  const {
    data: prompts,
    status,
    isSuccess,
    refetch: refetchPrompts
  } = useQuery('prompts', fetchPrompts, {
    enabled: false
  });

  useEffect(() => {
    if (isOpen) {
      refetchPrompts();
    }
  }, [isOpen, refetchPrompts]);

  const createReportMutation = useMutation(
    payload => api.post('/reports', payload),
    {
      onSuccess: () => {
        onClose();
        toast({
          title: t('toast.report_create_success'),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.report_create_error'),
          status: 'error'
        });
      }
    }
  );

  useEffect(() => {
    setStep(1);
    setSelectedPrompt(null);
    setSelectedCollections([]);
    setSelectedCards([]);
    setFolder(null);
    setReport(null);
  }, [isOpen]);

  const handleOnSelectSources = async sources => {
    setSelectedSources(sources);
    setStep(2);
  };

  const handleOnSelectCollections = async values => {
    let params = new URLSearchParams();

    setSelectedCollections(values.collections);

    for (const collection of values.collections) {
      params.append('collection', collection.id);
    }
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `/collections/ai_cards?${params.toString()}`
      );
      setPreselectedCards(data);
      setSelectedCards(data);
      setStep(3);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSelectCards = async () => {
    setStep(4);
  };

  const handleOnSelectPrompt = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.post(
        `/prompts/${selectedPrompt?.id}/execute`,
        {
          hive: hive?.id,
          cards: selectedCards.map(card => card.id)
        }
      );
      setReport(data.content);
      setStep(5);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChangeFolder = async ({ folder }) => {
    setFolder(folder);
    closeChangeFolderModal();
  };

  const handleOnGenerateReport = async ({ report }) => {
    await createReportMutation.mutateAsync({
      report,
      folder: folder.id
    });
  };

  const {
    isOpen: showChangeFolderModal,
    onOpen: openChangeFolderModal,
    onClose: closeChangeFolderModal
  } = useDisclosure();

  return (
    <>
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <Flex position="fixed" left={0} top={0} width="full" zIndex={9999}>
            <Progress
              value={step}
              max={5}
              width="full"
              height="4px"
              colorScheme="teal"
            />
          </Flex>
          <Container max="container.sm" py="64px">
            <ModalHeader px={0}>{t('report.create_modal.header')}</ModalHeader>
            <ModalCloseButton />
            <LoadingWrapper
              statuses={[status]}
              errorMessages={[
                t('common.could_not_fetch_data_please_try_again_later', {
                  data: t('common.prompts').toLowerCase()
                })
              ]}
            >
              {isSuccess && (
                <Stack spacing="8">
                  {step === 5 ? (
                    <>
                      <Text>{t('report.create_modal.step_5_title')}</Text>
                      <FolderAssociationButton
                        folder={folder}
                        onClickMoveToFolder={openChangeFolderModal}
                      />
                      <GeneratedReportForm
                        folder={folder}
                        defaultValues={{ report }}
                        onSubmit={handleOnGenerateReport}
                      />
                    </>
                  ) : step === 4 ? (
                    <>
                      <Text>{t('report.create_modal.step_4_title')}</Text>
                      <SimpleGrid columns={[1, 2]} spacing={4}>
                        {prompts.map(prompt => (
                          <Card
                            borderWidth={4}
                            borderColor={
                              prompt.id === selectedPrompt?.id
                                ? null
                                : 'transparent'
                            }
                            key={prompt.id}
                            bg={() =>
                              getColorByCode(prompt.color, colorMode === 'dark')
                            }
                            cursor="pointer"
                            onClick={() => {
                              setSelectedPrompt(prompt);
                            }}
                          >
                            <CardBody>
                              <Stack>
                                <Heading size="xs" textTransform="uppercase">
                                  {prompt.name}
                                </Heading>
                                <Text fontSize="sm">{prompt.description}</Text>
                              </Stack>
                            </CardBody>
                          </Card>
                        ))}
                      </SimpleGrid>
                      <Button
                        alignSelf="flex-end"
                        colorScheme="teal"
                        isDisabled={!selectedPrompt}
                        isLoading={isLoading}
                        onClick={handleOnSelectPrompt}
                      >
                        {t('button.continue')}
                      </Button>
                    </>
                  ) : step === 3 ? (
                    <>
                      <Text>{t('report.create_modal.step_3_title')}</Text>
                      <SelectCards
                        preselectedCards={preselectedCards}
                        selectedCards={selectedCards}
                        setSelectedCards={setSelectedCards}
                        onSubmit={null}
                      />
                      <Button
                        alignSelf="flex-end"
                        colorScheme="teal"
                        isDisabled={selectedCards.length === 0}
                        isLoading={isLoading}
                        onClick={handleOnSelectCards}
                      >
                        {t('button.continue')}
                      </Button>
                    </>
                  ) : step === 2 ? (
                    <>
                      <Text>{t('report.create_modal.step_2_title')}</Text>
                      <SelectCollectionsForm
                        defaultValues={{ collections: selectedCollections }}
                        isLoading={isLoading}
                        onSubmit={handleOnSelectCollections}
                      />
                    </>
                  ) : (
                    <>
                      <Text>{t('report.create_modal.step_1_title')}</Text>
                      <SelectSourcesForm onSubmit={handleOnSelectSources} />
                    </>
                  )}
                </Stack>
              )}
            </LoadingWrapper>
          </Container>
          <HStack pointerEvents="auto" position="absolute" right={4} bottom={4}>
            <Button
              variant="outline"
              isDisabled={isLoading || step === 1}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
              type="submit"
              form="survey-form"
              variant="outline"
              isDisabled={isLoading || step === 5}
              onClick={() => {
                setStep(step + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </HStack>
        </ModalContent>
      </Modal>
      <ChangeFolderModal
        folder={folder}
        isOpen={showChangeFolderModal}
        onClose={closeChangeFolderModal}
        onChange={handleOnChangeFolder}
      />
    </>
  );
};

export default CreateReportModal;
