import {
  Box,
  Button,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  Flex,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { faEye, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccessForm from 'components/AccessForm';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import LabeledBox from 'components/LabeledBox';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import BoardOrderPage from 'features/board/BoardOrderPage';
import CollectionListItem from 'features/collection/CollectionListItem';
import { useUi } from 'providers/UiProvider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'utils/api';
import { findMedia } from 'utils/media';

const BoardListSectionItemBasic = ({ board }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Stack spacing={4}>
        <HStack
          spacing={4}
          width="full"
          alignItems="center"
          flexWrap="wrap"
          rowGap={2}
        >
          <Heading
            noOfLines={99}
            width="fit-content"
            wordBreak="break-word"
            fontSize="lg"
            flexGrow={2}
          >
            {board.title}
          </Heading>
          <Spacer />
          <Flex flexGrow={1} alignSelf="flex-start">
            <Spacer />
            <HStack spacing={2} justifyContent="space-between">
              <Button size="sm" variant="outline" pointerEvents="none">
                <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button size="sm" variant="outline">
                {t('button.see_more')}
              </Button>
            </HStack>
          </Flex>
        </HStack>
        <Description noOfLines={3}>{board.stripped_description}</Description>
      </Stack>
      {board.collections.length > 0 && (
        <SimpleGrid columns={[1, null, 2]} spacing={6}>
          {board.collections?.map(collection => (
            <Box pointerEvents="none" key={collection.id}>
              <CollectionListItem collection={collection} />
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Stack>
  );
};

const BoardListGalleryItemBasic = ({ board }) => {
  return (
    <ChakraCard variant="outline" overflow="hidden">
      {findMedia(board) && (
        <CoverMedia object={board} height="100px" rounded={null} />
      )}
      <ChakraCardBody>
        <Stack spacing={2}>
          <Heading fontSize="lg" noOfLines={2}>
            {board.title}
          </Heading>
          {board.description && (
            <Text noOfLines={3} fontSize="sm">
              {board.stripped_description}
            </Text>
          )}
        </Stack>
      </ChakraCardBody>
    </ChakraCard>
  );
};

const ManageBoards = ({ isOpen, defaultValues, onClose }) => {
  const {
    isOpen: showCreateBoardModal,
    onOpen: openCreateBoardModal,
    onClose: closeCreateBoardModal
  } = useDisclosure();

  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const [reorderedBoards, setReorderedBoards] = useState([]);
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    data: boards,
    isSuccess,
    status
  } = useQuery(
    ['boards', slug],
    async () => {
      const { data } = await api.get(`/boards?hive=${hive.id}`);
      return data;
    },
    { enabled: false }
  );

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const isInitialOrder =
    JSON.stringify(boards) === JSON.stringify(reorderedBoards);

  const watchBoardLayout = watch('board_layout');

  const createBoardMutation = useMutation(
    board => api.post('/boards', board, { timeout: 0 }),
    {
      onSuccess: ({ data: board }) => {
        closeCreateBoardModal();
        toast({
          title: t('toast.board_create_success'),
          status: 'success'
        });
        queryClient.setQueryData(['boards', slug], [board, ...boards]);
      },
      onError: () => {
        toast({
          title: t('toast.board_create_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateBoard = async board => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', board.image ? board.image : '');
    payload.append('unsplash', board.unsplash ? board.unsplash : '');
    payload.append('title', board.title);
    payload.append('description', board.description ? board.description : '');
    payload.append('access', board.access.id);
    if (board.domains) {
      board.domains.forEach(domain => {
        payload.append('domains', domain.id);
      });
    }
    if (board.groups) {
      board.groups.forEach(group => {
        payload.append('groups', group.id);
      });
    }
    if (board.members) {
      board.members.forEach(member => {
        payload.append('members', member.id);
      });
    }
    await createBoardMutation.mutateAsync(payload);
  };

  const reorderBoardsMutation = useMutation(
    newOrder =>
      api.patch(`/hives/${slug}/reorder-boards`, { boards: newOrder }),
    {
      onSuccess: () => {
        toast({
          title: t('toast.board_reorder_success'),
          status: 'success'
        });
        queryClient.setQueryData(['boards', slug], reorderedBoards);
        onClose();
        reset();
      },
      onError: () => {
        toast({
          title: t('toast.board_reorder_error'),
          status: 'error'
        });
      }
    }
  );

  const updateBoardLayoutMutation = useMutation(
    layout => api.patch(`/hives/${slug}`, { board_layout: layout }),
    {
      onSuccess: () => {
        toast({
          title: t('toast.board_layout_update_success'),
          status: 'success'
        });
        queryClient.setQueryData(['hive', slug], hive);
        onClose();
        reset();
      },
      onError: () => {
        toast({
          title: t('toast.board_layout_update_error'),
          status: 'error'
        });
      }
    }
  );

  const onSubmitBoardsLayout = async data => {
    const newOrder = data?.reorderedBoards?.map(board => board.id);
    if (newOrder) {
      await reorderBoardsMutation.mutateAsync(newOrder);
    }
    if (data.board_layout) {
      await updateBoardLayoutMutation.mutateAsync(data.board_layout);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setReorderedBoards(boards);
    }
  }, [isSuccess, setReorderedBoards, boards]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    setModalClosureLocked(isDirty || !isInitialOrder);
  }, [isDirty, setModalClosureLocked, isInitialOrder]);

  return (
    <>
      <form
        onSubmit={handleSubmit(values =>
          onSubmitBoardsLayout({ ...values, reorderedBoards })
        )}
      >
        <SimpleGrid templateColumns={['1fr', null, '1fr 2fr']} spacing={4}>
          <Stack spacing={4}>
            <LabeledBox slimHeading label={t('common.layout')}>
              <Controller
                name="board_layout"
                control={control}
                defaultValue={defaultValues?.board_layout}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <SimpleGrid spacing={4} columns={2}>
                      <Radio value="SECTIONS">
                        <Text fontSize="sm">{t('common.section_view')}</Text>
                      </Radio>
                      <Radio value="GALLERY">
                        <Text fontSize="sm">{t('common.gallery_view')}</Text>
                      </Radio>
                    </SimpleGrid>
                  </RadioGroup>
                )}
              />
            </LabeledBox>
            <LabeledBox
              slimHeading
              label={t('board.order')}
              right={
                <Button
                  mx={4}
                  colorScheme="teal"
                  size="xs"
                  onClick={openCreateBoardModal}
                >
                  <HStack spacing={2}>
                    <FontAwesomeIcon icon={faPlus} />
                    <Text>{t('common.board')}</Text>
                  </HStack>
                </Button>
              }
            >
              <LoadingWrapper
                statuses={[status]}
                errorMessages={[
                  t('common.could_not_fetch_data_please_try_again_later', {
                    data: t('common.boards').toLowerCase()
                  })
                ]}
              >
                <Stack spacing={4} maxHeight="45vh" overflowY="auto">
                  {isSuccess && (
                    <BoardOrderPage
                      boards={reorderedBoards}
                      setBoards={setReorderedBoards}
                    />
                  )}
                </Stack>
              </LoadingWrapper>
            </LabeledBox>
          </Stack>
          <Stack spacing={4}>
            <LabeledBox
              slimHeading
              overflowY="auto"
              maxHeight="62vh"
              label={t('common.preview_noun')}
            >
              <Box position="relative" height="75vh">
                <Stack
                  spacing={8}
                  width="150%"
                  position="absolute"
                  top="0"
                  style={{
                    transform: 'scale(0.65)',
                    transformOrigin: 'top left'
                  }}
                >
                  {watchBoardLayout === 'GALLERY' ? (
                    <SimpleGrid columns={2} spacing={4}>
                      {isSuccess && reorderedBoards
                        ? reorderedBoards?.map((board, index) => (
                            <BoardListGalleryItemBasic
                              key={index}
                              board={board}
                            />
                          ))
                        : [...Array(6)].map((_, index) => (
                            <Skeleton key={index} height="100px" />
                          ))}
                    </SimpleGrid>
                  ) : (
                    <SimpleGrid columns={1} spacing={12}>
                      {isSuccess && reorderedBoards
                        ? reorderedBoards?.map((board, index) => (
                            <BoardListSectionItemBasic
                              key={index}
                              board={board}
                            />
                          ))
                        : [...Array(4)].map((_, index) => (
                            <Skeleton key={index} height="100px" />
                          ))}
                    </SimpleGrid>
                  )}
                </Stack>
              </Box>
            </LabeledBox>
            <Button
              width="fit-content"
              alignSelf="flex-end"
              colorScheme="teal"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isInitialOrder && !isDirty}
            >
              {t('button.save')}
            </Button>
          </Stack>
        </SimpleGrid>
      </form>

      <ModalWrapper
        isOpen={showCreateBoardModal}
        onClose={closeCreateBoardModal}
      >
        <AccessForm
          isOpen={showCreateBoardModal}
          onSubmit={handleOnCreateBoard}
          showImageSelect
        />
      </ModalWrapper>
    </>
  );
};

export default ManageBoards;
