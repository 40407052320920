import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as teamsJs from '@microsoft/teams-js';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import ConfettiModal from 'components/ConfettiModal';
import Description from 'components/Description';

const UiContext = createContext();

const UiProvider = ({ children }) => {
  const {
    isOpen: showThankYouMessageDetailedModal,
    onOpen: openThankYouMessageDetailedModal,
    onClose: closeThankYouMessageDetailedModal
  } = useDisclosure();

  const [showMobileCreateButton, setShowMobileCreateButton] = useState(true);
  const [collectionRefetch, setCollectionRefetch] = useState(null);
  const [modalClosureLocked, setModalClosureLocked] = useState(false);
  const [scrollParent, setScrollParent] = useState(false);
  const [inTeams, setInTeams] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(false);

  const [filter, setFilter] = useState({});
  const scrollRef = useRef();
  const { pathname } = useLocation();

  const handleOnThankYou = message => {
    setThankYouMessage(message);
    openThankYouMessageDetailedModal();
  };

  useEffect(() => {
    teamsJs.app
      .initialize()
      .then(() => {
        teamsJs.app
          .getContext()
          .then(context => {
            console.log(context);
            if (
              context.app.host.name === teamsJs.HostName.teams ||
              context.app.host.name === teamsJs.HostName.teamsModern
            ) {
              console.log('App is running inside Microsoft Teams');
              setInTeams(true);
            } else {
              console.log('App is not running inside Microsoft Teams');
            }
          })
          .catch(() => {
            console.log('Failed to get Teams context');
          });
      })
      .catch(() => {
        console.log('Failed to initialize Teams SDK');
      });
  }, []);

  useEffect(() => {
    setFilter({});
  }, [pathname]);

  return (
    <UiContext.Provider
      value={{
        showMobileCreateButton,
        setShowMobileCreateButton,
        collectionRefetch,
        setCollectionRefetch,
        scrollRef,
        scrollParent,
        setScrollParent,
        modalClosureLocked,
        setModalClosureLocked,
        filter,
        setFilter,
        inTeams,
        handleOnThankYou
      }}
    >
      <Box>
        {children}
        <ConfettiModal
          isOpen={showThankYouMessageDetailedModal}
          onClose={closeThankYouMessageDetailedModal}
        >
          <Stack spacing={2}>
            <Description>{thankYouMessage}</Description>
          </Stack>
        </ConfettiModal>
      </Box>
    </UiContext.Provider>
  );
};

const useUi = () => useContext(UiContext);

export { UiProvider, useUi };
