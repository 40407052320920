import i18next from 'i18next';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Stack,
  HStack
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import IconTooltip from 'components/tooltips/IconTooltip';

const collectionTypeOptions = [
  { id: 'IDEA', value: i18next.t('common.ideas') },
  { id: 'INSIGHT', value: i18next.t('common.insights') },
  { id: 'POST', value: i18next.t('common.posts') },
  { id: 'FEEDBACK', value: i18next.t('common.feedback') },
  { id: 'SOLUTION', value: i18next.t('common.solutions') },
  { id: 'SUGGESTION', value: i18next.t('common.suggestions') }
];

const reactionOptions = [
  { value: '', label: i18next.t('workflow.no_reaction') },
  { value: 'LIKE', label: i18next.t('common.like') },
  { value: 'VOTE', label: i18next.t('common.vote') }
];

const WorkflowForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    const collectionType = defaultValues?.type
      ? collectionTypeOptions.find(
          collectionType => collectionType.id === defaultValues.type
        )
      : collectionTypeOptions[0];
    reset({
      ...defaultValues,
      type: collectionType,
      contribute_button_label: defaultValues?.contribute_button_label
        ? defaultValues.contribute_button_label
        : `+ ${i18next.t('common.contribute')}`
    });
  }, [defaultValues, reset, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <Input
            placeholder={t('placeholder.enter_name')}
            {...register('name', { required: true })}
            autoFocus
          />
        </FormControl>
        <FormControl>
          <HStack spacing={0} color="gray">
            <FormLabel>{t('template.what_are_you_collecting')}</FormLabel>
            <IconTooltip
              label={t('template.what_are_you_collecting_tooltip')}
            />
          </HStack>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder={t('common.select')}
                options={collectionTypeOptions}
                getOptionLabel={collectionType => collectionType.value}
                getOptionValue={collectionType => collectionType.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        <FormControl id="contribute_button_label">
          <HStack spacing={0}>
            <FormLabel>{t('workflow.contribute_button_label')}</FormLabel>
            <IconTooltip
              label={t('workflow.contribute_button_label_tooltip')}
              color="gray"
            />
          </HStack>
          <Input
            placeholder={t('placeholder.contribute_button_label_examples')}
            {...register('contribute_button_label', { required: false })}
          />
        </FormControl>
        <FormControl id="reaction_type">
          <FormLabel>{t('workflow.reaction_type')}</FormLabel>
          <Controller
            name="reaction_type"
            control={control}
            defaultValue={
              defaultValues?.reaction_type
                ? (reactionOptions.find(
                    ({ value }) => value === defaultValues.reaction_type
                  ) ?? '')
                : ''
            }
            rules={{ required: false }}
            render={({ field }) => (
              <Select
                placeholder={t('common.select')}
                options={reactionOptions}
                value={
                  reactionOptions.find(
                    reactionOption => reactionOption.value === field.value
                  ) || ''
                }
                onChange={selectedOption => {
                  field.onChange(selectedOption.value);
                }}
                useBasicStyles
                isSearchable={false}
              />
            )}
          />
        </FormControl>

        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default WorkflowForm;
