import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import Scrollable from 'components/Scrollable';
import LoginPage from 'features/auth/LoginPage';
import LoginRedirect from 'features/auth/LoginRedirect';
import LoginHeader from 'features/navigation/LoginHeader';
import { PublicCardProvider } from 'providers/PublicCardProvider';
import api from 'utils/api';

const canJoinOrHasProfile = async slug => {
  const { data } = await api.get(`/hives/${slug}/can-join-or-has-profile`);
  return data;
};

const HiveAccessRoute = ({ memberView, publicView }) => {
  const { pathname } = useLocation();

  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { isSuccess, isError } = useQuery(
    ['can-join-or-has-profile', slug],
    () => canJoinOrHasProfile(slug),
    {
      retry: false
    }
  );

  /* Check if can join */
  return isSuccess ? (
    memberView
  ) : hive?.public ? (
    <PublicCardProvider>
      <Flex flexDirection="column">
        <LoginHeader darkLogo={hive?.dark_logo} lightLogo={hive?.light_logo} />
        <Scrollable hasHeader>{publicView}</Scrollable>
      </Flex>
    </PublicCardProvider>
  ) : pathname === `/${slug}` ? (
    <LoginPage />
  ) : isError ? (
    <LoginRedirect />
  ) : null;
};

export default HiveAccessRoute;
