import { Button, Stack, Text } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import LoadingWrapper from 'components/LoadingWrapper';
import { useUi } from 'providers/UiProvider';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'utils/api';

const fetchAnnouncements = async () => {
  const { data } = await api.get(`/announcements`);
  return data.results;
};

const PopupAnnouncementForm = ({
  defaultValues,
  onSubmit,
  isOpen,
  refetchHive
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty }
  } = useForm({ mode: 'onChange', defaultValues });

  const {
    status,
    data: announcements,
    isSuccess,
    refetch: refetchAnnouncements
  } = useQuery(
    ['announcements', slug],
    async () => {
      const { data } = await api.get(`/announcements`);
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [isDirty, setModalClosureLocked]);

  useEffect(() => {
    if (isOpen) {
      refetchAnnouncements();
    }
  }, [isOpen, refetchAnnouncements]);

  useEffect(() => {
    if (!isOpen) {
      refetchHive();
      reset(defaultValues);
    }
  }, [refetchHive, isOpen, defaultValues, reset]);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.announcements').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Text>{t('home.set_popup_announcement_explainer')}</Text>
            <Controller
              control={control}
              name="popup_announcement"
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  defaultValue={defaultValues.popup_announcement}
                  defaultOptions={announcements}
                  loadOptions={() => fetchAnnouncements()}
                  placeholder={t('placeholder.search')}
                  getOptionLabel={collection => collection.title}
                  getOptionValue={collection => collection.id}
                  useBasicStyles
                  isClearable
                />
              )}
            />
            <Button
              isDisabled={!isDirty}
              type="submit"
              alignSelf="flex-end"
              colorScheme="teal"
              isLoading={isSubmitting}
            >
              {t('button.save')}
            </Button>
          </Stack>
        </form>
      )}
    </LoadingWrapper>
  );
};

export default PopupAnnouncementForm;
