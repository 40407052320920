// import { pages } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, HStack, MenuDivider, Show, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarGroupButton from 'components/AvatarGroupButton';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';
import {
  faFileExport,
  faFolder,
  faHome,
  faLink,
  faMessageHeart,
  faUserGroup
} from '@fortawesome/pro-regular-svg-icons';
import { Plans, hasPlanGreaterThanOrEqual } from 'features/hive/hiveUtils';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';

const CollectionControls = ({
  collection,
  onClickMoveToFolder,
  onClickDelete,
  onClickEdit,
  onClickExport,
  onClickManagers,
  onClickPublish,
  onClickSetThankYouMessage,
  onClickShare
}) => {
  const { inTeams } = useUi();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const { t } = useTranslation();

  return (
    <HStack>
      <Show above="lg">
        <Text variant="muted">{t('common.managers')}</Text>
        <AvatarGroupButton
          max={3}
          members={collection.managers}
          onClick={onClickManagers}
        />
        {!inTeams && hive?.use_share && (
          <Button variant="outline" onClick={onClickShare}>
            <HStack>
              <FontAwesomeIcon icon={faLink} />
              <Text>{`${t('common.share')} / ${t('common.invite')}`}</Text>
            </HStack>
          </Button>
        )}
      </Show>
      <ActionMenu>
        <CustomMenuItem
          icon={faUserGroup}
          label={t('collection.select_managers')}
          onClick={onClickManagers}
        />
        <MenuDivider />
        {hive?.use_share && (
          <CustomMenuItem
            icon={faLink}
            label={`${t('common.share')} / ${t('common.invite')}`}
            onClick={onClickShare}
          />
        )}
        <CustomMenuItem
          icon={faHome}
          label={t('collection.publish_on_home')}
          onClick={onClickPublish}
        />
        {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
          <CustomMenuItem
            icon={faFolder}
            label={
              collection?.folder
                ? t('collection.change_folder')
                : t('collection.add_to_folder')
            }
            onClick={onClickMoveToFolder}
          />
        )}
        <CustomMenuItem
          icon={faMessageHeart}
          label={t('collection.set_thank_you_message')}
          onClick={onClickSetThankYouMessage}
        />
        <MenuDivider />
        <CustomMenuItem
          icon={faFileExport}
          label={t('collection.export_collection')}
          onClick={onClickExport}
        />
        <MenuDivider />
        <AttachmentUploadComponent
          modelConfig={{
            singular: 'collection',
            plural: 'collections',
            modelId: collection.id
          }}
        />
        <MenuDivider />
        <EditMenuItem onClick={onClickEdit} />
        <DeleteMenuItem onClick={onClickDelete} />
      </ActionMenu>
    </HStack>
  );
};

export default CollectionControls;
