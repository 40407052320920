import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckToSlot, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckToSlot as faCheckToSlotSolid,
  faThumbsUp as faThumbsUpSolid
} from '@fortawesome/pro-solid-svg-icons';
import LoginModal from './modals/LoginModal';
import { useAuth } from 'providers/AuthProvider';

const Reaction = ({
  reactions,
  reacted,
  onClickReact,
  reactionType,
  useReactions
}) => {
  const { authenticated } = useAuth();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const { t } = useTranslation();

  const likedColor = useColorModeValue('blue.500', 'blue.200');
  const votedColor = useColorModeValue('teal.500', 'teal.200');

  return (
    <>
      <Stack
        spacing={4}
        justifyContent={['flex-end', null, 'flex-start']}
        alignItems="flex-end"
      >
        <ButtonGroup variant="outline">
          {reactionType && (
            <Tooltip
              isDisabled={useReactions}
              label={
                reactionType === 'VOTE'
                  ? t('card.vote_reaction_disabled_tooltip')
                  : t('card.like_reaction_disabled_tooltip')
              }
              placement="top"
              hasArrow
            >
              <Button
                isDisabled={!useReactions}
                onClick={() => {
                  if (authenticated) {
                    onClickReact();
                  } else {
                    openLoginModal();
                  }
                }}
              >
                <HStack spacing={2}>
                  <Text
                    color={
                      reacted
                        ? reactionType === 'VOTE'
                          ? votedColor
                          : likedColor
                        : null
                    }
                  >
                    <FontAwesomeIcon
                      icon={
                        reacted
                          ? reactionType === 'VOTE'
                            ? faCheckToSlotSolid
                            : faThumbsUpSolid
                          : reactionType === 'VOTE'
                            ? faCheckToSlot
                            : faThumbsUp
                      }
                    />
                  </Text>
                  <Text>
                    {reactionType === 'VOTE'
                      ? reacted
                        ? t('common.user_has_voted')
                        : t('common.vote_verb')
                      : reacted
                        ? t('common.user_has_liked')
                        : t('common.like_verb')}
                  </Text>
                  <Text>{reactions}</Text>
                </HStack>
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      </Stack>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
    </>
  );
};

export default Reaction;
