import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingWrapper from 'components/LoadingWrapper';
import LoginPage from 'features/auth/LoginPage';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';

const ACCESSIBLE_ROUTES = [
  '',
  'announcements',
  'preview/survey',
  'shared/collection',
  'shared/survey'
];

const fetchHive = async slug => {
  const { data } = await api.get(`/public/hives/${slug}`);
  return data;
};

const HiveRoute = () => {
  const { authenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();

  const { status, isSuccess } = useQuery(
    ['hive', slug],
    () => fetchHive(slug),
    {
      onSuccess: () => localStorage.setItem('slug', slug),
      onError: () => {
        localStorage.removeItem('slug');
        navigate(authenticated ? '/join' : '/');
      }
    }
  );

  const isAccessibleRoute = ACCESSIBLE_ROUTES.some(route =>
    location.pathname.includes(route)
  );

  return (
    <LoadingWrapper statuses={[status]}>
      {isSuccess && (authenticated || isAccessibleRoute) ? (
        <Outlet />
      ) : (
        <LoginPage />
      )}
    </LoadingWrapper>
  );
};

export default HiveRoute;
