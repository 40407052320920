import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import MemberAvatar from 'components/MemberAvatar';
import Linkify from 'linkify-react';
import { Link, useParams } from 'react-router-dom';
import { renderLink } from 'utils/link';
import api from 'utils/api';
import TranslateButton from 'components/TranslateButton';
import FieldAccessButton from 'components/FieldAccessButton';
import i18next from 'i18next';
import LanguageDetect from 'languagedetect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';

const accessOptions = [
  {
    id: 'COL',
    label: i18next.t('common.collection_managers'),
    longLabel: i18next.t('card.visible_to_access_role', {
      accessRole: i18next.t('common.collection_managers').toLowerCase()
    })
  },
  {
    id: 'CON',
    label: i18next.t('common.contributors'),
    longLabel: i18next.t('card.visible_to_access_role', {
      accessRole: i18next.t('common.contributors').toLowerCase()
    })
  },
  {
    id: 'EVE',
    label: i18next.t('common.everyone'),
    longLabel: i18next.t('card.visible_to_access_role', {
      accessRole: i18next.t('common.everyone').toLowerCase()
    })
  }
];

const detector = new LanguageDetect();

export const CommentListItemBasic = ({
  comment,
  commentTranslations,
  handleOnClickTranslate
}) => {
  const { slug } = useParams();
  const navigatable = false; //<-- to sidestep _blank not working //code ? false : true;
  const { t } = useTranslation();

  const languages = detector.detect(comment.comment, 2);

  return (
    <Stack>
      <Flex justifyContent="space-between">
        <HStack alignItems="flex-start" spacing={4} width="full">
          <Stack
            justifyContent="space-between"
            height="full"
            alignItems="center"
          >
            <MemberAvatar
              member={comment.creator}
              size="sm"
              link={navigatable}
            />
          </Stack>
          <Stack spacing={2} width="full">
            <HStack alignItems="center" justifyContent="space-between">
              {navigatable ? (
                <Link to={`/${slug}/member/${comment.creator.id}`}>
                  <Heading fontSize="sm">{comment.creator.full_name}</Heading>
                </Link>
              ) : (
                <Heading fontSize="sm">{comment.creator.full_name}</Heading>
              )}
              <FieldAccessButton
                field={{
                  field: t('common.visibility'),
                  read_access: comment?.read_access
                }}
                size="xs"
              />
            </HStack>
            <Linkify
              as={Text}
              options={{
                render: renderLink,
                format: value => {
                  if (value.charAt[0] === ' ') {
                    value = value.slice(1, value.length - 1);
                  }
                  return value;
                }
              }}
              fontSize="sm"
              whiteSpace="pre-line"
            >
              {commentTranslations[comment?.id]?.shown &&
              commentTranslations[comment?.id]?.text
                ? commentTranslations[comment?.id]?.text
                : comment.comment}
            </Linkify>
            <HStack>
              {languages &&
                languages.length > 0 &&
                languages[0][0] !== 'english' &&
                languages[0][1] > 0.2 && (
                  <TranslateButton
                    showTranslated={commentTranslations[comment?.id]?.shown}
                    onClick={handleOnClickTranslate}
                  />
                )}
            </HStack>
          </Stack>
        </HStack>
      </Flex>
    </Stack>
  );
};

const CommentForm = ({
  onSubmit,
  onFocusCallback,
  defaultValues,
  replyingTo = undefined,
  commentTranslations,
  handleOnSetTranslationShown,
  handleOnSetTranslationText
}) => {
  const { t } = useTranslation();

  const handleOnClickTranslate = async () => {
    if (!commentTranslations[replyingTo?.id]?.text) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [replyingTo.comment]
        });
        handleOnSetTranslationText(replyingTo.id, data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    handleOnSetTranslationShown(
      replyingTo.id,
      !commentTranslations[replyingTo?.id]?.shown
    );
  };

  const inModal = defaultValues || replyingTo;
  const isReply = replyingTo;

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        read_access:
          accessOptions.find(
            option => option.id === defaultValues?.read_access
          ) || accessOptions[1]
      });
    } else {
      reset({ read_access: accessOptions[1] });
    }
  }, [defaultValues, reset]);

  const textareaRef = useRef(null);

  const handleInput = e => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        {isReply && (
          <Stack spacing={4}>
            <CommentListItemBasic
              key={replyingTo.id}
              comment={replyingTo}
              commentTranslations={commentTranslations}
              handleOnClickTranslate={handleOnClickTranslate}
            />
          </Stack>
        )}
        <Stack spacing={2} position="relative" top={0} left={0}>
          <Textarea
            ref={textareaRef}
            onKeyPress={
              !inModal
                ? e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      handleSubmit(onSubmit)();
                    }
                  }
                : () => {}
            }
            onFocus={onFocusCallback}
            placeholder={t('placeholder.add_a_comment')}
            {...register('comment', { required: true })}
            defaultValue={defaultValues?.comment}
            height="min-content"
            maxHeight={['40vh', null, 'fit-content']}
            overflowY={['auto', null, 'hidden']}
            resize="none"
            onInput={handleInput}
            pb={[2, null, 12]}
            position="relative"
            _after={{
              content: '""',
              display: 'block',
              height: '30px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'white'
            }}
          />
          {isReply ? (
            <HStack spacing={4} justifyContent="flex-end">
              <Text fontSize="sm">
                <Trans
                  t={t}
                  i18nKey="card.reply_read_access_explainer"
                  components={[<span />, <strong />]}
                  values={{
                    accessRole: accessOptions.find(
                      option => option.id === replyingTo.read_access
                    )?.label
                  }}
                />
              </Text>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
                {t('common.reply_verb')}
              </Button>
            </HStack>
          ) : (
            <HStack
              spacing={2}
              justifyContent="flex-end"
              position={['static', null, 'absolute']}
              bottom={2}
              right={2}
              zIndex={2} //needed for click-priority even if typing in textarea
            >
              <Box width="fit-content">
                <FormControl id="read_access">
                  <Controller
                    name="read_access"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={accessOptions}
                        getOptionLabel={option => option.longLabel}
                        getOptionValue={option => option.id}
                        onChange={value => {
                          field.onChange(value);
                        }}
                        useBasicStyles
                        defaultValue={defaultValues?.read_access}
                        size="xs"
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <IconButton
                size="xs"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </IconButton>
            </HStack>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default CommentForm;
