import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Spacer,
  Stack
} from '@chakra-ui/react';

const AccordionBase = ({ left, right, children, ...rest }) => (
  <AccordionItem px={0} {...rest}>
    <HStack py={4} spacing={4}>
      <AccordionButton
        rounded="md"
        justifyContent="space-between"
        px={0}
        py={2}
        _hover={{
          bg: null
        }}
      >
        {left}
        <Spacer />
        {right}
        <AccordionIcon />
      </AccordionButton>
    </HStack>
    <AccordionPanel padding={0}>
      <Stack py={4} spacing={8}>
        {children}
      </Stack>
    </AccordionPanel>
  </AccordionItem>
);

export default AccordionBase;
