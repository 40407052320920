import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Switch
} from '@chakra-ui/react';

const InboxStepForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="hide_cards" mb="0">
            {t('step.hide_cards')}
          </FormLabel>
          <Switch
            id="hide_inbox_cards"
            {...register('hide_inbox_cards')}
            defaultChecked={defaultValues?.hide_inbox_cards}
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="inbox_use_reactions" mb="0">
            {t('step.use_reactions')}
          </FormLabel>
          <Switch
            id="inbox_use_reactions"
            {...register('inbox_use_reactions')}
            defaultChecked={defaultValues?.inbox_use_reactions}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default InboxStepForm;
