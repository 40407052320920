import { useCallback, useEffect, useRef } from 'react';
import api from './api';

const useDebouncedLoadOptions = (fetchFunction, delay = 1000) => {
  const timeoutRef = useRef(null);

  return useCallback(
    (inputValue, callback) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        const options = await fetchFunction(inputValue);
        callback(options);
      }, delay);
    },
    [fetchFunction, delay]
  );
};

const useGlobalErrorLogger = () => {
  useEffect(() => {
    const errorHandler = (message, source, lineno, colno, error) => {
      api
        .post('/logs', {
          message: message || error?.message || 'Unknown error',
          level: 'ERROR',
          url: window.location.href,
          user_agent: navigator.userAgent
        })
        .catch(err => console.error('Logging API failed:', err));
    };

    const rejectionHandler = event => {
      api
        .post('/logs', {
          message: event.reason?.message || 'Unhandled promise rejection',
          level: 'ERROR',
          url: window.location.href,
          user_agent: navigator.userAgent
        })
        .catch(err => console.error('Logging API failed:', err));
    };

    // Capture React-specific warnings (like key prop issues)
    const originalConsoleError = console.error;
    console.error = (...args) => {
      let message = 'Unknown error';

      if (typeof args[0] === 'string') {
        message = args[0];
      } else if (args[0] instanceof Error) {
        message = args[0].message;
      } else if (args[0]?.isAxiosError) {
        message = `AxiosError: ${args[0].message}`;
      } else {
        try {
          message = JSON.stringify(args[0]); // Handle objects
        } catch (e) {
          message = '[Unserializable error object]';
        }
      }

      // Send error log but prevent infinite loops
      try {
        api
          .post('/logs', {
            message: message,
            level: 'ERROR',
            url: window.location.href,
            user_agent: navigator.userAgent
          })
          .catch(err => {
            // Use originalConsoleError to prevent recursion
            originalConsoleError('Logging API failed:', err);
          });
      } catch (e) {
        // Use originalConsoleError instead of console.error to avoid recursion
        originalConsoleError('Unexpected error in logging mechanism:', e);
      }

      originalConsoleError(...args);
    };

    // Attach event listeners
    window.onerror = errorHandler;
    window.addEventListener('unhandledrejection', rejectionHandler);

    return () => {
      // Properly remove the correct listener
      window.onerror = null;
      window.removeEventListener('unhandledrejection', rejectionHandler);
      console.error = originalConsoleError;
    };
  }, []);
};

export { useDebouncedLoadOptions, useGlobalErrorLogger };
