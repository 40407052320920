import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

const smartTruncateMarkdown = (markdown, limit) => {
  if (!limit || markdown.length <= limit) return markdown;

  let truncated = markdown.substring(0, limit);
  let extendedLimit = limit;
  let needsEllipsis = markdown.length > limit;

  const markdownPatterns = [
    { pattern: /\*\*[^*]+\*?$/, fix: '**' }, // Fix broken bold (**bold**)
    { pattern: /_[^_]+_?$/, fix: '_' }, // Fix broken italic (_italic_)
    { pattern: /\[[^\]]*$/, fix: ']' }, // Fix broken link text [Text]
    { pattern: /\]\([^)]*$/, fix: ')' } // Fix broken link URL (https://example.com)
  ];

  markdownPatterns.forEach(({ pattern, fix }) => {
    const match = truncated.match(pattern);
    if (match) {
      const fixIndex = markdown.indexOf(fix, limit);
      if (fixIndex !== -1) {
        extendedLimit = Math.max(extendedLimit, fixIndex + fix.length);
        needsEllipsis = markdown.length > extendedLimit;
      }
    }
  });

  // Handle incomplete markdown links of the form [label](url)
  const linkStartMatch = truncated.match(/\[([^\]]*)\]\(/);
  if (linkStartMatch) {
    const closeParenIndex = markdown.indexOf(')', limit);
    if (closeParenIndex !== -1) {
      extendedLimit = Math.max(extendedLimit, closeParenIndex + 1);
      needsEllipsis = markdown.length > extendedLimit;
    }
  }

  truncated = markdown.substring(0, extendedLimit);

  // Ensure properly formatted links at the end
  truncated = truncated.replace(/\[(https?:\/\/[^\s\]]+)]$/, (_, fullUrl) => {
    return `[${fullUrl}](${fullUrl})...`;
  });

  if (
    needsEllipsis &&
    !truncated.endsWith('...') &&
    !/\]\(.*\)$/.test(truncated)
  ) {
    truncated += '...';
  }

  return truncated;
};

const Description = ({ noOfCharacters, children = '' }) => {
  const truncatedContent = useMemo(
    () => smartTruncateMarkdown(children, noOfCharacters),
    [children, noOfCharacters]
  );

  return (
    <ReactMarkdown
      components={{
        ...ChakraUIRenderer(),
        p: props => <Text {...props} />,
        li: props => <ListItem {...props} />,
        ul: props => <UnorderedList {...props} />,
        ol: props => <OrderedList {...props} />,
        a: props => {
          const { href, children: linkText } = props;
          return (
            <Link href={href} target="_blank" rel="noopener noreferrer">
              {linkText}
            </Link>
          );
        }
      }}
      children={truncatedContent}
      skipHtml
    />
  );
};

export default Description;
