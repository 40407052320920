import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import AsyncSwitch from 'components/SpinnerSwitch';
import SpinnerSwitch from 'components/SpinnerSwitch';

const WorkflowSettings = ({ onUpdateWorkflow, workflow, submittingIndex }) => {
  const { t } = useTranslation();

  return (
    <>
      <Table variant="unstyled">
        <Tbody>
          <Tr>
            <Td pl={0}>{t('workflow.visibility_setting_label')}</Td>
            <Td pr={0} textAlign="end">
              <AsyncSwitch
                isChecked={workflow.use_card_visibility}
                optionKey="use_card_visibility"
                onChange={() => {
                  onUpdateWorkflow(
                    {
                      use_card_visibility: !workflow.use_card_visibility
                    },
                    0
                  );
                }}
              />
            </Td>
          </Tr>
          <Tr>
            <Td pl={0}>{t('workflow.votes_setting_label')}</Td>
            <Td pr={0} textAlign="end">
              <AsyncSwitch
                isChecked={workflow.use_card_reactions}
                optionKey="use_card_reactions"
                onChange={() => {
                  onUpdateWorkflow(
                    {
                      use_card_reactions: !workflow.use_card_reactions
                    },
                    1
                  );
                }}
              />
            </Td>
          </Tr>
          <Tr>
            <Td pl={0}>{t('workflow.assignees_setting_label')}</Td>
            <Td pr={0} textAlign="end">
              <SpinnerSwitch
                isChecked={workflow.use_card_assignees}
                optionKey="use_card_assignees"
                onChange={() => {
                  onUpdateWorkflow(
                    {
                      use_card_assignees: !workflow.use_card_assignees
                    },
                    2
                  );
                }}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default WorkflowSettings;
