import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, HStack, Stack, Text } from '@chakra-ui/react';

const SelectSourcesForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } = useForm();

  const options = [
    {
      field: 'collections',
      textKey: t('common.collections')
    }
    // {
    //   field: 'contributions',
    //   textKey: t('common.contributions')
    // },
    // {
    //   field: 'surveys',
    //   textKey: t('common.surveys')
    // }
  ];

  const values = watch();
  const isSubmitDisabled = !Object.values(values).some(value => value === true);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="8">
        <Stack spacing="2">
          {options.map(option => (
            <Controller
              key={option.field}
              name={option.field}
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Box
                  borderWidth={1}
                  borderRadius="lg"
                  cursor="pointer"
                  padding={4}
                  onClick={() => onChange(!value)}
                >
                  <HStack spacing={4}>
                    <Checkbox isChecked={value} pointerEvents="none" />
                    <Text>{t(option.textKey)}</Text>
                  </HStack>
                </Box>
              )}
            />
          ))}
        </Stack>
        <Button
          colorScheme="teal"
          type="submit"
          alignSelf="flex-end"
          isDisabled={isSubmitDisabled}
        >
          {t('button.continue')}
        </Button>
      </Stack>
    </form>
  );
};

export default SelectSourcesForm;
