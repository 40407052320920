import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Plans, hasPlanGreaterThanOrEqual } from 'features/hive/hiveUtils';
import {
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Spacer,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import CreateButton from 'components/CreateButton';
import NavigationDrawer from './NavigationDrawer';
import NavigationMenu from './NavigationMenu';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import NotificationDrawer from 'features/notification/NotificationDrawer';
import api from 'utils/api';

const Header = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const {
    isOpen: showNavigationDrawer,
    onOpen: openNavigationDrawer,
    onClose: closeNavigationDrawer
  } = useDisclosure();

  const {
    isOpen: showNotificationDrawer,
    onOpen: openNotificationDrawer,
    onClose: closeNotificationDrawer
  } = useDisclosure();

  const logo = useColorModeValue(
    hive.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive.light_logo ? hive.light_logo : '/hives_light.png'
  );

  return (
    <>
      <Flex
        alignItems="center"
        borderBottomWidth={1}
        height="70px"
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Flex width={{ xl: '100px' }} pl={4}>
          {profile.is_admin_or_manager &&
            hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
              <Button variant="outline" onClick={openNavigationDrawer}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            )}
        </Flex>
        <Container maxW="container.lg">
          <HStack>
            {me && (
              <Link to={`/${slug}`}>
                <Image src={logo} height={8} />
              </Link>
            )}
            <Spacer />
            <CreateButton />
            <Link to={`/${slug}/search`}>
              <Button variant="outline">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Link>
            <NavigationMenu
              me={me}
              onClickNotifications={openNotificationDrawer}
            />
          </HStack>
        </Container>
        <Flex width={{ xl: '100px' }} />
      </Flex>
      <NavigationDrawer
        logo={logo}
        isOpen={showNavigationDrawer}
        onClose={closeNavigationDrawer}
      />
      <NotificationDrawer
        isOpen={showNotificationDrawer}
        onClose={closeNotificationDrawer}
      />
    </>
  );
};

export default Header;
