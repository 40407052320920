import { useTranslation } from 'react-i18next';
import {
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import ChartSkeleton from 'features/chart/ChartSkeleton';
import { useCard } from 'providers/CardProvider';
import RechartsScatterChart from './RechartsScatterChart';

const HeatmapTooltip = ({ active, payload }) => {
  const { showCardModal } = useCard();
  const { t } = useTranslation();

  const bg = useColorModeValue('gray.100', 'gray.600');
  if (active && payload && payload.length) {
    return (
      <Stack bg={bg} p={4} opacity={0.9}>
        <Text fontWeight="bold">{payload[0].payload.title}</Text>
        <HStack>
          <Text>{t('common.views')}</Text>
          <Spacer />
          <Text fontWeight="bold">{payload[0].value}</Text>
        </HStack>
        <HStack>
          <Text>{t('common.reactions')}</Text>
          <Spacer />
          <Text fontWeight="bold">{payload[1].value}</Text>
        </HStack>
        {!showCardModal && (
          <Text opacity="0.4" fontWeight="light" fontStyle="italic">
            {t('common.click_to_open')}
          </Text>
        )}
      </Stack>
    );
  }
  return null;
};

const Heatmap = ({ data, status, isSuccess, emptyState }) => {
  /* 
    TODO: This feels like a hack! 
    We need to clear the state of the chart when displaying a card otherwise
    the tooltip gets stuck. Could not find any useful information on how to clear the
    state in the recharts documentation.

    const [chartKey, setChartKey] = useState(Math.random());
    */

  const { showCardModal } = useCard();
  const { t } = useTranslation();

  return (
    <LoadingWrapper
      indicator={
        <Stack>
          <ChartSkeleton />
        </Stack>
      }
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.metrics').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        data?.length > 0 ? (
          <RechartsScatterChart
            data={data}
            tooltip={<HeatmapTooltip showCardModal={showCardModal} />}
            height="65vh"
          />
        ) : (
          emptyState
        )
      ) : null}
    </LoadingWrapper>
  );
};

export default Heatmap;
