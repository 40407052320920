import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faEye } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

export const FieldAccessText = ({ field }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const checkColor = useColorModeValue(
    theme.colors.teal['400'],
    theme.colors.teal['200']
  );

  const getFieldAccessList = access => {
    let grayedOutUntilIndex = 0;
    let list = [
      t('common.guests'),
      t('common.members'),
      t('common.contributors'),
      t('common.assignees'),
      t('common.collection_managers'),
      t('common.panel'),
      t('common.managers'),
      t('common.admins')
    ];

    if (access === 'EVE') {
      grayedOutUntilIndex = 1;
    }
    if (access === 'CON') {
      grayedOutUntilIndex = 2;
    }
    if (access === 'COL' || access === null) {
      grayedOutUntilIndex = 4;
    }

    return (
      <ul>
        {list.map((item, index) => (
          <HStack key={index} spacing={2}>
            <FontAwesomeIcon
              color={checkColor}
              icon={faCheck}
              opacity={index >= grayedOutUntilIndex ? 1.0 : 0.0}
            />
            <li
              style={{
                opacity: index >= grayedOutUntilIndex ? 1.0 : 0.25,
                listStyleType: 'none'
              }}
              key={index}
            >
              {item}
            </li>
          </HStack>
        ))}
      </ul>
    );
  };

  return (
    <HStack
      justifyContent="space-between"
      spacing={8}
      flexWrap="wrap"
      rowGap={4}
    >
      <Stack spacing={2}>
        <Heading fontSize="md">{t('template.field_visible_to')}</Heading>
        <Box ml={4} pb={2}>
          {getFieldAccessList(field.read_access)}
        </Box>
      </Stack>
      {field.write_access && (
        <Stack spacing={2}>
          <Heading fontSize="md">{t('template.field_answerable_by')}</Heading>
          <Box ml={4} pb={2}>
            {getFieldAccessList(field.write_access)}
          </Box>
        </Stack>
      )}
    </HStack>
  );
};

const FieldAccessButton = ({ field, ...rest }) => {
  const {
    isOpen: showAccessModal,
    onOpen: openAccessModal,
    onClose: closeAccessModal
  } = useDisclosure();

  return (
    <>
      <Button
        maxWidth="fit-content"
        size="sm"
        variant="outline"
        onClick={openAccessModal}
        {...rest}
      >
        <HStack spacing={0.5}>
          <FontAwesomeIcon icon={faEye} />
          <Show above="xs">
            <Text opacity="50%" fontWeight="100" fontSize="sx">
              /
            </Text>
            <FontAwesomeIcon icon={faEdit} />
          </Show>
        </HStack>
      </Button>
      <Modal isOpen={showAccessModal} onClose={closeAccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{field.field}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FieldAccessText field={field} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FieldAccessButton;
