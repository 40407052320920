import { formatDistance } from 'date-fns';
import Linkify from 'linkify-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import MemberAvatar from 'components/MemberAvatar';
import TranslateButton from 'components/TranslateButton';
import api from 'utils/api';
import { renderLink } from 'utils/link';
import ReplyList from './ReplyList';
import FieldAccessButton from 'components/FieldAccessButton';

const CommentListItem = ({
  code,
  comment,
  depth,
  detector,
  onClickDelete,
  onClickReply,
  onClickUpdate,
  isAdminOrAssigneeOrCollectionManager,
  shouldRefetch,
  setShouldRefetch,
  commentTranslations,
  handleOnSetTranslationText,
  handleOnSetTranslationShown
}) => {
  const { slug } = useParams();
  const { data: me } = useQuery('me', { enabled: false });
  const { t } = useTranslation();
  // const [translations, setTranslations] = useState(null);
  // const [showTranslated, setShowTranslated] = useState(false);

  const languages = detector.detect(comment.comment, 2);

  const { isOpen: showReplies, onToggle: toggleReplies } = useDisclosure();

  const handleOnClickTranslate = async () => {
    if (!commentTranslations[comment?.id]?.text) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [comment.comment]
        });
        handleOnSetTranslationText(comment.id, data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    handleOnSetTranslationShown(
      comment.id,
      !commentTranslations[comment?.id]?.shown
    );
  };

  const navigatable = code ? false : true;

  const isAuthor = me?.id === comment.creator.id;

  return (
    <Flex justifyContent="space-between">
      <HStack alignItems="flex-start" spacing={4} width="full">
        <Stack justifyContent="space-between" height="full" alignItems="center">
          <MemberAvatar member={comment.creator} size="sm" link={navigatable} />
        </Stack>
        <Stack spacing={2} width="full">
          <HStack alignItems="center" justifyContent="space-between">
            {navigatable ? (
              <Link to={`/${slug}/member/${comment.creator.id}`}>
                <Heading fontSize="sm">{comment.creator.full_name}</Heading>
              </Link>
            ) : (
              <Heading fontSize="sm">{comment.creator.full_name}</Heading>
            )}
            <HStack spacing={2}>
              <FieldAccessButton
                field={{
                  field: t('common.visibility'),
                  read_access: comment?.read_access
                }}
                size="xs"
              />
              {(isAuthor || isAdminOrAssigneeOrCollectionManager) && (
                <ActionMenu size="xs">
                  {isAuthor && (
                    <EditMenuItem
                      onClick={() => {
                        handleOnSetTranslationText(comment.id, null);
                        handleOnSetTranslationShown(comment.id, true);
                        onClickUpdate(comment);
                      }}
                    />
                  )}
                  {(isAuthor || isAdminOrAssigneeOrCollectionManager) && (
                    <DeleteMenuItem
                      onClick={() => {
                        onClickDelete(comment);
                      }}
                    />
                  )}
                </ActionMenu>
              )}
            </HStack>
          </HStack>
          <Linkify
            as={Text}
            options={{
              render: renderLink,
              format: value => {
                if (value.charAt[0] === ' ') {
                  value = value.slice(1, value.length - 1);
                }
                return value;
              }
            }}
            fontSize={['xs', 'sm', null]}
            whiteSpace="pre-line"
            ml={[-8, 0, null]}
            mt={[2, 0, null]}
          >
            {commentTranslations[comment?.id]?.shown &&
            commentTranslations[comment?.id]?.text
              ? commentTranslations[comment?.id]?.text
              : comment.comment}
          </Linkify>
          <HStack ml={[-8, 0, null]}>
            <Button
              variant="link"
              size="xs"
              onClick={() => {
                onClickReply(comment, depth);
              }}
            >
              {t('common.reply_verb')}
            </Button>
            {languages &&
              languages.length > 0 &&
              languages[0][0] !== 'english' &&
              languages[0][1] > 0.2 && (
                <>
                  <Box>•</Box>
                  <TranslateButton
                    showTranslated={commentTranslations[comment?.id]?.shown}
                    onClick={handleOnClickTranslate}
                  />
                </>
              )}
            <Text
              textAlign="right"
              fontSize="xs"
              width="full"
              overflow="visible"
            >
              {formatDistance(new Date(comment.created_at), new Date(), {
                addSuffix: true
              })}
            </Text>
          </HStack>
          {comment.metrics.replies > 0 && (
            <Button
              alignSelf="flex-start"
              variant="link"
              onClick={toggleReplies}
              size="sm"
              my={2}
            >
              <HStack justifyContent="flex-start" width="full">
                <FontAwesomeIcon icon={faReply} />
                <Text>
                  {/*
                TODO: hacky solution to avoid getting the translation keys removed. It
                would be nice to use this solution instead: https://www.i18next.com/translation-function/plurals
                but it has to play nicely with i18next-scanner.
              */}
                  {comment.metrics.replies === 1
                    ? `${comment.metrics.replies} ${t(
                        'common.reply_with_count_one'
                      )}`
                    : `${comment.metrics.replies} ${t(
                        'common.reply_with_count_other'
                      )}`}
                </Text>
              </HStack>
            </Button>
          )}
          {comment.metrics.replies > 0 && (
            <Collapse in={showReplies}>
              <ReplyList
                code={code}
                commentId={comment.id}
                detector={detector}
                onClickReply={onClickReply}
                onClickDelete={onClickDelete}
                onClickUpdate={onClickUpdate}
                isAdminOrAssigneeOrCollectionManager={
                  isAdminOrAssigneeOrCollectionManager
                }
                depth={depth + 1}
                shouldRefetch={shouldRefetch}
                setShouldRefetch={setShouldRefetch}
                commentTranslations={commentTranslations}
                handleOnSetTranslationText={handleOnSetTranslationText}
                handleOnSetTranslationShown={handleOnSetTranslationShown}
              />
            </Collapse>
          )}
        </Stack>
      </HStack>
    </Flex>
  );
};

export default CommentListItem;
