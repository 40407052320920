import React from 'react';
import { Divider, Stack } from '@chakra-ui/react';
import SelectAnswer from './SelectAnswer';
import TextAnswer from './TextAnswer';
import VoteResults from './VoteResults';
import { findAnswer } from './cardUtils';

const PublicCardFields = ({ card, fields, lastField = null }) => {
  const renderField = (card, field) => {
    const isLastField = lastField ? field.id === lastField.id : false;
    if (field.type === 'INPUT') {
      const answer = findAnswer(field, card.public_answers);
      return (
        <Stack spacing={8}>
          <TextAnswer
            answer={answer}
            field={field}
            canEdit={false}
            locked
            forReport
          />
          {!isLastField && <Divider />}
        </Stack>
      );
    } else if (field.type === 'MULTI_SELECT') {
      const answer = findAnswer(field, card.public_selections);
      // TODO: FIX!
      if (!answer) {
        return null;
      }
      return field.many ? (
        <Stack spacing={8}>
          <VoteResults
            field={field}
            options={answer.options}
            cardId={card.id}
            locked
            forReport
          />
          {!isLastField && <Divider />}
        </Stack>
      ) : (
        <Stack spacing={8}>
          <SelectAnswer
            answer={answer}
            field={field}
            locked={field.step?.id !== card.step?.id}
            forReport
          />
          {!isLastField && <Divider />}
        </Stack>
      );
    } else if (field.type === 'RATING' && field.chart) {
      return null;
      // return (
      //   <Chart card={card} chart={field.chart} field={field} forReport>
      //     {!isLastField && <Divider />}
      //   </Chart>
      // );
    } else if (field.type === 'SINGLE_SELECT') {
      const answer = findAnswer(field, card.public_selections);
      return (
        <Stack spacing={8}>
          <SelectAnswer answer={answer} field={field} locked forReport />
          {!isLastField && <Divider />}
        </Stack>
      );
    }
    return null;
  };

  return fields.length > 0 ? (
    <>
      {fields.map(field => {
        return <Stack key={field.id}>{renderField(card, field, false)}</Stack>;
      })}
    </>
  ) : null;
};

export default PublicCardFields;
