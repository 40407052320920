import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import CardDetailed from 'features/card/CardDetailed';
import { useDisclosure } from '@chakra-ui/react';

const CardContext = createContext();

const CardProvider = ({ children }) => {
  const { code: codeParam } = useParams();

  const [code, setCode] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [carouselCardIds, setCarouselCardIds] = useState([]);
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [shouldRefetchCard, setShouldRefetchCard] = useState(false);
  const [shouldRefetchCardList, setShouldRefetchCardList] = useState(false);

  const location = useLocation();

  const [searchParams] = useSearchParams();
  const {
    isOpen: showCardModal,
    onOpen: openCardModal,
    onClose: closeCardModal
  } = useDisclosure();

  const openCard = (cardId, code = null) => {
    setCardId(cardId);
    setCode(code);
    openCardModal();
  };

  /*
    Refactor: should convert cardId to integer before comparing in case it's a string
  */
  useEffect(() => {
    if (carouselCardIds.length > 0) {
      // eslint-disable-next-line eqeqeq
      const currentIndex = carouselCardIds.findIndex(card => card == cardId);
      setCarouselPosition(currentIndex + 1);
    }
  }, [cardId, carouselCardIds]);

  const nextCard = () => {
    // eslint-disable-next-line eqeqeq
    const currentIndex = carouselCardIds.findIndex(card => card == cardId);
    setCardId(
      carouselCardIds[
        currentIndex < carouselCardIds.length - 1 ? currentIndex + 1 : 0
      ]
    );
  };

  const previousCard = () => {
    // eslint-disable-next-line eqeqeq
    const currentIndex = carouselCardIds.findIndex(card => card == cardId);
    setCardId(
      carouselCardIds[
        currentIndex > 0 ? currentIndex - 1 : carouselCardIds.length - 1
      ]
    );
  };

  useEffect(() => {
    closeCardModal();
  }, [closeCardModal, location]);

  useEffect(() => {
    const card = searchParams.get('card');
    if (card) {
      setCardId(card);
      setCode(codeParam);
      openCardModal();
    }
  }, [location, codeParam, searchParams, openCardModal]);

  return (
    <CardContext.Provider
      value={{
        carouselPosition,
        showCardModal,
        openCard,
        carouselCardIds,
        setCarouselCardIds,
        nextCard,
        previousCard,
        shouldRefetchCard,
        setShouldRefetchCard,
        shouldRefetchCardList,
        setShouldRefetchCardList
      }}
    >
      {children}
      <CardDetailed
        code={code}
        cardId={cardId}
        isOpen={showCardModal}
        onClose={closeCardModal}
      />
    </CardContext.Provider>
  );
};

const useCard = () => useContext(CardContext);

export { CardProvider, useCard };
