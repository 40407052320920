import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import AnnouncementDetailed from './AnnouncementDetailed';
import AnnouncementListItem from './AnnouncementListItem';

const PublicAnnouncementList = () => {
  const { slug } = useParams();

  const {
    isOpen: showAnnouncementModal,
    onOpen: openAnnouncementModal,
    onClose: closeAnnouncementModal
  } = useDisclosure();

  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const { t } = useTranslation();

  const {
    status,
    data: announcements,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess
  } = useInfiniteQuery(
    ['public', 'announcements', slug],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/public/announcements?offset=${pageParam}&limit=10`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="2xl">{t('common.announcements')}</Heading>
        </Flex>
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.announcements').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <>
              <InfiniteScrollHelper
                hasMore={!isFetching && hasNextPage}
                loadMore={fetchNextPage}
              >
                <Stack spacing={4}>
                  {announcements.pages.map(page =>
                    page.results.map(announcement => (
                      <AnnouncementListItem
                        key={announcement.id}
                        announcement={announcement}
                        onClickReadMore={() => {
                          setSelectedAnnouncement(announcement);
                          openAnnouncementModal();
                        }}
                      />
                    ))
                  )}
                  {isFetching && <LoadingIndicator />}
                </Stack>
              </InfiniteScrollHelper>
              {selectedAnnouncement && (
                <AnnouncementDetailed
                  announcement={selectedAnnouncement}
                  canEdit={false}
                  isOpen={showAnnouncementModal}
                  onClose={closeAnnouncementModal}
                />
              )}
            </>
          ) : null}
        </LoadingWrapper>
      </Stack>
    </Container>
  );
};

export default PublicAnnouncementList;
