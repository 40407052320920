import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import PublicCardDetailed from 'features/card/PublicCardDetailed';

const PublicCardContext = createContext();

const PublicCardProvider = ({ children }) => {
  const [cardId, setCardId] = useState(null);

  const location = useLocation();

  const [searchParams] = useSearchParams();
  const {
    isOpen: showCardModal,
    onOpen: openCardModal,
    onClose: closeCardModal
  } = useDisclosure();

  const openCard = cardId => {
    setCardId(cardId);
    openCardModal();
  };

  useEffect(() => {
    closeCardModal();
  }, [closeCardModal, location]);

  useEffect(() => {
    const card = searchParams.get('card');
    if (card) {
      setCardId(card);
      openCardModal();
    }
  }, [location, searchParams, openCardModal]);

  return (
    <PublicCardContext.Provider
      value={{
        showCardModal,
        openCard
      }}
    >
      {children}
      <PublicCardDetailed
        cardId={cardId}
        isOpen={showCardModal}
        onClose={closeCardModal}
      />
    </PublicCardContext.Provider>
  );
};

const usePublicCard = () => useContext(PublicCardContext);

export { PublicCardProvider, usePublicCard };
