import {
  Badge,
  Card,
  CardBody,
  Checkbox,
  Flex,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SelectCards = ({ preselectedCards, selectedCards, setSelectedCards }) => {
  const { t } = useTranslation();

  const handleOnClickCard = card => {
    if (selectedCards.some(selectedCard => selectedCard.id === card.id)) {
      setSelectedCards(
        selectedCards.filter(selectedCard => selectedCard.id !== card.id)
      );
    } else {
      setSelectedCards([...selectedCards, card]);
    }
  };

  return (
    <Stack spacing="4" maxHeight="500px" overflowY="auto">
      {preselectedCards.map(card => (
        <Card
          key={card.id}
          cursor="pointer"
          onClick={() => handleOnClickCard(card)}
          userSelect="none"
        >
          <CardBody>
            <HStack spacing="4">
              <Checkbox
                isChecked={selectedCards.some(
                  selectedCard => selectedCard.id === card.id
                )}
                pointerEvents="none"
              />
              <Stack width="full">
                <Text>{card.title}</Text>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text variant="muted">{card.collection.title}</Text>
                  <Badge
                    alignSelf="flex-start"
                    colorScheme={card.step ? card.step.color : 'gray'}
                  >
                    {card.step ? card.step.name : t('common.inbox')}
                  </Badge>
                </Flex>
              </Stack>
            </HStack>
          </CardBody>
        </Card>
      ))}
    </Stack>
  );
};

export default SelectCards;
