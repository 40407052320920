import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Flex,
  HStack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { getCollectingSingular } from 'features/collection/collectionUtils';
import { useCard } from 'providers/CardProvider';
import { useUi } from 'providers/UiProvider';
import { Link, useParams } from 'react-router-dom';

const CarouselHeader = ({ card, withoutCount }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const { carouselCardIds, carouselPosition } = useCard();
  const { filter } = useUi();

  const bgColor = useColorModeValue('white', 'gray.700');
  const progressTrackColor = useColorModeValue('gray.300', 'whiteAlpha.300');
  const progressColor = useColorModeValue('teal.500', 'teal.200');

  return (
    <Flex
      alignItems="center"
      width="full"
      position="absolute"
      top={-14}
      height="40px"
      px={6}
      bg={bgColor}
      borderRadius="lg"
      fontSize="sm"
    >
      {card && (
        <HStack spacing={4}>
          {!withoutCount && (
            <CircularProgress
              trackColor={progressTrackColor}
              color={progressColor}
              size={5}
              thickness={16}
              value={carouselPosition}
              max={carouselCardIds.length}
            />
          )}
          <HStack spacing={1}>
            {withoutCount ? (
              <Trans
                t={t}
                i18nKey="card.carousel_header_without_count"
                components={[
                  <Text />,
                  <Button
                    size="sm"
                    variant="link"
                    as={Link}
                    to={`/${slug}/collection/${card?.collection.id}`}
                  >
                    <Text fontWeight="bold" isTruncated maxW="45ch" />
                  </Button>
                ]}
                values={{
                  type: getCollectingSingular(card.collection.type),
                  collection: filter?.step
                    ? card.step
                      ? card.step.name
                      : t('common.inbox')
                    : card.collection.title
                }}
              />
            ) : (
              <Trans
                t={t}
                i18nKey="card.carousel_header"
                components={[
                  <Text />,
                  <Text fontWeight="bold" isTruncated maxW="45ch" />
                ]}
                values={{
                  position: carouselPosition,
                  total: carouselCardIds.length,
                  type: getCollectingSingular(card.collection.type),
                  collection: filter?.step
                    ? card.step
                      ? card.step.name
                      : t('common.inbox')
                    : card.collection.title
                }}
              />
            )}
          </HStack>
        </HStack>
      )}
    </Flex>
  );
};

export default CarouselHeader;
