import React, { useCallback, useEffect, useState } from 'react';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';

const ConfettiModal = ({ isOpen, onClose, children }) => {
  const [conductor, setConductor] = useState(null);

  const onInit = ({ conductor }) => setConductor(conductor);

  const fire = useCallback(() => {
    if (conductor) {
      conductor.shoot();
    }
  }, [conductor]);

  useEffect(() => {
    if (isOpen) {
      fire();
    }
  }, [fire, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody height="full" minHeight="full" minWidth="20vw">
          <Center pb={12} minHeight="20vh" height="full" width="full">
            {children}
          </Center>
        </ModalBody>
      </ModalContent>
      {isOpen && (
        <Realistic
          onInit={onInit}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            pointerEvents: 'none',
            zIndex: 'var(--chakra-zIndices-modal)'
          }}
        />
      )}
    </Modal>
  );
};

export default ConfettiModal;
