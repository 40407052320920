import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Box, SimpleGrid } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CollectionListItem from './CollectionListItem';

const PublicCollectionList = ({ boardId }) => {
  const { t } = useTranslation();

  const {
    status,
    data: collections,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['public', 'collections', boardId],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20,
        board: boardId
      });
      const { data } = await api.get(
        `/public/collections?${params.toString()}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <Box width="full">
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collections').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <>
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
            >
              <SimpleGrid columns={[1, null, 2]} spacing={6}>
                {collections.pages[0].count > 0 &&
                  collections.pages.map(page =>
                    page.results.map(collection => (
                      <CollectionListItem
                        key={collection.id}
                        collection={collection}
                      />
                    ))
                  )}
              </SimpleGrid>
              {isFetching && <LoadingIndicator />}
            </InfiniteScrollHelper>
          </>
        ) : null}
      </LoadingWrapper>
    </Box>
  );
};

export default PublicCollectionList;
