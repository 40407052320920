import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  AspectRatio,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import AnnouncementForm from './AnnouncementForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import api from 'utils/api';
import ModalWrapper from 'components/ModalWrapper';

const AnnouncementDetailed = ({ announcement, canEdit, isOpen, onClose }) => {
  const { slug } = useParams();

  const {
    isOpen: showDeleteAnnouncementModal,
    onOpen: openDeleteAnnouncementModal,
    onClose: closeDeleteAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showUpdateAnnouncementModal,
    onOpen: openUpdateAnnouncementModal,
    onClose: closeUpdateAnnouncementModal
  } = useDisclosure();

  const toast = useToast();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync: deleteAnnouncementMutation } = useMutation(
    async () => {
      await api.delete(`/announcements/${announcement.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['announcements', slug]);
        queryClient.invalidateQueries(['home', slug]);
        closeDeleteAnnouncementModal();
        onClose();
        toast({
          title: t('toast.announcement_delete_success'),
          status: 'success'
        });
      }
    }
  );

  const updateAnnouncementMutation = useMutation(
    payload => api.patch(`/announcements/${announcement.id}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['announcements', slug]);
        queryClient.invalidateQueries(['home', slug]);
        closeUpdateAnnouncementModal();
        onClose();
        toast({
          title: t('toast.announcement_update_success'),
          status: 'success'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteAnnouncementMutation();
  };

  const handleOnUpdateAnnouncement = async announcement => {
    const payload = new FormData();

    if (!announcement.image || announcement.image instanceof File) {
      payload.append('image', announcement.image ? announcement.image : '');
    }

    if (announcement.video == null) {
      payload.append('video', '');
    }

    payload.append('unsplash', announcement.unsplash || '');
    payload.append('title', announcement.title);
    payload.append('description', announcement.description || '');

    /* TODO: add support for valid video types instead of application/octet-stream fallback */
    try {
      if (announcement.video instanceof File) {
        const { data } = await api.post('/signed-url', {
          filename: announcement.video.name,
          model_type: 'announcement',
          model_id: announcement.id
        });
        await axios.put(data.signed_url, announcement.video, {
          headers: {
            'Content-Type':
              announcement.video.type || 'application/octet-stream'
          }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }

    await updateAnnouncementMutation.mutateAsync(payload);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
      >
        <Stack spacing={4} pb={4}>
          <AspectRatio ratio={16 / 9}>
            <CoverMedia object={announcement} clickToFullscreen />
          </AspectRatio>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading fontSize="lg">{announcement.title}</Heading>
            {canEdit && (
              <ActionMenu>
                <EditMenuItem onClick={openUpdateAnnouncementModal} />
                <DeleteMenuItem onClick={openDeleteAnnouncementModal} />
              </ActionMenu>
            )}
          </Flex>
          <Description>{announcement.description}</Description>
        </Stack>
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.all_information_in_this_card_will_be_permanently_removed_and_cannot_be_restored'
        )}
        isLoading={isLoading}
        isOpen={showDeleteAnnouncementModal}
        onClose={closeDeleteAnnouncementModal}
        onDelete={handleOnClickDelete}
      />
      <ModalWrapper
        title={t('common.announcement')}
        isOpen={showUpdateAnnouncementModal}
        onClose={closeUpdateAnnouncementModal}
      >
        <AnnouncementForm
          defaultValues={{
            ...announcement,
            description: announcement.description
          }}
          onSubmit={handleOnUpdateAnnouncement}
        />
      </ModalWrapper>
    </>
  );
};

export default AnnouncementDetailed;
