import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Show,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-duotone-svg-icons';
import LoginModal from 'components/modals/LoginModal';
import { Link, useParams } from 'react-router-dom';
import { getLanguageCode } from 'i18n';

const LoginHeader = ({ darkLogo, lightLogo, showLoginButton = true }) => {
  const { slug } = useParams();
  const { colorMode, setColorMode } = useColorMode();

  const { t } = useTranslation();

  const logo = useColorModeValue(
    darkLogo ? darkLogo : '/hives_dark.png',
    lightLogo ? lightLogo : '/hives_light.png'
  );

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: toggleShowLoginModal
  } = useDisclosure();

  return (
    <>
      <Flex borderBottomWidth={1} py={4} height="70px" alignItems="center">
        <Container maxW="container.lg">
          <Flex alignItems="center" justifyContent="space-between">
            <Link to={`/${slug}`}>
              <Image src={logo} height={8} />
            </Link>
            <HStack spacing={2}>
              {showLoginButton && (
                <Button
                  size={['sm', null, 'md']}
                  variant="outline"
                  onClick={openLoginModal}
                >
                  <HStack>
                    <Show above="sm">
                      <FontAwesomeIcon icon={faSignIn} />
                    </Show>
                    <Text>{t('common.sign_in')}</Text>
                  </HStack>
                </Button>
              )}
              <Menu>
                <MenuButton
                  as={IconButton}
                  variant="outline"
                  size={['sm', null, 'md']}
                >
                  <FontAwesomeIcon icon={faCog} />
                </MenuButton>
                <MenuList>
                  <Stack spacing={8} px={4} py={2}>
                    <Stack spacing={2}>
                      <Heading fontSize="md">{t('common.appearance')}</Heading>
                      <RadioGroup
                        onChange={value => {
                          setColorMode(value);
                        }}
                        value={colorMode}
                      >
                        <HStack spacing={4}>
                          <Radio value="light" cursor="pointer">
                            {t('common.light')}
                          </Radio>
                          <Radio value="dark" cursor="pointer">
                            {t('common.dark')}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Stack>
                    <Stack spacing={2}>
                      <Heading fontSize="md">{t('common.language')}</Heading>
                      <RadioGroup
                        defaultValue={getLanguageCode()}
                        onChange={value => {
                          i18n.changeLanguage(value);
                        }}
                      >
                        <HStack spacing={4}>
                          <Radio value="en" cursor="pointer">
                            English
                          </Radio>
                          <Radio value="sv" cursor="pointer">
                            Svenska
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Stack>
                  </Stack>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
        </Container>
      </Flex>
      <LoginModal isOpen={showLoginModal} onClose={toggleShowLoginModal} />
    </>
  );
};

export default LoginHeader;
