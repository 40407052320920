import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Stack } from '@chakra-ui/react';
import DescriptionFormControl from 'components/DescriptionFormControl';
import CharacterCounterInput from 'components/CharacterCounterInput';

const HintForm = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <CharacterCounterInput
          useControllerProps={{
            name: 'title',
            control: control,
            rules: {
              required: true,
              maxLength: {
                value: 200,
                message: t('common.character_limit_error', {
                  limit: 200
                })
              }
            }
          }}
        />
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
          onClickMagic={null}
          isLoading={false}
          showMagicButton={false}
        />
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isDirty || !isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default HintForm;
