import Linkify from 'linkify-react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react';
import Answerer from './Answerer';
import { renderLink } from 'utils/link';
import FieldAccessButton from 'components/FieldAccessButton';

const TextAnswer = ({
  answer,
  canEdit,
  field,
  onClickEdit,
  forReport,
  locked
}) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={6}>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        flexWrap={['wrap', 'nowrap', null]}
        spacing={4}
      >
        <Heading fontSize="md" width="full">
          {field.field}
        </Heading>
        {canEdit && !forReport && (
          <Flex width="full" justifyContent="flex-end">
            <HStack spacing={2}>
              <FieldAccessButton field={field} />
              <Tooltip
                isDisabled={!locked}
                label={t('workflow.field_lock_current_step_explainer_simple')}
                hasArrow
                shouldWrapChildren
              >
                <Button
                  isDisabled={locked}
                  size="sm"
                  variant="outline"
                  onClick={onClickEdit}
                >
                  {answer ? t('common.edit') : t('common.answer_verb')}
                </Button>
              </Tooltip>
            </HStack>
          </Flex>
        )}
      </HStack>
      <Box minHeight={1} pb={2}>
        <Text whiteSpace="pre-line">
          <Linkify
            options={{
              render: renderLink,
              format: value => {
                if (value.charAt[0] === ' ') {
                  value = value.slice(1, value.length - 1);
                }
                return value;
              }
            }}
          >
            {answer ? answer.answer : ''}
          </Linkify>
        </Text>
      </Box>
      {answer?.creator && !field.form && (
        <Answerer
          creator={answer.creator}
          answeredAt={answer.created_at}
          forReport={forReport}
        />
      )}
    </Stack>
  );
};

export default TextAnswer;
