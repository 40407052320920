import { createContext, useContext, useCallback, useState } from 'react';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';

const ConfettiContext = createContext();

const ConfettiProvider = ({ children }) => {
  const [conductor, setConductor] = useState(null);

  const onInit = ({ conductor }) => setConductor(conductor);

  const fire = useCallback(() => {
    conductor?.shoot();
  }, [conductor]);

  return (
    <ConfettiContext.Provider value={{ fire }}>
      {children}
      <Realistic onInit={onInit} />
    </ConfettiContext.Provider>
  );
};

const useConfetti = () => useContext(ConfettiContext);

export { ConfettiProvider, useConfetti };
