import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  ButtonGroup,
  Stack,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Text,
  useToast,
  SimpleGrid,
  useDisclosure,
  TableContainer
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import ParameterForm from 'features/chart/ParameterForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const ParameterTable = ({ chartId }) => {
  const [selectedParameter, setSelectedParameter] = useState();

  const {
    isOpen: showDeleteParameterModal,
    onClose: closeDeleteParameterModal
  } = useDisclosure();

  const {
    isOpen: showUpdateParameterModal,
    onOpen: openUpdateParameterModal,
    onClose: closeUpdateParameterModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: parameters,
    isSuccess,
    refetch
  } = useQuery(['parameters', chartId], async () => {
    const { data } = await api.get(`/parameters?chart=${chartId}`);
    return data;
  });

  const deleteParameterMutation = useMutation(
    parameterId => api.delete(`/parameters/${parameterId}`),
    {
      onSuccess: () => {
        closeDeleteParameterModal();
        queryClient.setQueryData(
          ['parameters', chartId],
          parameters.filter(s => s.id !== selectedParameter.id)
        );
        toast({
          title: t('toast.parameter_delete_success'),
          status: 'success'
        });
      }
    }
  );

  const updateParameterMutation = useMutation(
    parameter => api.patch(`/parameters/${selectedParameter.id}`, parameter),
    {
      onSuccess: ({ data: parameter }) => {
        closeUpdateParameterModal();
        toast({
          title: t('toast.parameter_update_success'),
          status: 'success'
        });
        queryClient.setQueryData(
          ['parameters', chartId],
          parameters.map(s => (s.id === parameter.id ? parameter : s))
        );
      },
      onError: () => {
        toast({
          title: t('toast.parameter_update_error'),
          status: 'error'
        });
      }
    }
  );

  const moveDownMutation = useMutation(
    parameterId => api.patch(`/parameters/${parameterId}/down`),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );

  const moveUpMutation = useMutation(
    parameterId => api.patch(`/parameters/${parameterId}/up`),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteParameterMutation.mutateAsync(selectedParameter.id);
  };

  const handleOnClickMoveDown = async parameterId => {
    await moveDownMutation.mutateAsync(parameterId);
  };

  const handleOnClickMoveUp = async parameterId => {
    await moveUpMutation.mutateAsync(parameterId);
  };

  const handleOnUpdateParameter = async parameter => {
    await updateParameterMutation.mutateAsync({
      ...parameter,
      chart: chartId
    });
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.parameters').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={4}>
          <SimpleGrid />
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th pl={0}>{t('common.label')}</Th>
                  <Th textAlign="center">{t('chart.min')}</Th>
                  <Th textAlign="center">{t('chart.max')}</Th>
                  <Th textAlign="left">{t('common.description')}</Th>
                  <Th pr={0} textAlign="center">
                    {t('common.priority')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {parameters.map((parameter, index, { length }) => (
                  <Tr key={parameter.id}>
                    <Td pl={0}>
                      <Button
                        onClick={() => {
                          setSelectedParameter(parameter);
                          openUpdateParameterModal();
                        }}
                        variant="link"
                      >
                        <Text noOfLines={3} maxWidth="150px">
                          {parameter.label}
                        </Text>
                      </Button>
                    </Td>
                    <Td>
                      <Text textAlign="center">{parameter.min}</Text>
                    </Td>
                    <Td>
                      <Text textAlign="center">{parameter.max}</Text>
                    </Td>
                    <Td>
                      <Text
                        whiteSpace="break-word"
                        textAlign="left"
                        noOfLines={8}
                      >
                        {parameter.description}
                      </Text>
                    </Td>
                    <Td pr={0} textAlign="center">
                      <ButtonGroup>
                        <Button
                          variant="outline"
                          isDisabled={index === 0}
                          onClick={() => handleOnClickMoveUp(parameter.id)}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </Button>
                        <Button
                          variant="outline"
                          isDisabled={index === length - 1}
                          onClick={() => handleOnClickMoveDown(parameter.id)}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <ModalWrapper
            title={t('chart.parameter')}
            isOpen={showUpdateParameterModal}
            onClose={closeUpdateParameterModal}
          >
            <ParameterForm
              chartId={chartId}
              defaultValues={{
                label: selectedParameter?.label,
                min: selectedParameter?.min,
                max: selectedParameter?.max,
                description: selectedParameter?.description
              }}
              isOpen={showUpdateParameterModal}
              onSubmit={handleOnUpdateParameter}
            />
          </ModalWrapper>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
            )}
            isOpen={showDeleteParameterModal}
            onClose={closeDeleteParameterModal}
            onDelete={handleOnClickDelete}
          />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default ParameterTable;
