import i18n from 'i18n';
import theme from 'theme';
import { I18nextProvider } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './AuthProvider';
import { ConfettiProvider } from './ConfettiProvider';
import { UiProvider } from './UiProvider';
import { AttachmentProvider } from './AttachmentProvider';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    scopes: ['User.Read'],
    redirectUri: `${process.env.REACT_APP_URL}/spa/auth/microsoft`
  }
  // cache: {
  //   cacheLocation: 'sessionStorage',
  //   storeAuthStateInCookie: true
  // }
});

const AppProviders = ({ children }) => (
  <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: 'bottom-right',
          isClosable: true
        }
      }}
    >
      <UiProvider>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={client}>
            <MsalProvider instance={pca}>
              <AuthProvider>
                <DndProvider backend={HTML5Backend}>
                  <AttachmentProvider>
                    <ConfettiProvider>{children}</ConfettiProvider>
                  </AttachmentProvider>
                </DndProvider>
              </AuthProvider>
            </MsalProvider>
          </QueryClientProvider>
        </I18nextProvider>
      </UiProvider>
    </ChakraProvider>
  </IntercomProvider>
);

export default AppProviders;
