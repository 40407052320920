import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import LoginModal from 'components/modals/LoginModal';
import api from 'utils/api';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import { useCard } from 'providers/CardProvider';

const CommentSection = ({
  cardId,
  commentCount,
  updateCard,
  code,
  isAdminOrAssigneeOrCollectionManager
}) => {
  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const [selectedComment, setSelectedComment] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const { setShouldRefetchCard, setShouldRefetchCardList } = useCard();
  const [commentTranslations, setCommentTranslations] = useState([]);

  const handleOnSetTranslationShown = (commentId, isShown) => {
    setCommentTranslations(prev => ({
      ...prev,
      [commentId]: {
        ...prev[commentId],
        shown: isShown
      }
    }));
  };

  const handleOnSetTranslationText = (commentId, translatedText) => {
    setCommentTranslations(prev => ({
      ...prev,
      [commentId]: {
        text: translatedText,
        shown: prev[commentId]?.shown ?? false
      }
    }));
  };

  const modalBackgroundColor = useColorModeValue('white', 'gray.700');
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: showCommentModal,
    onOpen: openCommentModal,
    onClose: closeCommentModal
  } = useDisclosure();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const { data: me } = useQuery('me', { enabled: false });

  const queryClient = useQueryClient();

  const isReply = selectedComment?.parent ? true : false;

  const createCommentMutation = useMutation(
    newComment => api.post('/comments', newComment),
    {
      onSuccess: ({ data: newComment }) => {
        if (isReply) {
          queryClient.invalidateQueries(['replies', newComment.parent]);
        }
        const card = queryClient.getQueryData(['card', cardId]);
        if (card?.metrics?.comments) {
          updateCard({
            ...card,
            metrics: {
              ...card.metrics,
              comments: card.metrics.comments + 1
            }
          });
        } else {
          queryClient.invalidateQueries(['card', cardId]);
          setShouldRefetchCard(true);
          setShouldRefetchCardList(true);
        }
        queryClient.invalidateQueries(['comments', cardId]);
        setShouldRefetch(true);
      }
    }
  );

  const deleteCommentMutation = useMutation(
    () => api.delete(`/comments/${selectedComment.id}`),
    {
      onSuccess: () => {
        if (isReply) {
          queryClient.invalidateQueries(['replies', selectedComment.id]);
        }
        const card = queryClient.getQueryData(['card', cardId]);
        if (card?.metrics?.comments) {
          updateCard({
            ...card,
            metrics: {
              ...card.metrics,
              comments: card.metrics.comments + 1
            }
          });
        } else {
          queryClient.invalidateQueries(['card', cardId]);
          setShouldRefetchCard(true);
          setShouldRefetchCardList(true);
        }
        queryClient.invalidateQueries(['comments', cardId]);
        setShouldRefetch(true);
      }
    }
  );

  const updateCommentMutation = useMutation(
    comment => api.patch(`/comments/${selectedComment.id}`, comment),
    {
      onSuccess: ({ data: updatedComment }) => {
        if (isReply) {
          queryClient.invalidateQueries(['replies', updatedComment.parent]);
        }
        queryClient.invalidateQueries(['comments', cardId]);
        setShouldRefetch(true);
      }
    }
  );

  const handleOnCreateComment = async (comment, _) => {
    if (me) {
      try {
        await createCommentMutation.mutateAsync({
          read_access: comment.read_access.id,
          comment: comment.comment,
          card: cardId
        });
        toast({
          title: t('toast.comment_add_success'),
          status: 'success'
        });
      } catch (e) {
        console.log(e);
        toast({
          title: t('toast.comment_add_error'),
          status: 'error'
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnCreateReply = async (comment, closeModal) => {
    if (me && selectedComment) {
      try {
        await createCommentMutation.mutateAsync({
          comment: comment.comment,
          card: cardId,
          parent: selectedComment.id
        });
        closeModal();
        toast({
          title: t('toast.reply_add_success'),
          status: 'success'
        });
      } catch {
        toast({
          title: t('toast.reply_add_error'),
          status: 'error'
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnDeleteComment = async (comment, closeModal) => {
    setSelectedComment(comment);
    try {
      await deleteCommentMutation.mutateAsync();
      closeModal();
      toast({
        title: t('toast.comment_delete_success'),
        status: 'success'
      });
    } catch {
      toast({
        title: t('toast.comment_delete_error'),
        status: 'error'
      });
    }
  };

  const handleOnUpdateComment = async (comment, closeModal) => {
    try {
      await updateCommentMutation.mutateAsync({
        comment: comment.comment
      });
      closeModal();
      toast({
        title: t('toast.comment_update_success'),
        status: 'success'
      });
    } catch {
      toast({
        title: t('toast.comment_update_error'),
        status: 'error'
      });
    }
  };

  const initialCommentLimit = isMobile ? 3 : null;

  return (
    <Stack spacing={8} pb={8}>
      <CommentForm
        onSubmit={handleOnCreateComment}
        onFocusCallback={event => {
          if (!me && code) {
            openLoginModal();
            event.target.blur();
          }
        }}
      />
      <CommentList
        limit={initialCommentLimit}
        cardId={cardId}
        code={code}
        onCreateReply={handleOnCreateReply}
        onDeleteComment={handleOnDeleteComment}
        onUpdateComment={handleOnUpdateComment}
        handleOnSetSelectedComment={comment => setSelectedComment(comment)}
        selectedComment={selectedComment}
        isAdminOrAssigneeOrCollectionManager={
          isAdminOrAssigneeOrCollectionManager
        }
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
        updateCard={updateCard}
        openLoginModal={openLoginModal}
        triggerLoginModal={!me && code}
        handleOnSetTranslationShown={handleOnSetTranslationShown}
        handleOnSetTranslationText={handleOnSetTranslationText}
        commentTranslations={commentTranslations}
      />
      {initialCommentLimit && commentCount > initialCommentLimit && (
        <Button variant="link" onClick={openCommentModal}>
          {t('button.view_all_nr_comments', { nr: commentCount })}
        </Button>
      )}
      <Modal
        size="full"
        onClose={closeCommentModal}
        isOpen={showCommentModal}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Stack
            position="sticky"
            py={2}
            top={0}
            bg={modalBackgroundColor}
            zIndex={9999}
          >
            <ModalCloseButton />
            <ModalHeader>
              {`${commentCount} ${t('common.comments')}`}
            </ModalHeader>
          </Stack>
          <ModalBody py={8}>
            <CommentList
              cardId={cardId}
              code={code}
              onCreateReply={handleOnCreateReply}
              onDeleteComment={handleOnDeleteComment}
              onUpdateComment={handleOnUpdateComment}
              handleOnSetSelectedComment={comment =>
                setSelectedComment(comment)
              }
              selectedComment={selectedComment}
              isAdminOrAssigneeOrCollectionManager={
                isAdminOrAssigneeOrCollectionManager
              }
              handleOnSetTranslationShown={handleOnSetTranslationShown}
              handleOnSetTranslationText={handleOnSetTranslationText}
              commentTranslations={commentTranslations}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
    </Stack>
  );
};

export default CommentSection;
