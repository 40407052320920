import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HStack, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import BoxContainer from 'components/BoxContainer';
import HintForm from './HintForm';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';
import IconTooltip from 'components/tooltips/IconTooltip';

const AdminHints = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: hints,
    isSuccess
  } = useQuery('hints', async () => {
    const { data } = await api.get(`/hints?hive__slug=${slug}`);
    return data;
  });

  const loginHint = hints?.find(h => h.type === 'LOGIN');
  const groupHint = hints?.find(h => h.type === 'GROUP');

  const createHintMutation = useMutation(
    payload => api.post('/hints', payload),
    {
      onSuccess: ({ data: hint }) => {
        queryClient.setQueryData('hints', [...hints, hint]);
      }
    }
  );

  const deleteHintMutation = useMutation(
    hintId => api.delete(`/hints/${hintId}`),
    {
      onSuccess: (_, hintId) => {
        queryClient.setQueryData(
          'hints',
          hints.filter(h => h.id !== hintId)
        );
      }
    }
  );

  const updateHintMutation = useMutation(
    ([hintId, payload]) => api.patch(`/hints/${hintId}`, payload),
    {
      onSuccess: ({ data: hint }) => {
        queryClient.setQueryData(
          'hints',
          hints.map(h => (h.id === hint.id ? hint : h))
        );
      }
    }
  );

  const handleOnSubmitHint = async (hint, type) => {
    const description = hint.description;
    if (hint.id) {
      if (hint.title.length === 0 && description.length === 0) {
        await deleteHintMutation.mutateAsync(hint.id);
      } else {
        await updateHintMutation.mutateAsync([
          hint.id,
          {
            title: hint.title,
            description: description,
            type
          }
        ]);
      }
    } else {
      await createHintMutation.mutateAsync({
        hive: hive.id,
        title: hint.title,
        description: description,
        type
      });
    }
  };

  return (
    <LoadingWrapper statuses={[status]}>
      {isSuccess && (
        <SimpleGrid columns={2} spacing={8}>
          <BoxContainer alignSelf="flex-start">
            <Stack spacing={4}>
              <HStack alignItems="center">
                <Heading fontSize="lg">{t('admin.sign_in_guide')}</Heading>
                <IconTooltip
                  label={t('admin.sign_in_guide_tooltip')}
                  alignSelf="center"
                  color="gray"
                />
              </HStack>
              <HintForm
                defaultValues={
                  loginHint
                    ? {
                        id: loginHint.id,
                        title: loginHint.title,
                        description: loginHint.description
                      }
                    : null
                }
                onSubmit={async values => handleOnSubmitHint(values, 'LOGIN')}
              />
            </Stack>
          </BoxContainer>
          <BoxContainer alignSelf="flex-start">
            <Stack spacing={4}>
              <HStack alignItems="center">
                <Heading fontSize="lg">
                  {t('admin.group_selection_guide')}
                </Heading>
                <IconTooltip
                  label={t('admin.group_selection_guide_tooltip')}
                  alignSelf="center"
                  color="gray"
                />
              </HStack>
              <HintForm
                defaultValues={
                  groupHint
                    ? {
                        id: groupHint.id,
                        title: groupHint.title,
                        description: groupHint.description
                      }
                    : null
                }
                onSubmit={async values => handleOnSubmitHint(values, 'GROUP')}
              />
            </Stack>
          </BoxContainer>
        </SimpleGrid>
      )}
    </LoadingWrapper>
  );
};

export default AdminHints;
