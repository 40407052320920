import Masonry from 'react-masonry-css';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import { usePublicCard } from 'providers/PublicCardProvider';
import api from 'utils/api';
import CardListItem from './CardListItem';
import { appendCardFilterToURLParams, cardFilterToArray } from './cardUtils';

const PublicCardList = ({
  filter,
  breakpointCols = {
    default: 3,
    992: 2,
    768: 1
  }
}) => {
  const { openCard } = usePublicCard();
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status: loadingStatus,
    data: cards,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage
  } = useInfiniteQuery(
    ['public', 'cards', { hive: slug }, ...cardFilterToArray(filter)],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 10
      });
      params.append('hive', slug);
      appendCardFilterToURLParams(filter, params);
      const { data } = await api.get(`/public/cards?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <LoadingWrapper
      statuses={[loadingStatus]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.cards').toLowerCase()
        })
      ]}
    >
      {isSuccess && cards.pages[0].count > 0 && (
        <Stack>
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <Masonry
              breakpointCols={breakpointCols}
              className="masonry-grid"
              columnClassName="masonry-column"
            >
              {cards.pages.map(page =>
                page.results.map(card => (
                  <CardListItem
                    key={card.id}
                    card={card}
                    onClick={() => {
                      openCard(card.id);
                    }}
                  />
                ))
              )}
            </Masonry>
          </InfiniteScrollHelper>
          {isFetching && <LoadingIndicator />}
        </Stack>
      )}
    </LoadingWrapper>
  );
};

export default PublicCardList;
