import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Container, Divider, Stack } from '@chakra-ui/react';
import BackButton from 'components/BackButton';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import TitleDescription from 'components/TitleDescription';
import api from 'utils/api';
import PublicCollectionList from 'features/collection/PublicCollectionList';

const PublicBoardDetailed = () => {
  const { boardId } = useParams();
  const { t } = useTranslation();

  const {
    status,
    data: board,
    isSuccess
  } = useQuery(['public', 'board', boardId], async () => {
    const { data } = await api.get(`/public/boards/${boardId}`);
    return data;
  });

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.board').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <BackButton />
            <CoverMedia object={board} height="280px" clickToFullscreen />
            <TitleDescription
              title={board.title}
              description={board.description}
              button={null}
            />
            <Divider />
            <PublicCollectionList boardId={boardId} />
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default PublicBoardDetailed;
