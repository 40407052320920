import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';

const SingleSelectForm = ({ answer, options, helpText, onSubmit }) => {
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [setModalClosureLocked, isDirty]);

  const firstSelected = answer?.options.find(option => option.selected)?.id;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl>
          <Stack spacing={4}>
            <Controller
              name="option"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  defaultValue={firstSelected}
                  value={value}
                  onChange={onChange}
                >
                  <Stack spacing={4}>
                    {options.map(option => (
                      <Box
                        key={option.id}
                        borderWidth={1}
                        borderRadius="lg"
                        cursor="pointer"
                        padding={4}
                        onClick={() => onChange(option.id)}
                      >
                        <HStack spacing={4}>
                          <Radio value={option.id} pointerEvents="none" />
                          <Text>{option.option}</Text>
                        </HStack>
                      </Box>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
            <FormControl>
              {helpText && <FormHelperText>{helpText}</FormHelperText>}
            </FormControl>
          </Stack>
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default SingleSelectForm;
