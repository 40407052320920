import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalWrapper from './ModalWrapper';
import DescriptionFormControl from './DescriptionFormControl';
import { useUi } from 'providers/UiProvider';

const ThankYouFormModal = ({
  defaultValues,
  onSubmit,
  onDelete,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, isDirty }
  } = useForm({
    defaultValues: {
      description: defaultValues.thank_you_message
    }
  });

  const message = watch('description');

  useEffect(() => {
    if (!isOpen) {
      reset({
        description: defaultValues.thank_you_message
      });
    }
  }, [isOpen, defaultValues, reset]);

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [isDirty, setModalClosureLocked]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title={t('collection.set_thank_you_message')}
      confirmClosure
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Text>{t('collection.thank_you_message_explainer')}</Text>
          <DescriptionFormControl
            label={t('common.message')}
            control={control}
            setValue={setValue}
            defaultValues={defaultValues}
          />
          <HStack spacing={4} justifyContent="space-between">
            <Button onClick={onDelete} colorScheme="red">
              {t('common.delete')}
            </Button>
            <Button
              isDisabled={!isDirty || message?.length < 1}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              {t('button.save')}
            </Button>
          </HStack>
        </Stack>
      </form>
    </ModalWrapper>
  );
};

export default ThankYouFormModal;
