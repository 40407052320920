import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Switch
} from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';

const StepForm = ({ defaultValues, isOpen, onSubmit }) => {
  const colors = [
    'red',
    'orange',
    'yellow',
    'cyan',
    'blue',
    'purple',
    'pink',
    'green',
    'gray'
  ];

  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const stepNameMaxLength = 30;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <CharacterCounterInput
            isTextArea
            useControllerProps={{
              name: 'name',
              control: control,
              rules: {
                required: true,
                maxLength: {
                  value: stepNameMaxLength,
                  message: t('common.character_limit_error', {
                    limit: stepNameMaxLength
                  })
                }
              }
            }}
            defaultValue={defaultValues?.name}
          />
        </FormControl>
        <FormControl id="description">
          <FormLabel>{t('common.description')}</FormLabel>
          <CharacterCounterInput
            isTextArea
            useControllerProps={{
              name: 'description',
              control: control,
              rules: {
                maxLength: {
                  value: 200,
                  message: t('common.character_limit_error', {
                    limit: 200
                  })
                }
              }
            }}
            defaultValue={defaultValues?.description}
          />
        </FormControl>
        <FormControl id="color">
          <FormLabel>{t('common.color')}</FormLabel>
          <Controller
            name="color"
            defaultValue={defaultValues?.color}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <SimpleGrid columns={[2, 3]} spacing={3}>
                  {colors.map(color => (
                    <Radio key={color} value={color}>
                      <Badge colorScheme={color}>{color}</Badge>
                    </Radio>
                  ))}
                </SimpleGrid>
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="hide_cards" mb="0">
            {t('step.hide_cards')}
          </FormLabel>
          <Switch
            id="hide_cards"
            {...register('hide_cards')}
            defaultChecked={defaultValues?.hide_cards}
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="use_reactions" mb="0">
            {t('step.use_reactions')}
          </FormLabel>
          <Switch
            id="use_reactions"
            {...register('use_reactions')}
            defaultChecked={defaultValues?.use_reactions}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default StepForm;
