import {
  Center,
  useColorMode,
  useMediaQuery,
  useTheme
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import ScoreTooltip from './tooltips/ScoreTooltip';
import { useCard } from 'providers/CardProvider';
import { useTranslation } from 'react-i18next';

const RechartsScatterChart = ({
  chart,
  data,
  tooltip = <ScoreTooltip chartType="SCA" />,
  onClick,
  height = '100%'
}) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const [chartKey, setChartKey] = useState(Math.random());
  const [isSlimMobile] = useMediaQuery('(max-width: 48em)');
  const { t } = useTranslation();

  const { showCardModal, openCard } = useCard();

  const minSize = 55;
  const maxSize = 1500;
  const calculatedSize = (1 - Math.pow(data?.length / 100, 2)) * maxSize;
  const bubbleSize =
    chart && chart.parameters.length !== 2
      ? null
      : Math.max(minSize, calculatedSize);

  useEffect(() => {
    setChartKey(Math.random());
  }, [showCardModal]);

  const getAdjustedFontSize = length => {
    return 18 - length / 14;
  };

  return (
    <Center>
      <ResponsiveContainer height={height} width="65%" aspect={1}>
        <ScatterChart
          margin={{
            top: 20,
            right: 30,
            bottom: 40,
            left: isSlimMobile ? -5 : 20
          }}
          key={chartKey}
        >
          <XAxis
            name={chart ? chart.parameters[0].label : ''}
            domain={
              chart ? [chart.parameters[0].min, chart.parameters[0].max] : null
            }
            type="number"
            dataKey={chart ? chart.parameters[0].label : 'views'}
            tick={true}
            allowDecimals={false}
            stroke={
              colorMode === 'light'
                ? theme.colors.gray['400']
                : theme.colors.gray['500']
            }
            label={
              isSlimMobile ? null : (
                <Label
                  value={chart ? chart.parameters[0].label : t('common.views')}
                  position="bottom"
                  offset={10}
                  style={{
                    fill: `${
                      colorMode === 'light'
                        ? theme.colors.gray['800']
                        : theme.colors.whiteAlpha['900']
                    }`,
                    stroke: 'none',
                    fontSize: chart
                      ? getAdjustedFontSize(chart.parameters[0]?.label.length)
                      : null
                  }}
                />
              )
            }
          />
          <YAxis
            name={chart ? chart.parameters[1].label : ''}
            domain={
              chart ? [chart.parameters[1].min, chart.parameters[1].max] : null
            }
            type="number"
            dataKey={chart ? chart.parameters[1].label : 'reactions'}
            tick={true}
            allowDecimals={false}
            stroke={
              colorMode === 'light'
                ? theme.colors.gray['400']
                : theme.colors.gray['500']
            }
            label={
              isSlimMobile ? null : (
                <Label
                  value={
                    chart ? chart.parameters[1].label : t('common.reactions')
                  }
                  angle={-90}
                  position="insideLeftCenter"
                  dx={-15}
                  offset={5}
                  style={{
                    fill: `${
                      colorMode === 'light'
                        ? theme.colors.gray['800']
                        : theme.colors.whiteAlpha['900']
                    }`,
                    stroke: 'none',
                    fontSize: chart
                      ? getAdjustedFontSize(chart.parameters[0]?.label.length)
                      : null
                  }}
                />
              )
            }
          />
          <ZAxis
            name={chart ? chart.parameters[2]?.label : ''}
            domain={
              chart && [chart.parameters[2]?.min, chart.parameters[2]?.max]
            }
            range={bubbleSize ? [bubbleSize, bubbleSize] : [100, 1000]}
            type="number"
            dataKey={chart ? chart.parameters[2]?.label : 'size'}
          />
          <Tooltip
            wrapperStyle={{ maxWidth: 200 }}
            cursor={{
              strokeDasharray: '3 3',
              stroke:
                colorMode === 'light'
                  ? theme.colors.gray['400']
                  : theme.colors.gray['500']
            }}
            content={tooltip}
          />
          <Scatter
            name={!!!chart ? 'Popularity' : ''}
            onClick={
              onClick
                ? ({ id }) => onClick(id)
                : ({ id }) => {
                    if (!showCardModal) {
                      openCard(id);
                    }
                  }
            }
            cursor="pointer"
            data={data}
            fill={theme.colors.chart.fill}
            stroke={theme.colors.chart.stroke}
          />
          {!chart && (
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                colorMode === 'light'
                  ? theme.colors.gray['400']
                  : theme.colors.gray['500']
              }
              opacity={0.5}
              horizontalCoordinatesGenerator={props => {
                const val = [(props.width - 50) / 2];
                return val;
              }}
              verticalCoordinatesGenerator={props => {
                const val = [Math.round(props.height / 1.85)];
                return val;
              }}
            />
          )}
          <ReferenceLine
            y={
              chart
                ? chart.parameters[1].max / 2 + chart.parameters[1].min / 2
                : '50%' //midPoint && midPoint.y
            }
            stroke={
              colorMode === 'light'
                ? theme.colors.gray['400']
                : theme.colors.gray['500']
            }
            strokeDasharray="3 3"
            opacity={0.5}
          />
          <ReferenceLine
            x={
              chart
                ? chart.parameters[0].max / 2 + chart.parameters[0].min / 2
                : '50%' //midPoint && midPoint.x
            }
            stroke={
              colorMode === 'light'
                ? theme.colors.gray['400']
                : theme.colors.gray['500']
            }
            strokeDasharray="3 3"
            opacity={0.5}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Center>
  );
};

export default RechartsScatterChart;
