import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, FormControl, Stack, Text } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useUi } from 'providers/UiProvider';

const PublishCollectionModal = ({ board, isOpen, onClose, onSubmit }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const { setModalClosureLocked } = useUi();

  const { t } = useTranslation();

  const { status, data: boards } = useQuery('boards', async () => {
    const { data } = await api.get(`/boards?hive=${hive.id}`);
    return data;
  });

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, isSubmitSuccessful }
  } = useForm({
    mode: 'onChange',
    defaultValues: { board }
  });

  const value = watch('board');

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose();
      reset();
    }
  }, [onClose, reset, isSubmitSuccessful]);

  useEffect(() => {
    if (isOpen) {
      reset({ board });
    }
  }, [isOpen, reset, board]);

  useEffect(() => {
    setModalClosureLocked(value?.id !== board?.id);
  }, [board?.id, setModalClosureLocked, value?.id]);

  return (
    <ModalWrapper
      title={t('common.publish_collection')}
      isOpen={isOpen}
      onClose={onClose}
      pb={8}
      confirmClosure
    >
      <form id="board-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8}>
          <Stack spacing={4}>
            <Text>{t('collection.publish_explainer')}</Text>
            <FormControl>
              <Controller
                control={control}
                name="board"
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={board}
                    options={status === 'success' ? boards : []}
                    getOptionLabel={board => board.title}
                    getOptionValue={board => board.id}
                    isClearable
                    useBasicStyles
                    onChange={value => field.onChange(value)}
                  />
                )}
              />
            </FormControl>
          </Stack>
          <Button
            isDisabled={value?.id === board?.id}
            isLoading={isSubmitting}
            colorScheme="teal"
            type="submit"
            form="board-form"
            width="fit-content"
            alignSelf="flex-end"
          >
            {t('button.save')}
          </Button>
        </Stack>
      </form>
    </ModalWrapper>
  );
};

export default PublishCollectionModal;
