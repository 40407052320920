import { createContext, useContext, useRef, useState } from 'react';
import { Input, Progress, useDisclosure, useToast } from '@chakra-ui/react';
import UnsplashModal from 'components/modals/UnsplashModal';
import ModalWrapper from 'components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import AttachmentForm from 'features/attachment/AttachmentForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useQueryClient } from 'react-query';
import { sleepMs } from 'utils/network';
import api from 'utils/api';
import axios from 'axios';

const AttachmentContext = createContext();

const AttachmentProvider = ({ children }) => {
  const imageInputRef = useRef();
  const videoInputRef = useRef();
  const fileInputRef = useRef();
  const [attachments, setAttachments] = useState([]);
  const [thumbnailIndex, setThumbnailIndex] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    isOpen: showUnsplashModal,
    onOpen: openUnsplashModal,
    onClose: closeUnsplashModal
  } = useDisclosure();

  const {
    isOpen: showUpdateAttachmentModal,
    onOpen: openUpdateAttachmentModal,
    onClose: closeUpdateAttachmentModal
  } = useDisclosure();

  const {
    isOpen: showDeleteAttachmentModal,
    onOpen: openDeleteAttachmentModal,
    onClose: closeDeleteAttachmentModal
  } = useDisclosure();

  const openAttachmentPicker = (type = null) => {
    switch (type) {
      case 'image/*':
        imageInputRef.current.click();
        break;
      case 'video/*':
        videoInputRef.current.click();
        break;
      case 'unsplash':
        openUnsplashModal();
        break;
      default:
        fileInputRef.current.click();
    }
  };

  const addUnsplash = url => {
    const fileName = 'image.jpg';
    let file = new File([], fileName, {});
    fetch(url).then(async response => {
      const blob = await response.blob();
      file = new File([blob], fileName, { type: 'image/*' });
      setAttachments([...attachments, file]);
    });
    setThumbnailIndex(attachments.length);
  };

  const addFiles = (e, type) => {
    let files = e.target.files;
    if (type === 'image/*' || type === 'video/*') {
      setThumbnailIndex(0);
    }
    addAttachments(files);
  };

  const clearInputs = () => {
    imageInputRef.current.value = '';
    videoInputRef.current.value = '';
    fileInputRef.current.value = '';
  };

  const addAttachments = async files => {
    let newAttachments = [...files];
    setAttachments([...attachments, ...newAttachments]);
    clearInputs();
  };

  //move to attachmentUtils
  const handleOnUploadAttachment = async (toUpload, modelConfig) => {
    if (toUpload.size > 1000000000) {
      toast({
        title: t('toast.file_too_large'),
        status: 'error'
      });
    } else {
      setProgress(0);
      setUploading(true);
      /*
      Perhaps a bit subjective but I feel like the user experience is a bit improved
      by adding a little bit of sleep before and after upload.
    */
      await sleepMs(250);
      try {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: toUpload.name
        });
        await axios.put(data.url, toUpload, {
          headers: { 'x-amz-acl': 'public-read' },
          onUploadProgress: ({ loaded, total }) => {
            const percentage = Math.floor((loaded * 100) / total);
            setProgress(percentage);
          }
        });
        const { data: attachment } = await api.post(
          `/${modelConfig.plural}/attachments`,
          {
            key: data.key,
            name: toUpload.name,
            [modelConfig.singular]: modelConfig.modelId
          }
        );
        const attachments = queryClient.getQueryData([
          'attachments',
          modelConfig.plural,
          modelConfig.modelId
        ]);
        if (attachments) {
          queryClient.setQueryData(
            ['attachments', modelConfig.plural, modelConfig.modelId],
            [...attachments, attachment]
          );
        }
      } catch (e) {
        toast({
          title: t('toast.attachment_upload_error'),
          status: 'error'
        });
        console.error(e);
      }
      await sleepMs(250);
      setUploading(false);
    }
  };

  const uploadAttachments = async modelConfig => {
    attachments.forEach(attachment =>
      handleOnUploadAttachment(attachment, modelConfig)
    );
  };

  const removeAttachment = () => {
    const remainingAttachments = attachments.filter(
      attachment =>
        attachments.indexOf(attachment) !==
        attachments.indexOf(selectedAttachment)
    );
    clearInputs();
    setAttachments([...remainingAttachments]);
  };

  const updateAttachment = updatedAttachment => {
    const updatedAttachments = attachments.map(attachment => {
      if (
        attachments.indexOf(attachment) ===
        attachments.indexOf(selectedAttachment)
      ) {
        return updatedAttachment;
      }
      return attachment;
    });
    setAttachments([...updatedAttachments]);
  };

  const onClickDeleteAttachment = attachment => {
    setSelectedAttachment(attachment);
    openDeleteAttachmentModal();
  };

  const onClickUpdateAttachment = attachment => {
    setSelectedAttachment(attachment);
    openUpdateAttachmentModal();
  };

  const onClickSetAsThumbnail = index => {
    setThumbnailIndex(index);
  };

  return (
    <AttachmentContext.Provider
      value={{
        attachments,
        thumbnailIndex,
        onClickDeleteAttachment,
        onClickUpdateAttachment,
        onClickSetAsThumbnail,
        openAttachmentPicker,
        uploadAttachments
      }}
    >
      {children}
      <Input
        ref={imageInputRef}
        accept="image/*"
        display="none"
        type="file"
        multiple
        onChange={event => addFiles(event, 'image/*')}
      />
      <Input
        ref={videoInputRef}
        accept="video/*"
        display="none"
        type="file"
        multiple
        onChange={event => addFiles(event, 'video/*')}
      />
      <UnsplashModal
        isOpen={showUnsplashModal}
        onClose={closeUnsplashModal}
        onImage={addUnsplash}
      />
      <Input
        ref={fileInputRef}
        display="none"
        type="file"
        multiple
        onChange={event => addFiles(event, null)}
      />
      <ModalWrapper
        title={t('common.attachment')}
        isOpen={showUpdateAttachmentModal}
        onClose={closeUpdateAttachmentModal}
        returnFocusOnClose={true}
      >
        <AttachmentForm
          defaultValues={selectedAttachment}
          isOpen={showUpdateAttachmentModal}
          onSubmit={attachment => {
            updateAttachment(attachment);
            closeUpdateAttachmentModal();
          }}
        />
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
          { data: t('common.attachment').toLowerCase() }
        )}
        isOpen={showDeleteAttachmentModal}
        onClose={closeDeleteAttachmentModal}
        onDelete={() => {
          removeAttachment();
          closeDeleteAttachmentModal();
        }}
      />
      <ModalWrapper
        title={t('common.uploading_attachment')}
        isOpen={uploading}
        pb={6}
      >
        <Progress colorScheme="teal" hasStripe value={progress} rounded="lg" />
      </ModalWrapper>
    </AttachmentContext.Provider>
  );
};

const useAttachment = () => useContext(AttachmentContext);

export { AttachmentProvider, useAttachment };
