import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import BoardListItem from './BoardListItem';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';

const PublicBoardList = () => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status,
    data: boards,
    isSuccess
  } = useQuery(['public', 'boards', slug], async () => {
    const { data } = await api.get(`/public/boards?hive=${slug}`);
    return data;
  });

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.boards').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={16}>
            {boards.map(board => (
              <BoardListItem
                key={board.id}
                board={board}
                hasCreateCollectionsPermission={false}
                onClickCreate={null}
              />
            ))}
          </Stack>
        ) : null}
      </LoadingWrapper>
    </>
  );
};

export default PublicBoardList;
