import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  Container,
  SimpleGrid,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import TitleDescription from 'components/TitleDescription';
import AnnouncementDetailed from 'features/announcement/AnnouncementDetailed';
import AnnouncementListItem from 'features/announcement/AnnouncementListItem';
import PublicBoardList from 'features/board/PublicBoardList';
import { useEffect } from 'react';

const PublicHivePage = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const {
    isOpen: showAnnouncementModal,
    onOpen: openAnnouncementModal,
    onClose: closeAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showPopupAnnouncementDetailedModal,
    onOpen: openPopupAnnouncementDetailedModal,
    onClose: closePopupAnnouncementDetailedModal
  } = useDisclosure();

  const { t } = useTranslation();

  useEffect(() => {
    if (hive?.popup_announcement) {
      const lastSeenPopupAnnouncement = parseInt(
        localStorage.getItem('lastSeenPopupAnnouncement')
      );
      const alreadySeenLatest =
        lastSeenPopupAnnouncement === hive?.popup_announcement?.id;
      if (!alreadySeenLatest) {
        const timer = setTimeout(() => {
          openPopupAnnouncementDetailedModal();
        }, 1500);
        return () => clearTimeout(timer);
      }
    }
  }, [hive?.popup_announcement, openPopupAnnouncementDetailedModal]);

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={16}>
        <Stack spacing={8}>
          <SimpleGrid columns={[1, null, 2]} spacing={8}>
            <AspectRatio ratio={16 / 9}>
              <CoverMedia object={hive} clickToFullscreen />
            </AspectRatio>
            <TitleDescription
              title={hive?.title}
              description={hive?.description}
              noOfCharacters={650}
            />
          </SimpleGrid>
          {hive?.latest_announcement && (
            <>
              <Stack spacing={4}>
                <AnnouncementListItem
                  announcement={hive.latest_announcement}
                  onClickReadMore={openAnnouncementModal}
                />
                <Button
                  alignSelf="flex-start"
                  as={Link}
                  variant="link"
                  to={`/${slug}/announcements`}
                >
                  {t('button.see_all_announcements')}
                </Button>
              </Stack>
              <AnnouncementDetailed
                announcement={hive.latest_announcement}
                isOpen={showAnnouncementModal}
                onClose={closeAnnouncementModal}
                canEdit={false}
              />
            </>
          )}
          {hive?.popup_announcement && (
            <AnnouncementDetailed
              announcement={hive?.popup_announcement}
              isOpen={showPopupAnnouncementDetailedModal}
              onClose={() => {
                localStorage.setItem(
                  'lastSeenPopupAnnouncement',
                  hive.popup_announcement?.id
                );
                closePopupAnnouncementDetailedModal();
              }}
              canEdit={false}
            />
          )}
        </Stack>
        {hive.metrics.boards > 0 && (
          <Stack spacing={8}>
            <PublicBoardList />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default PublicHivePage;
