import {
  Badge,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  HStack,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons';

const ReportListItem = ({ report, onClick }) => {
  return (
    <ChakraCard
      variant="outline"
      cursor="pointer"
      overflow="hidden"
      onClick={onClick}
    >
      <ChakraCardBody>
        <HStack spacing={4}>
          <Badge
            display="flex"
            alignItems="center"
            justifyContent="center"
            minW={10}
            minH={10}
            rounded="md"
            colorScheme="blue"
            cursor="pointer"
          >
            <Text fontSize="lg">
              <FontAwesomeIcon icon={faFileAlt} />
            </Text>
          </Badge>
          <Text fontWeight="bold" wordBreak="break-word">
            {report.title}
          </Text>
        </HStack>
      </ChakraCardBody>
    </ChakraCard>
  );
};

export default ReportListItem;
