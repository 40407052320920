import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Hide,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import {
  faSparkles,
  faRectangleVerticalHistory,
  faCloudWord
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import AllTextAnswers from './AllTextAnswers';
import SurveyTextAnswerCarousel from './SurveyTextAnswerCarousel';
import SurveyTextAnswerSummaries from './SurveyTextAnswerSummaries';
import SurveyAnswerWordcloud from './SurveyAnswerWordcloud';
import AnswerDetailed from './AnswerDetailed';

const SurveyAnswer = ({ field, options = [], resultsCode }) => {
  const { t } = useTranslation();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [limit] = useState(3);
  const [offset, setOffset] = useState(0);

  const modalBackgroundColor = useColorModeValue('white', 'gray.700');

  const {
    isOpen: showAnswerModal,
    onOpen: openAnswerModal,
    onClose: closeAnswerModal
  } = useDisclosure();

  const {
    isOpen: showAllAnswersModal,
    onOpen: openAllAnswersModal,
    onClose: closeAllAnswersModal
  } = useDisclosure();

  const {
    data: answers,
    status,
    isSuccess
  } = useQuery(['survey', 'answers', options, field.id, offset], async () => {
    let params = new URLSearchParams();
    params.append('field', field.id);
    params.append('limit', limit);
    params.append('offset', offset);
    if (options.length > 0) {
      params.append('options', options.map(option => option.id).join(','));
    }
    if (resultsCode) {
      params.append('results_code', resultsCode);
    }
    const { data } = await api.get(`/surveys/answers?${params.toString()}`);
    return data;
  });

  return (
    <Box key={field.id} borderWidth={1} rounded="lg" padding={6}>
      <Stack spacing={8}>
        <Tabs size="sm" variant="fresh">
          <Stack spacing={2}>
            <HStack
              spacing={4}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Heading fontSize="lg">{field.field}</Heading>
              <Spacer />
              <Badge variant="outline" alignSelf="flex-start">
                {t('survey.free_text')}
              </Badge>
            </HStack>
            <HStack spacing={8}>
              <Text variant="muted" whiteSpace="nowrap">
                {t('survey.answer_count', { count: field.metrics.answers })}
              </Text>
              <Divider width="full" />
              <TabList width="fit-content">
                <Tab fontSize="sm" px={2}>
                  <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                </Tab>
                <Tab fontSize="sm" px={2}>
                  <FontAwesomeIcon icon={faSparkles} />
                </Tab>
                <Hide below="lg">
                  <Tab fontSize="sm" px={2}>
                    <FontAwesomeIcon icon={faCloudWord} />
                  </Tab>
                </Hide>
              </TabList>
            </HStack>
          </Stack>
          <TabPanels mt={4} px={4}>
            <TabPanel>
              <LoadingWrapper statuses={[status]}>
                {isSuccess && (
                  <SurveyTextAnswerCarousel
                    answers={answers}
                    onClickRight={() => {
                      setOffset(offset - limit);
                    }}
                    onClickLeft={() => {
                      setOffset(offset + limit);
                    }}
                    onClickAnswer={answer => {
                      setSelectedAnswer(answer);
                      openAnswerModal();
                    }}
                    progressText={
                      field.metrics?.answers > 0 && (
                        <Text>
                          {t('common.item_number_out_of_total', {
                            item: t('common.answer_noun').toLowerCase(),
                            number: offset + 1,
                            total: field.metrics?.answers
                          })}
                        </Text>
                      )
                    }
                    onClickSeeAll={openAllAnswersModal}
                  />
                )}
              </LoadingWrapper>
            </TabPanel>
            <TabPanel>
              <SurveyTextAnswerSummaries
                field={field}
                resultsCode={resultsCode}
              />
            </TabPanel>
            <Hide below="lg">
              <TabPanel>
                <SurveyAnswerWordcloud
                  fieldId={field.id}
                  options={options}
                  emptyState={
                    <Text textAlign="center" variant="muted">
                      {t('common.nothing_here_yet')}
                    </Text>
                  }
                  onClickAnswer={answer => {
                    setSelectedAnswer(answer);
                    openAnswerModal();
                  }}
                  resultsCode={resultsCode}
                />
              </TabPanel>
            </Hide>
          </TabPanels>
        </Tabs>
      </Stack>
      <Modal isOpen={showAnswerModal} onClose={closeAnswerModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontSize={['sm', 'md', null]}>{field.field}</ModalHeader>
          <ModalBody>
            <AnswerDetailed answer={selectedAnswer} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="full"
        isOpen={showAllAnswersModal}
        onClose={closeAllAnswersModal}
      >
        <ModalOverlay />
        <ModalContent position="relative" top={0}>
          <Flex
            bg={modalBackgroundColor}
            width="full"
            position="sticky"
            top={0}
          >
            <ModalHeader
              minWidth="fit-content"
              py={4}
              pr={8}
              fontSize={['sm', 'md', null]}
            >
              {field.field}
            </ModalHeader>
            <ModalCloseButton />
          </Flex>
          <ModalBody pb={4}>
            <Container maxWidth="full" padding={[0, 'auto', 'auto']}>
              <Stack spacing={8}>
                <AllTextAnswers field={field} resultsCode={resultsCode} />
              </Stack>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SurveyAnswer;
