import { useEffect } from 'react';
import '@mdxeditor/editor/style.css';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  MDXEditor,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  toolbarPlugin
} from '@mdxeditor/editor';
import { useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const MDXChakraTheme = props => ({
  //TO-DO: refactor this to target the pre-existing .mdxeditor className, without breaking the dropdowns etc.
  '.mdx': {
    fontFamily: 'var(--chakra-fonts-body)',
    position: 'relative',
    zIndex: 'var(--chakra-zIndices-modal)'
  },
  '.mdxeditor': {
    borderWidth: '1px',
    borderRadius: 'var(--chakra-radii-lg)'
  },
  '.mdx ul, ol': {
    paddingInlineStart: 'var(--chakra-space-4)',
    paddingInlineEnd: 'var(--chakra-space-4)'
  },
  '.mdx a': {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline'
    },
    color: mode('blue.500', 'blue.200')(props)
  },
  '.mdx h2, h3, h4': {
    fontFamily: 'var(--chakra-fonts-heading)'
  },
  '.mdx h2': {
    fontSize: 'var(--chakra-fontSizes-4xl)'
  },
  '.mdx h3': {
    fontSize: 'var(--chakra-fontSizes-3xl)'
  },
  '.mdx h4': {
    fontSize: 'var(--chakra-fontSizes-2xl)'
  },
  '._toolbarRoot_11eqz_145': {
    backgroundColor: 'var(--chakra-colors-chakra-subtle-bg)',
    borderBottomWidth: '1px',
    borderBottomRadius: '0px'
  },
  '._toolbarRoot_11eqz_145 *': {
    fill: 'var(--chakra-colors-chakra-body-text)'
  },
  '._contentEditable_11eqz_352': {
    paddingInlineStart: 'var(--chakra-space-4)',
    paddingInlineEnd: 'var(--chakra-space-4)'
  },
  '._contentEditable_uazmk_379': {
    paddingLeft: '2ch',
    paddingRight: '2ch'
  },
  '._contentEditable_11eqz_352 li': {
    marginInlineStart: '1em'
  },
  '._formField_11eqz_1150 label[for="link-title"], ._formField_11eqz_1150 #link-title,':
    {
      display: 'none'
    },
  '._toolbarGroupOfGroups_uazmk_217 ._toolbarToggleSingleGroup_uazmk_222:nth-of-type(3) ._toolbarToggleItem_uazmk_206':
    {
      display: 'none'
    },
  '._linkDialogPopoverContent_11eqz_554': {
    backgroundColor: 'var(--chakra-colors-chakra-subtle-bg)',
    borderColor: 'inherit',
    fontFamily: 'var(--chakra-fonts-body)',
    fontSize: 'inherit'
  },
  '._linkDialogPopoverContent_11eqz_554:has(a)': {
    display: 'none'
  },
  '._linkDialogPopoverContent_11eqz_554 [type="submit"]': {
    backgroundColor: 'var(--chakra-colors-teal-500)',
    borderWidth: '0px'
  },
  '._linkDialogPopoverContent_11eqz_554 ._textInput_11eqz_1136': {
    backgroundColor: 'var(--chakra-colors-chakra-body-bg)'
  },
  '._selectTrigger_11eqz_281': {
    backgroundColor: 'var(--chakra-colors-chakra-subtle-bg)'
  },
  '._selectItem_11eqz_274': {
    backgroundColor: 'var(--chakra-colors-chakra-body-bg)',
    _hover: {
      backgroundColor: 'var(--chakra-colors-chakra-subtle-bg)'
    }
  },
  '._toolbarToggleSingleGroup_11eqz_203:last-of-type ._toolbarToggleItem_11eqz_187:only-child':
    {
      display: 'none'
    },
  '._toolbarToggleSingleGroup_11eqz_203 button:nth-of-type(3)': {
    display: 'none'
  }
});

export const CustomMDXEditor = ({ field, setValue }) => {
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (field && field.value) {
      let matches = field.value.matchAll(/(\[.*?\])(\(.*?\))/gim);
      let matchesArrays = [...matches];
      let newValue = '';
      if (matchesArrays.length > 0) {
        let replacements = [];
        matchesArrays.forEach(array => {
          if (
            !array[2].startsWith('http', 1) &&
            !array[2].startsWith('mailto', 1)
          ) {
            replacements.push({
              original: array[2],
              replacement: `(https://${array[2].substr(
                1,
                array[2].length - 2
              )} "")`
            });
          }
        });
        if (replacements.length > 0) {
          replacements.forEach(match => {
            newValue = field.value.replace(match.original, match.replacement);
          });
          setValue(newValue);
        }
      }
    }
  }, [field, field.value, setValue]);
  return (
    <MDXEditor
      className={colorMode === 'dark' ? 'mdx dark-theme' : 'mdx'}
      markdown={field?.value ? field.value.replace(/<\/?u>/g, '') : ''}
      plugins={[
        headingsPlugin({ allowedHeadingLevels: [2, 3, 4] }),
        listsPlugin({}),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
              <CreateLink />
            </>
          )
        }),
        linkPlugin(),
        linkDialogPlugin()
      ]}
      {...field}
    />
  );
};

export default CustomMDXEditor;
