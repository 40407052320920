import { Reorder, useDragControls } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';
import ActionMenu, {
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import FieldTable from './FieldTable';
import StepForm from './StepForm';
import StepReminderForm from './StepReminderForm';
import MembersButton from 'components/MembersButton';
import IconTooltip from 'components/tooltips/IconTooltip';

const StepListItem = ({
  step,
  workflow,
  onDrag,
  onFieldChangeStep,
  isOpen
}) => {
  const controls = useDragControls();
  const toast = useToast();

  const {
    isOpen: showUpdateStepModal,
    onOpen: openUpdateStepModal,
    onClose: closeUpdateStepModal
  } = useDisclosure();

  const {
    isOpen: showDeleteStepModal,
    onOpen: openDeleteStepModal,
    onClose: closeDeleteStepModal
  } = useDisclosure();

  const {
    isOpen: showSetReminderModal,
    onOpen: openSetReminderModal,
    onClose: closeSetReminderModal
  } = useDisclosure();

  const { t } = useTranslation();

  const deleteStepMutation = useMutation(
    () => api.delete(`/steps/${step.id}`),
    {
      onSuccess: () => {
        const steps = queryClient.getQueryData(['steps', workflow.id]);
        queryClient.setQueryData(
          ['steps', workflow.id],
          steps.filter(s => s.id !== step.id)
        );
        closeDeleteStepModal();
        toast({
          title: t('toast.step_delete_success'),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.step_delete_error'),
          status: 'error'
        });
      }
    }
  );

  const updateStepMutation = useMutation(
    payload => api.patch(`/steps/${step.id}`, payload),
    {
      onSuccess: ({ data: step }) => {
        toast({
          title: t('toast.step_update_success'),
          status: 'success'
        });
        closeUpdateStepModal();
        closeSetReminderModal();
        const steps = queryClient.getQueryData(['steps', workflow.id]);
        queryClient.setQueryData(
          ['steps', workflow.id],
          steps.map(s => (s.id === step.id ? step : s))
        );
      },
      onError: () => {
        toast({
          title: t('toast.step_update_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteStepMutation.mutateAsync(step.id);
  };

  const handleOnUpdateStep = async values => {
    await updateStepMutation.mutateAsync({ ...values, workflow: workflow.id });
  };

  const queryClient = useQueryClient();

  return (
    <AccordionItem>
      <Reorder.Item
        as="div"
        value={step}
        dragListener={false}
        dragControls={controls}
      >
        <HStack spacing={4} py={4}>
          <Text
            cursor="grab"
            variant="light"
            onPointerDown={e => {
              onDrag();
              controls.start(e);
            }}
          >
            <FontAwesomeIcon icon={faGripDots} />
          </Text>
          <AccordionButton
            px={0}
            _hover={{
              bg: null
            }}
          >
            <HStack spacing={4} width="full">
              <Badge colorScheme={step ? step.color : null} userSelect="none">
                {step.name}
              </Badge>
            </HStack>
            <AccordionIcon ml={2} />
          </AccordionButton>
          <ActionMenu>
            <EditMenuItem onClick={openUpdateStepModal} />
            <DeleteMenuItem onClick={openDeleteStepModal} />
          </ActionMenu>
        </HStack>
        <AccordionPanel pb={8}>
          <Stack spacing={4}>
            <HStack
              spacing={4}
              justifyContent="space-between"
              alignItems="space-between"
            >
              <Stack spacing={2}>
                <HStack spacing={2} fontSize="xs" color="gray">
                  <Text variant="muted">{t('common.description')}</Text>
                  <IconTooltip
                    style={{ alignSelf: 'center' }}
                    label={t('workflow.step_description_visibility_explainer')}
                  />
                </HStack>
                {step.description?.length > 0 ? (
                  <Text fontSize="sm" overflowY="auto" maxHeight="30vh">
                    {step.description}
                  </Text>
                ) : (
                  <Text fontSize="sm" variant="light" fontStyle="italic">
                    {t('common.none')}
                  </Text>
                )}
              </Stack>
              <HStack spacing={4} alignItems="flex-start">
                <Stack spacing={2}>
                  <Text variant="muted">{t('common.reminder')}</Text>
                  <Button
                    width="full"
                    variant="outline"
                    size="sm"
                    onClick={openSetReminderModal}
                    leftIcon={<FontAwesomeIcon icon={faClock} />}
                  >
                    <Text color={step.reminder_days ? null : 'gray'}>
                      {step.reminder_days ? step.reminder_days : '-'}
                    </Text>
                  </Button>
                </Stack>
                <Stack spacing={2}>
                  <Text textAlign="center" variant="muted">
                    {t('common.panel')}
                  </Text>
                  <MembersButton
                    size="sm"
                    max={3}
                    members={step.panel}
                    onSubmit={async ({ members }) => {
                      let panel = members.map(member => member.id);
                      await handleOnUpdateStep({ panel });
                    }}
                    canEdit
                  />
                </Stack>
              </HStack>
            </HStack>
            <FieldTable
              step={step}
              workflow={workflow}
              onFieldChangeStep={onFieldChangeStep}
              isOpen={isOpen}
            />
          </Stack>
        </AccordionPanel>
        <ModalWrapper
          title={t('common.step')}
          isOpen={showUpdateStepModal}
          onClose={closeUpdateStepModal}
        >
          <StepForm
            defaultValues={{
              name: step.name,
              description: step.description,
              color: step.color,
              hide_cards: step.hide_cards,
              use_reactions: step.use_reactions
            }}
            isOpen={showUpdateStepModal}
            onSubmit={handleOnUpdateStep}
          />
        </ModalWrapper>
        <ModalWrapper
          size="md"
          title={t('workflow.set_reminder')}
          isOpen={showSetReminderModal}
          onClose={closeSetReminderModal}
          confirmClosure
        >
          <Stack spacing={4}>
            <Text fontSize="sm">{t('workflow.reminder_explainer')}</Text>
            <StepReminderForm
              defaultValues={{
                reminder_days: step.reminder_days
              }}
              isOpen={showSetReminderModal}
              onDelete={async () => {
                await handleOnUpdateStep({ reminder_days: null });
              }}
              onSubmit={async values => {
                await handleOnUpdateStep(values);
              }}
            />
          </Stack>
        </ModalWrapper>
        <ConfirmationModal
          deleteText={t(
            'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
            { data: t('common.step').toLowerCase() }
          )}
          isOpen={showDeleteStepModal}
          onClose={closeDeleteStepModal}
          onDelete={handleOnClickDelete}
        />
      </Reorder.Item>
    </AccordionItem>
  );
};

export default StepListItem;
