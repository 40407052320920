import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  Container,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import AnnouncementDetailed from 'features/announcement/AnnouncementDetailed';
import AnnouncementListItem from 'features/announcement/AnnouncementListItem';
import BoardList from 'features/board/BoardList';
import ManagePage from 'features/member/ManagePage';
import { Plans, hasPlan } from 'features/hive/hiveUtils';
import api from 'utils/api';
import HiveActionMenu from './HiveActionMenu';
import HiveForm from './HiveForm';
import PopupAnnouncementForm from 'features/announcement/PopupAnnouncementForm';
import { useEffect, useState } from 'react';

const HivePage = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive, refetch } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const [popupJustUpdated, setPopupJustUpdated] = useState(false);

  const {
    isOpen: showAnnouncementModal,
    onOpen: openAnnouncementModal,
    onClose: closeAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showPopupAnnouncementFormModal,
    onOpen: openPopupAnnouncementFormModal,
    onClose: closePopupAnnouncementFormModal
  } = useDisclosure();

  const {
    isOpen: showPopupAnnouncementDetailedModal,
    onOpen: openPopupAnnouncementDetailedModal,
    onClose: closePopupAnnouncementDetailedModal
  } = useDisclosure();

  const {
    isOpen: showHomeModal,
    onOpen: openHomeModal,
    onClose: closeHomeModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const toast = useToast();

  const updateHiveMutation = useMutation(
    payload => api.patch(`/hives/${slug}`, payload),
    {
      onSuccess: ({ data: hive }) => {
        queryClient.setQueryData(['hive', slug], hive);
        closeHomeModal();
        toast({
          title: t('toast.hive_update_success'),
          status: 'success'
        });
        refetch();
      },
      onError: () => {
        toast({
          title: t('toast.hive_update_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnUpdatePopupAnnouncement = async form => {
    const payload = new FormData();
    payload.append('popup_announcement', form?.popup_announcement?.id ?? '');
    try {
      await updateHiveMutation.mutateAsync(payload);
      setPopupJustUpdated(true);
      closePopupAnnouncementFormModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnUpdateHive = async hive => {
    const payload = new FormData();
    if (!hive.image || hive.image instanceof File) {
      payload.append('image', hive.image ? hive.image : '');
    }
    if (!hive.video) {
      payload.append('video', '');
    }
    payload.append('unsplash', hive.unsplash ? hive.unsplash : '');
    payload.append('title', hive.title);
    payload.append('description', hive.description ? hive.description : '');

    try {
      if (hive.video instanceof File) {
        const { data } = await api.post('/signed-url', {
          filename: hive.video.name,
          model_type: 'hive',
          model_id: hive.id
        });
        await axios.put(data.signed_url, hive.video, {
          headers: {
            'Content-Type': hive.video.type || 'application/octet-stream'
          }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await updateHiveMutation.mutateAsync(payload);
  };

  useEffect(() => {
    if (hive?.popup_announcement && !popupJustUpdated) {
      const lastSeenPopupAnnouncement = parseInt(
        localStorage.getItem('lastSeenPopupAnnouncement')
      );
      const alreadySeenLatest =
        lastSeenPopupAnnouncement === hive?.popup_announcement?.id;
      if (!alreadySeenLatest) {
        const timer = setTimeout(() => {
          openPopupAnnouncementDetailedModal();
        }, 1500);
        return () => clearTimeout(timer);
      }
    }
  }, [
    hive?.popup_announcement,
    openPopupAnnouncementDetailedModal,
    popupJustUpdated
  ]);

  return hasPlan(hive?.plan, Plans.Freemium) ? (
    <ManagePage />
  ) : (
    <Container maxW="container.lg" marginY={8}>
      <>
        <Stack spacing={16}>
          <Stack spacing={8}>
            {me ? (
              <>
                <Stack>
                  <HStack justifyContent="space-between">
                    <Heading fontSize="3xl">
                      {t('home.hi_name', { name: me.first_name })}
                    </Heading>
                    {profile?.is_admin && (
                      <HiveActionMenu
                        onClickHome={openHomeModal}
                        onClickPopupAnnouncement={
                          openPopupAnnouncementFormModal
                        }
                      />
                    )}
                  </HStack>
                  <Text>{t('home.happy_to_see_you_here_today')}</Text>
                </Stack>
              </>
            ) : null}
            <SimpleGrid columns={[1, null, 2]} spacing={8}>
              <AspectRatio ratio={16 / 9}>
                <CoverMedia object={hive} clickToFullscreen />
              </AspectRatio>
              <TitleDescription
                title={hive?.title}
                description={hive?.description}
                noOfCharacters={650}
              />
            </SimpleGrid>
            {hive?.latest_announcement && (
              <>
                <Stack spacing={4}>
                  <AnnouncementListItem
                    announcement={hive.latest_announcement}
                    onClickReadMore={openAnnouncementModal}
                  />
                  <Button
                    alignSelf="flex-start"
                    as={Link}
                    variant="link"
                    to={`/${slug}/announcements`}
                  >
                    {t('button.see_all_announcements')}
                  </Button>
                </Stack>
                <AnnouncementDetailed
                  announcement={hive.latest_announcement}
                  isOpen={showAnnouncementModal}
                  onClose={closeAnnouncementModal}
                  canEdit={profile?.is_admin_or_manager}
                />
              </>
            )}
          </Stack>
          {hive.metrics.boards > 0 && (
            <Stack spacing={8}>
              <BoardList />
            </Stack>
          )}
        </Stack>
        <ModalWrapper
          title={t('home.set_popup_announcement')}
          isOpen={showPopupAnnouncementFormModal}
          onClose={() => {
            closePopupAnnouncementFormModal();
          }}
          confirmClosure
        >
          <PopupAnnouncementForm
            defaultValues={{ popup_announcement: hive?.popup_announcement }}
            isOpen={showPopupAnnouncementFormModal}
            onSubmit={handleOnUpdatePopupAnnouncement}
            refetchHive={refetch}
          />
        </ModalWrapper>
        {hive?.popup_announcement && (
          <AnnouncementDetailed
            announcement={hive?.popup_announcement}
            isOpen={showPopupAnnouncementDetailedModal}
            onClose={() => {
              localStorage.setItem(
                'lastSeenPopupAnnouncement',
                hive.popup_announcement?.id
              );
              closePopupAnnouncementDetailedModal();
            }}
            canEdit={false}
          />
        )}
        <ModalWrapper
          title={t('common.home')}
          isOpen={showHomeModal}
          onClose={closeHomeModal}
          pb={6}
        >
          <HiveForm
            defaultValues={hive}
            isOpen={showHomeModal}
            onSubmit={handleOnUpdateHive}
          />
        </ModalWrapper>
      </>
    </Container>
  );
};

export default HivePage;
