import { getPublicFields } from './cardUtils';
import PublicCardFields from './PublicCardFields';

const PublicContributorFeedback = ({ card }) => {
  const fields = getPublicFields(card);
  return (
    <PublicCardFields
      card={card}
      fields={fields}
      lastField={fields.length > 0 ? null : fields[fields.length - 1]}
    />
  );
};

export default PublicContributorFeedback;
