import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import CreateDrawer from './CreateDrawer';
import CardCreationForm from 'features/card/CardCreationForm';
import ModalWrapper from './ModalWrapper';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';

const CreateButtonBase = ({ children, ...rest }) => (
  <Button
    position="fixed"
    colorScheme="teal"
    bottom={4}
    right={4}
    rounded="full"
    fontSize="xl"
    shadow="xl"
    zIndex={2}
    {...rest}
  >
    {children}
  </Button>
);

const MobileCreateButton = ({
  openInviteModal,
  openCreateAnnouncementModal,
  openCreateCollectionModal
}) => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const { showMobileCreateButton } = useUi();
  const { t } = useTranslation();

  const {
    isOpen: showCreateDrawer,
    onOpen: openCreateDrawer,
    onClose: closeCreateDrawer
  } = useDisclosure();

  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  return profile?.is_admin_or_manager ? (
    hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
      <CreateButtonBase
        height={12}
        width={12}
        fontSize="2xl"
        onClick={openCreateDrawer}
      >
        <FontAwesomeIcon icon={faPlus} />
        <CreateDrawer
          isOpen={showCreateDrawer}
          onClose={closeCreateDrawer}
          openCreateAnnouncementModal={openCreateAnnouncementModal}
          openCreateCollectionModal={openCreateCollectionModal}
          openInviteModal={openInviteModal}
        />
      </CreateButtonBase>
    )
  ) : showMobileCreateButton ? (
    <CreateButtonBase px={6} py={8} fontSize="xl" onClick={openCreateCardModal}>
      <HStack>
        <FontAwesomeIcon icon={faPlus} />
        <Text>{t('button.submit')}</Text>
      </HStack>
      <ModalWrapper
        size="full"
        isOpen={showCreateCardModal}
        onClose={closeCreateCardModal}
        hasSteps
      >
        <CardCreationForm
          isOpen={showCreateCardModal}
          onClose={closeCreateCardModal}
          isMobile
        />
      </ModalWrapper>
    </CreateButtonBase>
  ) : null;
};

export default MobileCreateButton;
