import { useDisclosure } from '@chakra-ui/react';
import AvatarGroupButton from 'components/AvatarGroupButton';
import ScorersModal from './ScorersModal';
import { useEffect } from 'react';

const ScorersButton = ({
  code,
  scores,
  parameters,
  max = 3,
  isAdminOrCollectionManager = false,
  focusedScore,
  resetFocusedScore,
  ...rest
}) => {
  const {
    isOpen: showScorersModal,
    onOpen: openScorersModal,
    onClose: closeScorersModal
  } = useDisclosure();

  const scorers = scores?.map(score => score.creator);

  useEffect(() => {
    if (focusedScore?.scoreId) {
      openScorersModal();
    }
  }, [
    showScorersModal,
    openScorersModal,
    closeScorersModal,
    focusedScore,
    scores
  ]);

  return (
    <>
      <>
        <AvatarGroupButton
          max={max}
          members={scorers}
          onClick={openScorersModal}
          isDisabled={scorers?.length === 0}
          showCount
          {...rest}
        />
        <ScorersModal
          code={code}
          scores={scores}
          parameters={parameters}
          isOpen={showScorersModal}
          onClose={() => {
            if (resetFocusedScore) {
              resetFocusedScore();
            }
            closeScorersModal();
          }}
          isAdminOrCollectionManager={isAdminOrCollectionManager}
          focusedScore={focusedScore}
        />
      </>
    </>
  );
};

export default ScorersButton;
