import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  Heading,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CoverMedia from 'components/CoverMedia';
import { findMedia } from 'utils/media';

const BoardListGalleryViewItem = ({ board }) => {
  const { slug } = useParams();

  return (
    <ChakraCard
      variant="outline"
      cursor="pointer"
      overflow="hidden"
      as={Link}
      to={`/${slug}/board/${board.id}`}
    >
      {findMedia(board) && (
        <CoverMedia object={board} height="100px" rounded={null} />
      )}
      <ChakraCardBody>
        <Stack spacing={2}>
          <Heading fontSize="lg" noOfLines={2}>
            {board.title}
          </Heading>
          {board.stripped_description && (
            <Text noOfLines={3} fontSize="sm">
              {board.stripped_description}
            </Text>
          )}
        </Stack>
      </ChakraCardBody>
    </ChakraCard>
  );
};

const BoardListGalleryView = ({ managerId }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const { t } = useTranslation();

  const {
    status,
    data: boards,
    isSuccess
  } = useQuery(['boards', { manager: managerId }, slug], async () => {
    const { data } = await api.get(`/boards?hive=${hive.id}`);
    return data;
  });

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.boards').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <SimpleGrid columns={[1, 2, null]} spacing={4}>
          {boards.map(board => (
            <React.Fragment key={board.id}>
              <BoardListGalleryViewItem key={board.id} board={board} />
            </React.Fragment>
          ))}
        </SimpleGrid>
      ) : null}
    </LoadingWrapper>
  );
};

export default BoardListGalleryView;
