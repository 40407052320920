import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRef } from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Stack,
  useToast
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

const PlaceholderImages = ({ hive }) => {
  const imageRef = useRef();

  const { t } = useTranslation();

  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    data: placeholderImages,
    status,
    isSuccess
  } = useQuery(['placeholder-images'], async () => {
    const { data } = await api.get(`/placeholder-images?hive=${hive.id}`);
    return data;
  });

  const { mutateAsync: uploadImage, isLoading: createIsLoading } = useMutation(
    payload => api.post('/placeholder-images', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['placeholder-images']);
      },
      onError: () => {
        toast({
          title: t('toast.image_upload_error'),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: deleteImage, isLoading: deleteIsLoading } = useMutation(
    async id => api.delete(`/placeholder-images/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['placeholder-images']);
      },
      onError: () => {
        toast({
          title: t('toast.image_delete_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnDeletePlaceholderImage = async id => {
    await deleteImage(id);
  };

  const handleUploadPlaceholderImage = async e => {
    const payload = new FormData();
    const file = e.target.files[0];
    payload.append('hive', hive.id);
    payload.append('image', file);
    uploadImage(payload);
  };

  return (
    <Stack spacing={8}>
      <Heading fontSize="lg">{t('admin.placeholder_images')}</Heading>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.images').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Stack spacing={8}>
            <SimpleGrid columns={[1, 2, 3]} gap={4}>
              {placeholderImages.map(image => (
                <AspectRatio key={image.id} ratio={4 / 3}>
                  <Box position="relative" rounded="lg">
                    <Image
                      src={image.thumbnail_image}
                      rounded="lg"
                      height="200px"
                      objectFit="cover"
                    />
                    <Button
                      position="absolute"
                      variant="overlay"
                      top={2}
                      right={1}
                      size="sm"
                      onClick={() => handleOnDeletePlaceholderImage(image.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </Box>
                </AspectRatio>
              ))}
            </SimpleGrid>
            <Button
              colorScheme="teal"
              onClick={() => imageRef.current.click()}
              isLoading={createIsLoading || deleteIsLoading}
            >
              {t('button.upload_image')}
            </Button>
            <Input
              type="file"
              accept="image/*"
              ref={imageRef}
              onChange={handleUploadPlaceholderImage}
              hidden
            />
          </Stack>
        )}
      </LoadingWrapper>
    </Stack>
  );
};

export default PlaceholderImages;
