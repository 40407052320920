import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spacer,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react';
import MembersButton from 'components/MembersButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import IconTooltip from 'components/tooltips/IconTooltip';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

const CardHeader = ({
  code,
  card,
  canEdit,
  collectionRefetch,
  isAdminOrCollectionManager,
  isAdminOrAssigneeOrCollectionManager,
  updateCardMutation
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const handleOnChangeAssignees = async ({ members }) => {
    try {
      await updateCardMutation.mutateAsync({
        assignees: members.map(member => member.id),
        hive: slug
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChangeStep = async step => {
    try {
      await updateCardMutation.mutateAsync({
        step,
        hive: slug
      });
      if (collectionRefetch) {
        collectionRefetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChangePrivate = async value => {
    await updateCardMutation.mutateAsync({ private: value, hive: slug });
  };

  return (
    <HStack spacing={[4, null, 8]} alignItems="flex-start" rowGap={2}>
      <HStack
        spacing={4}
        flexWrap={['wrap', null, 'nowrap']}
        width="min-content"
        flexGrow={3}
        rowGap={2}
      >
        <Stack direction={['column', 'row', null]} alignItems="center">
          <Tooltip
            isDisabled={!card?.step?.description}
            label={card?.step?.description}
            hasArrow
            placement="top"
            maxWidth={['70vw', '50vw', '30vw']}
          >
            <Box>
              {isAdminOrAssigneeOrCollectionManager ? (
                <Menu placement="bottom-start" zIndex={999999999}>
                  <MenuButton variant="unstyled">
                    <Badge colorScheme={card.step ? card.step.color : 'gray'}>
                      <HStack>
                        <Text>
                          {card.step ? card.step.name : t('common.inbox')}
                        </Text>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </HStack>
                    </Badge>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => handleOnChangeStep(null)}>
                      <Badge colorScheme="gray">{t('common.inbox')}</Badge>
                    </MenuItem>
                    {card.collection.workflow?.steps.map(step => (
                      <MenuItem
                        key={step.id}
                        onClick={() => handleOnChangeStep(step.id)}
                      >
                        <Badge colorScheme={step.color}>{step.name}</Badge>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              ) : (
                <Badge colorScheme={card.step ? card.step.color : 'gray'}>
                  {card.step ? card.step.name : t('common.inbox')}
                </Badge>
              )}
            </Box>
          </Tooltip>
        </Stack>
        {canEdit ? (
          card?.collection.workflow?.use_card_visibility ? (
            <Show above="md">
              <Tooltip label={t('card.visible_to')} placement="top">
                <Box>
                  <Stack
                    direction={['column', 'row', null]}
                    alignItems="center"
                  >
                    <Menu>
                      <MenuButton>
                        <Badge minWidth="max-content" width="full">
                          <HStack spacing={2}>
                            <Text textAlign="left" width="full">
                              {card.private
                                ? t('common.contributors')
                                : t('common.everyone')}
                            </Text>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </HStack>
                        </Badge>
                      </MenuButton>
                      <MenuList position="right">
                        <MenuItem onClick={() => handleOnChangePrivate(false)}>
                          <Text fontSize="md">{t('common.all_members')}</Text>
                        </MenuItem>
                        <MenuItem onClick={() => handleOnChangePrivate(true)}>
                          <Text fontSize="md">
                            {t('common.only_contributors')}
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Stack>
                </Box>
              </Tooltip>
            </Show>
          ) : null
        ) : null}
        {card.collection?.workflow?.use_card_assignees ? (
          <HStack spacing={2}>
            <Text variant="muted">{t('common.assignees')}</Text>
            <MembersButton
              code={code}
              max={3}
              members={card.assignees}
              onSubmit={handleOnChangeAssignees}
              modalTitle={t('common.assigned_to')}
              canEdit={isAdminOrCollectionManager}
              size="xs"
              px={2}
            />
          </HStack>
        ) : null}
      </HStack>
      <Spacer />
      <Flex justifyContent="flex-end">
        {card.hidden && (
          <IconTooltip
            fontSize="md"
            icon={faEyeSlash}
            label={t('common.hidden_one')}
            placement="top"
            alignSelf="center"
          />
        )}
      </Flex>
    </HStack>
  );
};

export default CardHeader;
