import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/pro-solid-svg-icons';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import PoweredByHives from 'components/PoweredByHives';
import { useConfetti } from 'providers/ConfettiProvider';
import api from 'utils/api';
import SurveyPreviewFields from './SurveyPreviewFields';
import TitleDescription from 'components/TitleDescription';
import { motion, useSpring } from 'framer-motion';

const CountdownProgress = ({ max, value, labelCount, text, size }) => {
  const labelLimit = max / 10;
  return (
    <>
      {text}
      <Flex width="fit-content" justifyContent="center">
        <CircularProgress
          size={size}
          thickness={4}
          as={motion.div}
          value={value}
          max={max}
        >
          <CircularProgressLabel>
            {labelCount >= 0
              ? labelCount <= labelLimit
                ? labelCount
                : labelLimit
              : 0}
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </>
  );
};

const SurveyPreview = () => {
  const [fieldIndex, setFieldIndex] = useState(0);

  const { fire } = useConfetti();
  const { slug, code } = useParams();

  const { t } = useTranslation();

  const [introduction, setIntroduction] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [countdown, setCountdown] = useState(20);
  const [inactive, setInactive] = useState(false);
  const timeoutRef = useRef(null);
  const springValue = useSpring(countdown * 10, {
    stiffness: 100,
    damping: 20
  }); // needed to correctly finish the completion timer reaching 0, but has no effect on the modal one, probably due to modal closing

  const queryClient = useQueryClient();

  const {
    status: surveyStatus,
    data: survey,
    isSuccess: surveyIsSuccess
  } = useQuery(['survey', 'preview', code], async () => {
    const { data } = await api.get(`/surveys/previews/${code}`);
    return data;
  });

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setInactive(true);
    }, 30 * 1000);
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      resetTimeout();
    };

    if (survey?.kiosk_mode && !introduction) {
      window.addEventListener('mousemove', handleUserInteraction);
      window.addEventListener('keydown', handleUserInteraction);
      window.addEventListener('click', handleUserInteraction);

      resetTimeout();

      return () => {
        window.removeEventListener('mousemove', handleUserInteraction);
        window.removeEventListener('keydown', handleUserInteraction);
        window.removeEventListener('click', handleUserInteraction);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [t, introduction, survey?.kiosk_mode]);

  const handleOnDismissCountdown = () => {
    setInactive(false);
    setCountdown(20);
    resetTimeout();
  };

  const handleOnFormReset = useCallback(() => {
    setCountdown(-1); //needed to not visually stay at 0
    setTimeout(() => {
      setFieldIndex(0);
      setCompleted(false);
      setCountdown(20);
      setIntroduction(true);
      setInactive(false);
    }, 750);
  }, []);

  useEffect(() => {
    if (survey?.kiosk_mode && (completed || inactive)) {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 0) {
            clearInterval(interval);
            handleOnFormReset();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [completed, inactive, handleOnFormReset, countdown, survey?.kiosk_mode]);

  useEffect(() => {
    springValue.set(countdown * 10);
  }, [countdown, springValue]);

  const handleOnClickPrevious = () => {
    if (fieldIndex > 0) {
      setFieldIndex(fieldIndex - 1);
      setMissingRequired(false);
    }
  };

  const handleOnClickNext = () => {
    if (!missingRequired && fieldIndex < survey.fields.length - 1) {
      setFieldIndex(fieldIndex + 1);
      setMissingRequired(false);
    }
  };

  useEffect(() => {
    if (completed) {
      survey?.kiosk_mode && setCountdown(10);
      fire();
    }
  }, [completed, fire, survey?.kiosk_mode]);

  const hive = queryClient.getQueryData(['hive', slug]);

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  const HeaderNavigation = ({ ...props }) => (
    <HStack
      {...props}
      flexWrap="wrap"
      rowGap={4}
      justifyContent="space-between"
    >
      <Link to={`/${slug}`}>
        <Image src={logo} height={8} />
      </Link>
      <Spacer />
      <Text
        maxW={['90vw', '35vw', '20vw']}
        variant="light"
        textAlign="right"
        borderRadius="md"
      >
        {t('survey.no_responses_saved')}
      </Text>
    </HStack>
  );

  const FooterNavigation = ({ ...props }) => (
    <HStack
      {...props}
      pointerEvents="none"
      flexWrap="wrap-reverse"
      rowGap={4}
      justifyContent="flex-end"
    >
      <Flex pointerEvents="auto">
        <Show below="md">
          <Flex
            bg="var(--chakra-colors-chakra-body-bg)"
            position="absolute"
            width="calc(100vw - 20px)"
            bottom={0}
            pr={1}
            pl={2}
            pb={2}
            left={2}
          >
            <PoweredByHives />
          </Flex>
        </Show>
        <Show above="md">
          <PoweredByHives />
        </Show>
      </Flex>
      <Spacer />
      <HStack spacing={4}>
        {!introduction && !completed && (
          <>
            <Text
              alignSelf="center"
              wordBreak="keep-all"
            >{`${fieldIndex + 1} / ${survey?.fields.length}`}</Text>
            <HStack pointerEvents="auto">
              <Button
                variant="outline"
                isDisabled={fieldIndex === 0}
                onClick={handleOnClickPrevious}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button
                variant="outline"
                isDisabled={
                  missingRequired || fieldIndex === survey?.fields.length - 1
                }
                onClick={handleOnClickNext}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </HStack>
          </>
        )}
      </HStack>
    </HStack>
  );

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Show above="lg">
        <HeaderNavigation
          position="absolute"
          minHeight="40px"
          top={8}
          left={8}
          right={8}
          zIndex={1}
        />
      </Show>
      <Container maxW="container.xs" marginY={8}>
        <LoadingWrapper statuses={[surveyStatus]}>
          {surveyIsSuccess && (
            <Stack spacing={8}>
              <Show below="lg">
                <HeaderNavigation />
              </Show>
              {introduction ? (
                <Stack spacing={8}>
                  <CoverMedia
                    object={survey}
                    height="200px"
                    hideIfEmpty
                    clickToFullscreen
                  />
                  <TitleDescription
                    title={survey.title}
                    description={survey.description}
                    noOfCharacters={250}
                  />
                  <Button
                    colorScheme="teal"
                    alignSelf="flex-end"
                    onClick={() => {
                      setIntroduction(false);
                    }}
                    isDisabled={survey.fields.length === 0}
                  >
                    {t('button.start')}
                  </Button>
                </Stack>
              ) : completed ? (
                <Stack spacing={8}>
                  <Heading fontSize="3xl">
                    {`${t('survey.completion.thank_you_title')} 🎉`}
                  </Heading>
                  <Text>{t('survey.completion.thank_you_description')}</Text>
                  <Spacer />
                  {survey?.kiosk_mode && (
                    <HStack
                      spacing={4}
                      width="full"
                      justifyContent="space-between"
                    >
                      <HStack
                        spacing={4}
                        width="full"
                        justifyContent="flex-end"
                      >
                        <CountdownProgress
                          text={
                            <Text textAlign="right" width="full">
                              {t('survey.restarting_in')}
                            </Text>
                          }
                          value={springValue?.current}
                          labelCount={Math.round(countdown + 0.5)}
                          max={100}
                          size="80px"
                        />
                      </HStack>
                      <Button
                        width="fit-content"
                        alignSelf="center"
                        variant="outline"
                        onClick={handleOnFormReset}
                      >
                        {t('survey.restart_now')}
                      </Button>
                    </HStack>
                  )}
                </Stack>
              ) : (
                <Stack spacing={8}>
                  <SurveyPreviewFields
                    survey={survey}
                    fieldIndex={fieldIndex}
                    onNext={handleOnClickNext}
                    onDone={() => {
                      setCountdown(10);
                      setCompleted(true);
                    }}
                    missingRequired={missingRequired}
                    setMissingRequired={setMissingRequired}
                  />
                </Stack>
              )}
              <Show below="lg">
                <FooterNavigation />
              </Show>
            </Stack>
          )}
          <Show above="lg">
            <FooterNavigation
              position="absolute"
              minHeight="40px"
              bottom={8}
              left={8}
              right={8}
              spacing={4}
            />
          </Show>
          <Modal
            isOpen={survey?.kiosk_mode && inactive && !completed}
            onClose={handleOnDismissCountdown}
            size="md"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Heading size="lg">{t('survey.still_there')}</Heading>{' '}
              </ModalHeader>
              <ModalBody>
                <Stack spacing={4} width="full" alignItems="center">
                  <CountdownProgress
                    value={springValue?.current}
                    text={
                      <Text textAlign="center" width="66%">
                        {t('survey.clear_answers_warning')}
                      </Text>
                    }
                    labelCount={Math.round(countdown + 0.5)}
                    max={200}
                    size="100px"
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <HStack width="full" justifyContent="space-between" spacing={4}>
                  <Button variant="outline" onClick={handleOnFormReset}>
                    {t('survey.restart_now')}
                  </Button>
                  <Button colorScheme="teal" onClick={handleOnDismissCountdown}>
                    {t('button.continue')}
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </LoadingWrapper>
      </Container>
    </Flex>
  );
};

export default SurveyPreview;
