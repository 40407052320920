import { useEffect, useRef, useState } from 'react';
import {
  Center,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlayCircle } from '@fortawesome/pro-duotone-svg-icons';
import { findMedia } from 'utils/media';
import ImageDetailed from 'features/attachment/ImageDetailed';

const VIDEO_PLACEHOLDER = 'placeholder';

const CoverMedia = ({
  children,
  object,
  hideIfEmpty = false,
  placeholderImages = [],
  clickToFullscreen,
  ...rest
}) => {
  const {
    isOpen: showImageModal,
    onOpen: openImageModal,
    onClose: closeImageModal
  } = useDisclosure();

  const ref = useRef();
  const [videoClicked, setVideoClicked] = useState(false);
  const { colorMode } = useColorMode();

  const [previewMedia, setPreviewMedia] = useState(null);
  const [previewFileName, setPreviewFileName] = useState(null);

  const [type, setType] = useState(null);

  let media = findMedia(object);
  let hasImageFile =
    object?.image instanceof File || object?.placeholder_image instanceof File;
  let hasVideoFile = object?.video instanceof File;

  useEffect(() => {
    const readMediaAsURL = file => {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewFileName(file.name);
        if (hasVideoFile) {
          setPreviewMedia(VIDEO_PLACEHOLDER);
        } else {
          setPreviewMedia(reader.result);
        }
      };
      reader.readAsDataURL(file);
    };

    if (!hasImageFile && !hasVideoFile) {
      setPreviewMedia(null);
      setPreviewFileName(null);
    } else {
      if (hasImageFile) {
        if (object?.placeholder_image?.image) {
          readMediaAsURL(object.placeholder_image);
        } else {
          readMediaAsURL(object.image);
        }
      } else if (hasVideoFile) {
        readMediaAsURL(object.video);
      }
    }
  }, [object, hasImageFile, hasVideoFile]);

  useEffect(() => {
    if (
      object?.placeholder_image ||
      object?.image ||
      object?.unsplash ||
      hasImageFile
    ) {
      setType('image');
    } else if (object?.video || hasVideoFile) {
      setType('video');
    } else {
      setType(null);
    }
  }, [object, hasImageFile, hasVideoFile]);

  const handleOnClickPlay = () => {
    if (ref.current) {
      setVideoClicked(true);
      ref.current.play();
    }
  };

  if (hideIfEmpty && !hasImageFile && !hasVideoFile && !type) return null;

  return (
    <Center
      bg={
        type === 'video'
          ? 'black'
          : colorMode === 'light'
            ? 'gray.50'
            : 'whiteAlpha.100'
      }
      rounded="lg"
      position="relative"
      {...rest}
    >
      {type === 'image' ? (
        <Image
          src={previewMedia || media}
          fallback={
            <Text fontSize="3xl">
              <FontAwesomeIcon icon={faImage} />
            </Text>
          }
          height="full"
          width="full"
          rounded="lg"
          objectFit="cover"
          cursor={clickToFullscreen ? 'pointer' : 'default'}
          pointerEvents={clickToFullscreen ? 'all' : 'none'}
          onClick={clickToFullscreen ? openImageModal : null}
          {...rest}
        />
      ) : type === 'video' ? (
        <>
          {media && previewMedia !== VIDEO_PLACEHOLDER ? (
            <Flex
              position="relative"
              bg="blackAlpha.500"
              rounded="lg"
              height="full"
              width="full"
            >
              <video
                ref={ref}
                src={media}
                controls={videoClicked}
                position="relative"
                width="100%"
                style={{ borderRadius: '10px', objectFit: 'cover' }}
              >
                Your browser does not support the video tag.
              </video>
              {!videoClicked && (
                <Flex
                  position="absolute"
                  justify="center"
                  align="center"
                  bg="blackAlpha.500"
                  height="full"
                  width="full"
                  cursor="pointer"
                  onClick={handleOnClickPlay}
                >
                  <Text color="gray.200">
                    <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                  </Text>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex
              bg="blackAlpha.500"
              color="white"
              rounded="lg"
              height="full"
              width="full"
              justify="center"
              align="center"
            >
              <HStack spacing={4}>
                <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                <Stack justifyContent="center" alignItems="center">
                  <Text maxW="200px">{previewFileName}</Text>
                </Stack>
              </HStack>
            </Flex>
          )}
        </>
      ) : (
        <Text fontSize="3xl">
          <FontAwesomeIcon icon={faImage} />
        </Text>
      )}
      <ImageDetailed
        title=""
        src={previewMedia || media}
        isOpen={showImageModal}
        onClose={closeImageModal}
      />
    </Center>
  );
};

export default CoverMedia;
