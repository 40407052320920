import { useTranslation } from 'react-i18next';
import {
  Badge,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react';
import MembersButton from 'components/MembersButton';
import IconTooltip from 'components/tooltips/IconTooltip';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

const PublicCardHeader = ({ card }) => {
  const { t } = useTranslation();

  return (
    <HStack spacing={[4, null, 8]} alignItems="flex-start" rowGap={2}>
      <HStack
        spacing={4}
        flexWrap={['wrap', null, 'nowrap']}
        width="min-content"
        flexGrow={3}
        rowGap={2}
      >
        <Stack direction={['column', 'row', null]} alignItems="center">
          <Tooltip
            isDisabled={!card?.step?.description}
            label={card?.step?.description}
            hasArrow
            placement="top"
            maxWidth={['70vw', '50vw', '30vw']}
          >
            <Badge colorScheme={card.step ? card.step.color : 'gray'}>
              {card.step ? card.step.name : t('common.inbox')}
            </Badge>
          </Tooltip>
        </Stack>
        {card.collection?.workflow?.use_card_assignees ? (
          <HStack spacing={2}>
            <Text variant="muted">{t('common.assignees')}</Text>
            <MembersButton
              max={3}
              members={card.assignees}
              modalTitle={t('common.assigned_to')}
              canEdit={false}
              size="xs"
              px={2}
            />
          </HStack>
        ) : null}
      </HStack>
      <Spacer />
      <Flex justifyContent="flex-end">
        {card.hidden && (
          <IconTooltip
            fontSize="md"
            icon={faEyeSlash}
            label={t('common.hidden_one')}
            placement="top"
            alignSelf="center"
          />
        )}
      </Flex>
    </HStack>
  );
};

export default PublicCardHeader;
