import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Box, Button, Stack } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CommentListItem from './CommentListItem';
import { useEffect } from 'react';
import { CommentListItemBasic } from './CommentForm';

const ReplyList = ({
  code,
  commentId,
  depth,
  detector,
  onClickDelete,
  onClickReply,
  onClickUpdate,
  isAdminOrAssigneeOrCollectionManager,
  shouldRefetch,
  setShouldRefetch,
  stripped,
  handleOnClickTranslate, //TODO: refactor as to not need to pass both this and the handleOnSetTranslationText
  handleOnSetTranslationShown,
  handleOnSetTranslationText,
  commentTranslations
}) => {
  const { t } = useTranslation();

  const {
    status,
    data: replies,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery(
    ['replies', commentId],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams();
      params.append('parent', commentId);
      params.append('offset', pageParam);
      params.append('limit', 10);
      params.append('ordering', 'created_at');
      if (code) {
        params.append('code', code);
      }
      const { data } = await api.get(`/comments?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      refetch();
    }
  }, [shouldRefetch, setShouldRefetch, refetch]);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.replies').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={8}>
          {replies.pages.map(page =>
            page.results.map(comment =>
              stripped ? (
                <>
                  <CommentListItemBasic
                    key={comment.id}
                    code={code}
                    comment={comment}
                    detector={detector}
                    commentTranslations={commentTranslations}
                    handleOnClickTranslate={handleOnClickTranslate}
                    handleOnSetTranslationShown={handleOnSetTranslationShown}
                  />
                </>
              ) : (
                <CommentListItem
                  key={comment.id}
                  code={code}
                  comment={comment}
                  commentTranslations={commentTranslations}
                  handleOnSetTranslationText={handleOnSetTranslationText}
                  handleOnSetTranslationShown={handleOnSetTranslationShown}
                  detector={detector}
                  onClickReply={onClickReply}
                  onClickDelete={onClickDelete}
                  onClickUpdate={onClickUpdate}
                  isAdminOrAssigneeOrCollectionManager={
                    isAdminOrAssigneeOrCollectionManager
                  }
                  depth={depth}
                  shouldRefetch={shouldRefetch}
                  setShouldRefetch={setShouldRefetch}
                  stripped={stripped}
                />
              )
            )
          )}
          {hasNextPage && (
            <Box>
              <Button size="sm" variant="link" onClick={fetchNextPage}>
                {t('button.load_more_comments')}
              </Button>
            </Box>
          )}
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default ReplyList;
