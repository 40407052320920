import BoardReorderItem from './BoardReorderItem';

import { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

const BoardReorderHoverItem = ({ ref, id }) => {
  return <div ref={ref}>{id}</div>;
};

const BoardOrderPage = ({ boards, setBoards }) => {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(boards?.map((_, index) => index));
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    setItems(boards?.map((_, index) => index));
  }, [boards]);

  function handleOnDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleOnDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      let newItems = [];
      setItems(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        newItems = arrayMove(items, oldIndex, newIndex);
        return newItems;
      });
      const newBoards = newItems.map(index => boards[index]);
      setBoards(newBoards);
    }

    setActiveId(null);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleOnDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map(id => (
          <BoardReorderItem
            key={id}
            id={id}
            board={boards[id]}
            handleOnDragStart={handleOnDragStart}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? <BoardReorderHoverItem id={activeId} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default BoardOrderPage;
