import { format } from 'date-fns';
import LanguageDetect from 'languagedetect';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  HStack,
  Heading,
  Hide,
  Spacer,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import MembersButton from 'components/MembersButton';
import Reaction from 'components/Reaction';
import TranslateButton from 'components/TranslateButton';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import api from 'utils/api';
import LoginModal from 'components/modals/LoginModal';
import PublicContributorFeedback from './PublicContributorFeedback';

const detector = new LanguageDetect();

const PublicCardBody = ({ card = null }) => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const [translations, setTranslations] = useState(null);
  const [showTranslated, setShowTranslated] = useState(false);

  const languages = detector.detect(`${card?.title} ${card?.description}`, 2);

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const handleOnClickTranslate = async () => {
    if (!translations) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [card.title, card.description]
        });
        setTranslations(data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    setShowTranslated(!showTranslated);
  };

  return (
    <>
      <Stack spacing={8}>
        <CoverMedia
          position="relative"
          object={card}
          height={['300px', null, '200px']}
          hideIfEmpty
          clickToFullscreen
        />
        <Stack>
          <HStack spacing={2} width="full" alignItems="flex-start">
            <MembersButton
              showCount={isMobile}
              max={3}
              members={card.contributors}
              onSubmit={null}
              modalTitle={t('common.contributors')}
              canEdit={false}
            />
            <Hide below="lg">
              <Stack spacing={0}>
                {card.contributors.length > 0 && (
                  <Text fontSize="sm" fontWeight="bold">
                    {card.contributors.length === 1
                      ? card.contributors[0].first_name
                      : `${card.contributors.length} ${t(
                          'common.contributors'
                        )}`}
                  </Text>
                )}
                <Text fontSize="xs">
                  {format(
                    new Date(card.created_at),
                    isMobile ? 'dd MMM yyyy' : 'dd MMM yyyy, HH:mm'
                  )}
                </Text>
              </Stack>
            </Hide>
            <Spacer />
            {card.use_reactions ? (
              <Reaction
                reacted={card.reacted}
                reactions={card.metrics.reactions}
                reactionType={card.reaction_type}
                useReactions={card.use_reactions}
                onClickReact={null}
              />
            ) : null}
          </HStack>
        </Stack>

        <Stack spacing={6}>
          <HStack
            spacing={4}
            width="full"
            alignItems="alignItems"
            flexWrap="wrap"
            rowGap={2}
            flexGrow={2}
          >
            <Heading
              noOfLines={99}
              width="fit-content"
              wordBreak="break-word"
              fontSize="lg"
              alignSelf="center"
              maxWidth={['full', null, '90%']}
            >
              {translations && showTranslated ? translations[0] : card.title}
            </Heading>
          </HStack>
          {card.use_description && (
            <Description>
              {showTranslated && translations
                ? translations[1]
                : card.description}
            </Description>
          )}
          {languages &&
            languages.length > 0 &&
            languages[0][0] !== 'english' &&
            languages[0][1] > 0.2 && (
              <TranslateButton
                alignSelf="flex-start"
                showTranslated={showTranslated}
                onClick={handleOnClickTranslate}
              />
            )}
          <GridAttachmentList
            modelConfig={{
              singular: 'card',
              plural: 'cards',
              modelId: card.id
            }}
            canEdit={false}
            gridColumns={[2, 3, 4]}
          />

          <PublicContributorFeedback card={card} />

          <Textarea
            placeholder={t('placeholder.add_a_comment')}
            onClick={openLoginModal}
            isReadOnly
          />

          {/* <CommentSection
          commentCount={card.metrics.comments}
          cardId={card.id}
          updateCard={updateCard}
          code={code}
          isAdminOrAssigneeOrCollectionManager={
            isAdminOrAssigneeOrCollectionManager
          }
        /> */}
          <Divider />
          <Text>
            <Trans
              t={t}
              i18nKey="card.powered_by_hives"
              components={[
                <Text />,
                <Button
                  variant="link"
                  colorScheme="blue"
                  as="a"
                  href="https://hives.co"
                  target="_blank"
                />
              ]}
            />
          </Text>
        </Stack>
      </Stack>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
    </>
  );
};

export default PublicCardBody;
