import { Flex, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const BoardReorderItem = ({ id, board }) => {
  const { listeners, attributes, setNodeRef, transition, transform } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none'
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Flex borderWidth={0} borderBottom="none">
        <HStack spacing={4} alignItems="flex-start" userSelect="none">
          <Text cursor="grab" variant="light" pt={1}>
            <FontAwesomeIcon icon={faGripDots} />
          </Text>
          <Text maxWidth="300px" noOfLines={3} fontSize="sm">
            {board?.title}
          </Text>
        </HStack>
      </Flex>
    </div>
  );
};

export default BoardReorderItem;
