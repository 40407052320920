import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  CardFooter as ChakraCardFooter,
  Flex,
  Heading
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';

const SurveyListItem = ({ survey }) => {
  const { t } = useTranslation();

  const { slug } = useParams();
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  /* Ugly code! Just for testing!!! */
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContentHeight(entry.contentRect.height);
      }
    });

    const currentContentRef = contentRef.current; // Capture the current ref value

    if (currentContentRef) {
      resizeObserver.observe(currentContentRef);
    }

    return () => {
      if (currentContentRef) {
        resizeObserver.unobserve(currentContentRef); // Use the captured value
      }
    };
  }, []);

  return (
    <ChakraCard
      cursor="pointer"
      as={Link}
      to={`/${slug}/survey/${survey.id}`}
      direction="row"
      overflow="hidden"
      variant="outline"
    >
      <Flex
        width={['80px', '12px']}
        minWidth={['80px', '120px']}
        height={`${contentHeight}px`}
      >
        <CoverMedia object={survey} rounded="none" width="full" height="full" />
      </Flex>
      <Flex direction="column" ref={contentRef}>
        <ChakraCardBody pb={0}>
          <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
            {survey.title}
          </Heading>
        </ChakraCardBody>
        <ChakraCardFooter>
          <Badge>{t('common.survey')}</Badge>
        </ChakraCardFooter>
      </Flex>
    </ChakraCard>
  );
};

export default SurveyListItem;
