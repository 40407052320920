import { useTranslation } from 'react-i18next';
import {
  faChevronDown,
  faMemo,
  faRotateRight,
  faShare
} from '@fortawesome/pro-regular-svg-icons';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';
import ModalWrapper from 'components/ModalWrapper';
import ShareButtons from 'components/ShareButtons';
import {
  Badge,
  Box,
  Flex,
  Hide,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import CopyLinkButton from 'components/CopyLinkButton';
import { getCardShareUrl } from 'components/shareUtils';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from 'utils/api';

const CardActionMenu = ({
  card,
  collection,
  onClickEdit,
  onClickDelete,
  onClickViewReport,
  onClickChangeCollection,
  onClickChangePrivate,
  isAdminOrCollectionManager = false
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });
  const url = getCardShareUrl(slug, card);

  const { t } = useTranslation();

  const {
    isOpen: showShareButtonsModal,
    onOpen: openShareButtonsModal,
    onClose: closeShareButtonsModal
  } = useDisclosure();

  return (
    <>
      <ActionMenu>
        {card?.collection.workflow?.use_card_visibility ? (
          <Hide above="md">
            <Flex py={2} px={3}>
              <Stack width="full" fontSize="xs" spacing={2} flexWrap="wrap">
                <Text
                  fontSize="md"
                  whiteSpace="break-spaces"
                  maxWidth="150px"
                  fontWeight="400"
                >
                  {t('card.visible_to')}
                </Text>
                <Menu width="full">
                  <MenuButton width="full">
                    <Badge width="full">
                      <HStack width="full">
                        <Text textAlign="left" width="full">
                          {card.private
                            ? t('common.contributors')
                            : t('common.everyone')}
                        </Text>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </HStack>
                    </Badge>
                  </MenuButton>
                  <MenuList position="right">
                    <MenuItem
                      onClick={() => {
                        onClickChangePrivate(false);
                      }}
                    >
                      <Text fontSize="md">{t('common.all_members')}</Text>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onClickChangePrivate(true);
                      }}
                    >
                      <Text fontSize="md">{t('common.only_contributors')}</Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
            </Flex>
            <MenuDivider />
          </Hide>
        ) : null}
        <EditMenuItem onClick={onClickEdit} />
        <DeleteMenuItem onClick={onClickDelete} />
        <AttachmentUploadComponent
          modelConfig={{
            singular: 'card',
            plural: 'cards',
            modelId: card.id
          }}
        />
        {collection?.share_social_media && hive?.use_share ? (
          <CustomMenuItem
            icon={faShare}
            label={t('common.share')}
            onClick={openShareButtonsModal}
          />
        ) : (
          <CopyLinkButton inMenu url={url} />
        )}
        {isAdminOrCollectionManager && (
          <>
            <CustomMenuItem
              icon={faMemo}
              label={t('card.view_report')}
              onClick={onClickViewReport}
            />
            <CustomMenuItem
              icon={faRotateRight}
              label={t('collection.change_collection')}
              onClick={onClickChangeCollection}
            />
          </>
        )}
      </ActionMenu>
      {collection.share_social_media && hive?.use_share && (
        <ModalWrapper
          size="xs"
          isOpen={showShareButtonsModal}
          onClose={closeShareButtonsModal}
          title={t('common.share')}
        >
          <Box pb={4}>
            <ShareButtons
              collection={collection}
              isRound
              label={t('button.copy_link')}
            />
          </Box>
        </ModalWrapper>
      )}
    </>
  );
};

export default CardActionMenu;
