import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import api from 'utils/api';
import DesktopCreateButton from './DesktopCreateButton';
import InviteModal from 'features/hive/InviteModal';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import ModalWrapper from './ModalWrapper';
import AnnouncementForm from 'features/announcement/AnnouncementForm';
import CardCreationForm from 'features/card/CardCreationForm';
import MobileCreateButton from './MobileCreateButton';
import CreateReportModal from 'features/report/CreateReportModal';

const CreateButton = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const [newCollection, setNewCollection] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const toast = useToast();

  const { t } = useTranslation();

  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const {
    isOpen: showInviteModal,
    onOpen: openInviteModal,
    onClose: closeInviteModal
  } = useDisclosure();

  const {
    isOpen: showCreateAnnouncementModal,
    onOpen: openCreateAnnouncementModal,
    onClose: closeCreateAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showCreateReportModal,
    onOpen: openCreateReportModal,
    onClose: closeCreateReportModal
  } = useDisclosure();

  const createInvitesMutation = useMutation(
    async emails => await api.post('/hives/invite', emails),
    {
      onSuccess: () => {
        toast({
          title: t('toast.invites_send_success'),
          status: 'success'
        });
        closeInviteModal();
      },
      onError: () => {
        toast({
          title: t('toast.invites_send_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateAnnouncement = async announcement => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', announcement.image ? announcement.image : '');
    payload.append(
      'unsplash',
      announcement.unsplash ? announcement.unsplash : ''
    );
    payload.append('title', announcement.title);
    payload.append(
      'description',
      announcement.description ? announcement.description : ''
    );
    try {
      const {
        data: { id }
      } = await api.post('/announcements', payload, { timeout: 0 });
      if (announcement.video instanceof File) {
        const { data } = await api.post('/signed-url', {
          filename: announcement.video.name,
          model_type: 'announcement',
          model_id: id
        });
        await axios.put(data.signed_url, announcement.video, {
          headers: {
            'Content-Type':
              announcement.video.type || 'application/octet-stream'
          }
        });
        await api.patch(`/announcements/${id}`, { video: data.key });
      }
      queryClient.invalidateQueries(['announcements', slug]);
      toast({
        title: t('toast.announcement_create_success'),
        status: 'success'
      });
    } catch (e) {
      toast({
        title: t('toast.announcement_create_error'),
        status: 'error'
      });
    } finally {
      closeCreateAnnouncementModal();
    }
  };

  const handleOnCreateInvites = async values => {
    const emails = values.emails
      .map(email => email.value)
      .filter(e => /\S+@\S+\.\S+/.test(e));
    if (emails.length > 0) {
      await createInvitesMutation.mutateAsync({
        hive: hive.id,
        emails,
        subject: values.subject,
        message: values.message
      });
    }
  };

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      //always false cuz hook not updated in time
      navigate(`/${slug}/collection/${newCollection.id}`);
    }
  };

  return me ? (
    <>
      <Box display={['none', null, null, 'inline']}>
        <DesktopCreateButton
          openCreateAnnouncementModal={openCreateAnnouncementModal}
          openCreateCardModal={openCreateCardModal}
          openCreateCollectionModal={openCreateCollectionModal}
          openCreateReportModal={openCreateReportModal}
          openInviteModal={openInviteModal}
          me={me}
        />
      </Box>
      <Box display={['inline', null, null, 'none']}>
        <MobileCreateButton
          openCreateAnnouncementModal={openCreateAnnouncementModal}
          openCreateCollectionModal={openCreateCollectionModal}
          openInviteModal={openInviteModal}
          me={me}
        />
      </Box>
      <InviteModal
        me={me}
        isOpen={showInviteModal}
        onClose={closeInviteModal}
        onSubmit={handleOnCreateInvites}
      />
      <ModalWrapper
        size="full"
        isOpen={showCreateCollectionModal}
        onClose={handleOnCloseCreateCollectionModal}
        hasSteps
      >
        <CollectionCreationForm
          isOpen={showCreateCollectionModal}
          onClose={handleOnCloseCreateCollectionModal}
          onCollectionCreated={newCollection => {
            setNewCollection(newCollection);
          }}
          newCollection={newCollection}
        />
      </ModalWrapper>
      <ModalWrapper
        title={t('home.create_announcement')}
        size="full"
        isOpen={showCreateAnnouncementModal}
        onClose={closeCreateAnnouncementModal}
      >
        <AnnouncementForm
          isOpen={showCreateAnnouncementModal}
          onSubmit={handleOnCreateAnnouncement}
        />
      </ModalWrapper>
      <ModalWrapper
        size="full"
        isOpen={showCreateCardModal}
        onClose={closeCreateCardModal}
        hasSteps
      >
        <CardCreationForm
          isOpen={showCreateCardModal}
          onClose={closeCreateCardModal}
        />
      </ModalWrapper>
      <CreateReportModal
        isOpen={showCreateReportModal}
        onClose={closeCreateReportModal}
      />
    </>
  ) : null;
};

export default CreateButton;
