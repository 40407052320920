import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import LoadingWrapper from 'components/LoadingWrapper';
import TitleDescription from 'components/TitleDescription';
import api from 'utils/api';
import {
  AspectRatio,
  Button,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import PublicCardList from 'features/card/PublicCardList';
import ButtonAnimator from 'components/ButtonAnimator';
import { getContributeButtonLabel } from 'features/workflow/workflowUtils';
import ModalWrapper from 'components/ModalWrapper';
import { getCollectingSingular } from './collectionUtils';
import CardForm from 'features/card/CardForm';
import { useTranslation } from 'react-i18next';
import { generateCardPayload } from 'features/card/cardUtils';
import LoginModal from 'components/modals/LoginModal';
import { useConfetti } from 'providers/ConfettiProvider';
import { useUi } from 'providers/UiProvider';

const fetchCollection = async ({ queryKey }) => {
  const [, , collectionId] = queryKey;
  const { data } = await api.get(`/public/collections/${collectionId}`);
  return data;
};

const createCard = async payload => {
  const { data } = await api.post(`/public/cards`, payload);
  return data;
};

const PublicCollectionDetailed = () => {
  const { slug, collectionId } = useParams();
  const { fire } = useConfetti();
  const { handleOnThankYou } = useUi();

  const { t } = useTranslation();

  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    data: collection,
    status,
    isSuccess
  } = useQuery(['public', 'collection', collectionId], fetchCollection);

  const createCardMutation = useMutation(createCard, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'public',
        'cards',
        { hive: slug },
        { collection: collectionId }
      ]);
      if (collection?.thank_you_message) {
        handleOnThankYou(collection?.thank_you_message);
      } else {
        fire();
        toast({
          title: t('card.contribution_thank_you'),
          status: 'success'
        });
      }
      closeCreateCardModal();
    }
  });

  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const handleOnCreateCard = async card => {
    const payload = generateCardPayload(collection.id, card);
    await createCardMutation.mutateAsync(payload);
  };

  const canContribute =
    collection?.accept_anonymous_contributions &&
    collection?.contribution_access === 'CONTRIBUTORS';

  return (
    <>
      <Container maxW="container.lg" marginY={8}>
        <LoadingWrapper statuses={[status]}>
          {isSuccess && (
            <>
              <Stack spacing={8}>
                <SimpleGrid columns={[1, null, 2]} spacing={8}>
                  <AspectRatio ratio={16 / 9}>
                    <CoverMedia object={collection} clickToFullscreen />
                  </AspectRatio>
                  <Stack>
                    <TitleDescription
                      title={collection?.title}
                      description={collection?.description}
                      noOfCharacters={650}
                    />
                    <Spacer />
                    <Flex alignItems="flex-start">
                      <ButtonAnimator active={canContribute}>
                        <Button
                          colorScheme="teal"
                          isDisabled={
                            collection.contribution_access === 'MANAGERS' ||
                            collection.contribution_access === 'CLOSED'
                          }
                          onClick={() => {
                            if (canContribute) {
                              openCreateCardModal();
                            } else {
                              openLoginModal();
                            }
                          }}
                        >
                          {getContributeButtonLabel(collection.workflow)}
                        </Button>
                      </ButtonAnimator>
                    </Flex>
                  </Stack>
                </SimpleGrid>
                <PublicCardList filter={{ collection: collectionId }} />
              </Stack>
              <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
              <ModalWrapper
                title={getCollectingSingular(collection?.workflow?.type)}
                size="full"
                isOpen={showCreateCardModal}
                onClose={closeCreateCardModal}
              >
                <CardForm
                  titleOverride={collection.workflow?.title_override}
                  useDescription={collection.workflow?.use_card_description}
                  useImage={collection.workflow?.use_card_image}
                  workflowId={collection.workflow?.id}
                  collection={collection}
                  isOpen={showCreateCardModal}
                  onSubmit={handleOnCreateCard}
                />
              </ModalWrapper>
            </>
          )}
        </LoadingWrapper>
      </Container>
    </>
  );
};

export default PublicCollectionDetailed;
