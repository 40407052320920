import { Skeleton, Stack } from '@chakra-ui/react';

const CardBodySkeleton = () => (
  <Stack spacing={8}>
    <Skeleton height="25vh" width="100%" rounded="lg" />
    <Skeleton height="10vh" width="100%" rounded="lg" />
    <Skeleton height="20vh" width="100%" rounded="lg" />
    <Skeleton height="25vh" width="100%" rounded="lg" />
  </Stack>
);

export default CardBodySkeleton;
