import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import Description from 'components/Description';
import ReportListItem from 'features/report/ReportListItem';
import ReportForm from 'features/report/ReportForm';
import api from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';

const FolderReportList = ({ filter }) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const { t } = useTranslation();
  const { slug } = useParams();

  const {
    isOpen: showReportModal,
    onOpen: openReportModal,
    onClose: closeReportModal
  } = useDisclosure();

  const {
    isOpen: showDeleteReportModal,
    onOpen: openDeleteReportModal,
    onClose: closeDeleteReportModal
  } = useDisclosure();

  const {
    isOpen: showUpdateReportModal,
    onOpen: openUpdateReportModal,
    onClose: closeUpdateReportModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const toast = useToast();

  const {
    data: reports,
    status,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['reports', filter],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      if (filter?.folder) {
        params.append('folder', filter.folder);
      }
      params.append('hive__slug', slug);
      const { data } = await api.get(`/reports?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const { isLoading: deleteIsLoading, mutateAsync: deleteReportMutation } =
    useMutation(() => api.delete(`/reports/${selectedReport.id}`), {
      onSuccess: () => {
        setSelectedReport(null);
        queryClient.invalidateQueries('reports');
        toast({
          title: t('report.toast.delete_success'),
          status: 'success'
        });
        closeDeleteReportModal();
        closeReportModal();
      },
      onError: () => {
        toast({
          title: t('report.toast.delete_error'),
          status: 'error'
        });
      }
    });

  const updateReportMutation = useMutation(
    payload => api.patch(`/reports/${selectedReport.id}`, payload),
    {
      onSuccess: ({ data: report }) => {
        setSelectedReport(report);
        queryClient.invalidateQueries('reports');
        toast({
          title: t('report.toast.update_success'),
          status: 'success'
        });
        closeUpdateReportModal();
      },
      onError: () => {
        toast({
          title: t('report.toast.update_error'),
          status: 'error'
        });
      }
    }
  );

  const handleOnDeleteReport = async () => {
    await deleteReportMutation();
  };

  const handleOnUpdateReport = async data => {
    await updateReportMutation.mutateAsync(data);
  };

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.reports').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <>
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
            >
              <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                {reports.pages.map(page =>
                  page.results.map(report => (
                    <ReportListItem
                      key={report.id}
                      report={report}
                      onClick={() => {
                        setSelectedReport(report);
                        openReportModal();
                      }}
                    />
                  ))
                )}
              </SimpleGrid>
              {isFetching && <LoadingIndicator />}
            </InfiniteScrollHelper>
          </>
        )}
      </LoadingWrapper>
      <ModalWrapper
        title={selectedReport?.title}
        size="full"
        isOpen={showReportModal}
        onClose={closeReportModal}
      >
        <Stack spacing={4}>
          <HStack>
            <Button
              alignSelf="flex-start"
              variant="outline"
              onClick={openUpdateReportModal}
            >
              <HStack>
                <FontAwesomeIcon icon={faPencil} />
                <Text>{t('common.edit')}</Text>
              </HStack>
            </Button>
            <Button
              alignSelf="flex-start"
              variant="outline"
              onClick={openDeleteReportModal}
            >
              <HStack>
                <FontAwesomeIcon icon={faTrashAlt} />
                <Text>{t('common.delete')}</Text>
              </HStack>
            </Button>
          </HStack>
          <Description>{selectedReport?.report}</Description>
        </Stack>
      </ModalWrapper>
      <ModalWrapper
        title={t('report.update_modal.header')}
        isOpen={showUpdateReportModal}
        onClose={closeUpdateReportModal}
      >
        <ReportForm
          defaultValues={selectedReport}
          onSubmit={handleOnUpdateReport}
        />
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t('report.delete_modal.body')}
        isLoading={deleteIsLoading}
        isOpen={showDeleteReportModal}
        onClose={closeDeleteReportModal}
        onDelete={handleOnDeleteReport}
      />
    </>
  );
};

export default FolderReportList;
