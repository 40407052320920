import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react';
import { format } from 'date-fns';

const CollectionDetails = ({ collection }) => {
  const { t } = useTranslation();
  const canContribute = collection.contribution_access === 'CONTRIBUTORS';
  return (
    <Box alignSelf="flex-start" rounded="lg" borderWidth="1px" mb={8}>
      <Heading fontSize="lg" padding={4}>
        {t('collection.collection_details')}
      </Heading>
      <Divider />
      <Stack padding={4} spacing={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>{t('common.status')}</Text>
          <Badge colorScheme={canContribute ? 'teal' : 'gray'}>
            {canContribute ? t('common.open') : t('common.closed')}
          </Badge>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text>{t('common.created_at')}</Text>
          <Text>{format(new Date(collection.created_at), 'dd MMM yyyy')}</Text>
        </Flex>
      </Stack>
    </Box>
  );
};

export default CollectionDetails;
