import { useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import { splitIntoPages } from 'utils/general';
import ChangeFolderModal from './ChangeFolderModal';

const FolderUncategorizedSurveys = () => {
  const [survey, setSurvey] = useState();

  const { slug } = useParams();

  const { t } = useTranslation();

  const {
    isOpen: showChangeFolderModal,
    onOpen: openChangeFolderModal,
    onClose: closeChangeFolderModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const toast = useToast();

  const {
    status,
    data: surveys,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['uncategorized', 'surveys'],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      params.append('hive__slug', slug);
      params.append('folder__isnull', 'true');
      const { data } = await api.get(`/surveys?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const changeFolderMutation = useMutation(
    ({ surveyId, folderId }) =>
      api.patch(
        `/surveys/${surveyId}?hive__slug=${slug}`,
        { folder: folderId },
        {
          timeout: 0
        }
      ),
    {
      onSuccess: (_, { surveyId }) => {
        closeChangeFolderModal();
        const allResults = surveys.pages.flatMap(page => page.results);
        const filteredResults = allResults.filter(item => item.id !== surveyId);
        const updatedSurveys = {
          ...surveys,
          pages: splitIntoPages(surveys.pages, filteredResults)
        };
        queryClient.setQueryData(['uncategorized', 'surveys'], updatedSurveys);
        const navigationMetrics = queryClient.getQueryData(['navigation']);
        queryClient.setQueryData(['navigation'], {
          ...navigationMetrics,
          metrics: {
            ...navigationMetrics.metrics,
            uncategorized_surveys:
              navigationMetrics.metrics.uncategorized_surveys - 1
          }
        });
        toast({
          title: t('toast.survey_move_success'),
          status: 'success'
        });
        setSurvey(null);
      }
    }
  );

  const handleOnChangeFolder = async ({ folder }) => {
    if (survey) {
      await changeFolderMutation.mutateAsync({
        surveyId: survey.id,
        folderId: folder ? folder.id : null
      });
    }
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.surveys').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <>
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th pl={0}>{t('common.survey')}</Th>
                  <Th>{t('common.created_at')}</Th>
                  <Th pr={0} textAlign="right">
                    {t('common.folder')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {surveys.pages[0].count > 0 &&
                  surveys.pages.map(page =>
                    page.results.map(survey => (
                      <Tr key={survey.id}>
                        <Td pl={0}>
                          <Button
                            variant="link"
                            as={Link}
                            to={`/${slug}/survey/${survey.id}`}
                          >
                            {survey.title}
                          </Button>
                        </Td>
                        <Td>
                          {format(new Date(survey.created_at), 'dd MMM yyyy')}
                        </Td>
                        <Td pr={0} textAlign="right">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setSurvey(survey);
                              openChangeFolderModal();
                            }}
                          >
                            {t('common.move')}
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  )}
              </Tbody>
            </Table>
            {isFetching && <LoadingIndicator />}
          </InfiniteScrollHelper>
          <ChangeFolderModal
            folder={null}
            isOpen={showChangeFolderModal}
            onClose={closeChangeFolderModal}
            onChange={handleOnChangeFolder}
          />
        </>
      ) : null}
    </LoadingWrapper>
  );
};

export default FolderUncategorizedSurveys;
