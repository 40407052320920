import { AsyncSelect } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import api from 'utils/api';
import { useDebouncedLoadOptions } from 'utils/hooks';

const fetchCollections = async (query, slug) => {
  const { data } = await api.get(
    `/collections?search=${query}&hive__slug=${slug}`
  );
  return data;
};

const SelectCollectionsForm = ({ defaultValues, isLoading, onSubmit }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({ defaultValues });

  const loadCollections = useDebouncedLoadOptions(query =>
    fetchCollections(query, slug)
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="8">
        <FormControl id="collections">
          <FormLabel>{t('common.collections')}</FormLabel>
          <Controller
            name="collections"
            control={control}
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                isMulti
                loadOptions={loadCollections}
                placeholder={t('placeholder.search')}
                getOptionLabel={collection => collection.title}
                getOptionValue={collection => collection.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        <Button
          type="submit"
          alignSelf="flex-end"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          {t('button.continue')}
        </Button>
      </Stack>
    </form>
  );
};

export default SelectCollectionsForm;
