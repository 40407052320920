import { useTranslation } from 'react-i18next';
import ActionMenu, {
  CustomMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import { faSortUpDown } from '@fortawesome/pro-solid-svg-icons';
import {
  MenuDivider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  useDisclosure
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ManageBoards from './ManageBoards';
import { useUi } from 'providers/UiProvider';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { faMegaphone } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const HiveActionMenu = ({ onClickHome, onClickPopupAnnouncement }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const {
    isOpen: showConfirmationModal,
    onOpen: openConfirmationModal,
    onClose: closeConfirmationModal
  } = useDisclosure();

  const { modalClosureLocked, setModalClosureLocked } = useUi();

  const { t } = useTranslation();

  const {
    isOpen: showManageBoardsModal,
    onOpen: openManageBoardsModal,
    onClose: closeManageBoardsModal
  } = useDisclosure();

  return (
    <ActionMenu>
      <EditMenuItem
        label={t('home.edit_welcome_message')}
        onClick={onClickHome}
      />
      <CustomMenuItem
        icon={faMegaphone}
        label={t('home.set_popup_announcement')}
        onClick={onClickPopupAnnouncement}
      />
      <MenuDivider />
      <CustomMenuItem
        icon={faSortUpDown}
        label={t('board.manage_boards')}
        onClick={openManageBoardsModal}
      />
      <Modal
        size="full"
        isOpen={showManageBoardsModal}
        onClose={() => {
          if (modalClosureLocked) {
            openConfirmationModal();
          } else {
            closeManageBoardsModal();
          }
        }}
      >
        <ModalContent>
          <ModalHeader mx={[0, null, 8]}>
            {t('board.manage_boards')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mx={[0, null, 8]}>
            <ManageBoards
              isOpen={showManageBoardsModal}
              defaultValues={{
                board_layout: hive?.board_layout
                  ? hive.board_layout
                  : 'SECTIONS'
              }}
              onClose={closeManageBoardsModal}
            />
          </ModalBody>
        </ModalContent>
        <ConfirmationModal
          deleteHeader={t('confirmation.unsaved_changes')}
          deleteText={t('confirmation.unsaved_changes_explainer')}
          isOpen={showConfirmationModal}
          onClose={closeConfirmationModal}
          onDelete={() => {
            closeConfirmationModal();
            setModalClosureLocked(false);
            closeManageBoardsModal();
          }}
          buttonText={t('confirmation.discard')}
        />
      </Modal>
    </ActionMenu>
  );
};

export default HiveActionMenu;
